//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, FC, useEffect } from 'react';
import { Portal, useInterval } from '../newReact/misc';
import { Routes, Route, HashRouter } from 'react-router-dom';
import { AdminTemplate } from '../newReact/AdminTemplate';
import { AppData, MachshevetClient } from '../newReact/Declarations';
import { MainAppType, MainContext } from '../newReact/globals';

export const MyApp: FC = () => {
    const [state, setState] = useState<MainAppType>({ data: new AppData(), localized: (s) => s, setUser: setuser });

    useEffect(() => {
        getData()

    }, []);

    useInterval(() => { getData() }, 10000);

    function setuser(userid?: number) {
        setState(prev => ({ ...prev, data: { ...prev.data, UserID: userid } }))
    }

    async function getData() {
        const js = await MachshevetClient.Global.GetAppData();
        var eq = JSON.stringify(js) === JSON.stringify(state.data)
        if (!eq)
            setState(prev => ({ ...prev, data: js }))
    }

    return <MainContext.Provider value={state}>
        <HashRouter>
            <Routes>
                <Route path={"/*"} element={<AdminTemplate />} />
                <Route path={'Portal'} element={<Portal />} />
            </Routes>
        </HashRouter>
    </MainContext.Provider>
}