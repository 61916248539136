//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import { createContext, ReactElement, ReactNode, useEffect, useState } from "react"
import { AppData, ClipboardData, ColumnChoice, CommandList, CommandMessage, CommandResult, ControlProps, DataTypes, FileProps, GridProps, Intervals, MachshevetClient, MiniReportField, NavData, RecordData, ReportLite, SettingGroup } from "./Declarations"
import { devLog, fetchJson, fetchPlus, queryVals } from "./shared"

declare global {
    interface Array<T> {
        includes: (searchElement: T, fromIndex?: number) => boolean;
        sortBy: (generator: (value: T) => any[] | any) => T[];
        //remove: (searchElement: T) => boolean;
        removeMany: (elements: T[]) => boolean[];
        options: () => JSX.Element[];
    }
}

Array.prototype.sortBy = function <T>(this: Array<T>, sortkeysGenerator: (x: T) => any[] | any): Array<T> {
    this.sort((a, b) => {
        var sortkeysA = sortkeysGenerator(a);
        var sortkeysB = sortkeysGenerator(b);

        if (!(sortkeysA instanceof Array)) { return compare(sortkeysA, sortkeysB); }

        var length = sortkeysA.length;
        for (var i = 0; i < length; i++) {
            var compareResult = compare(sortkeysA[i], sortkeysB[i]);
            if (compareResult !== 0) { return compareResult; }
        }
        return 0;
    });
    return this;
};

window.addEventListener('error', function (event) {
    alert(event.message);
})

window.addEventListener('unhandledrejection', function (event) {
    if (event.reason instanceof (DOMException)) {
        if (event.reason.name === "AbortError") return
    }
    if (event.reason instanceof (PromiseRejectionEvent)) {
        return
    }
    var msg = event.reason + ''
    var m = msg.toLowerCase()
    if (m.includes("failed to fetch") || m.includes("the resource cannot be found") || m.includes("failed to execute 'observe' on")) return
    msg = msg.replace(/<br>/g, "\n");
    msg = msg.replace(/<\/br>/g, "");
    alert(msg)
})

export interface KeyValuePair<Key = number, Value = string> {
    Key: Key;
    Value: Value;
}

var compare = <T>(x: T, y: T) => {
    if (x === y) { return 0; }
    if (x < y) { return -1; }
    return 1;
};

export function getActionUrl(controller: string, action: string, id?: number, search?: string): string {
    if (!controller) return ""
    var dmn = leftCut(window.location.pathname, "/")
    let qry = search || '';
    if (qry !== '') qry = '?' + qry;
    const ret = '/' + [dmn, controller, action, id].filter(x => x).join('/') + qry;//who took out preceding flash?
    return ret;
};

export function performAction(controller: string, action: string, vals: Record<string, any>, usePost?: boolean, signal?: AbortSignal) {
    if (usePost) {
        var js = myStringify(vals)
        var hfile = hasFile(vals)
        var url = getActionUrl(controller, action, undefined)
        if (hfile) {
            const formData = new FormData();
            var flat = flatten(vals);
            flat.forEach(x => {
                formData.append(x[0], x[1]);
            });
            var ret = fetchPlus(url, {
                method: "POST",
                body: formData,
                signal
            });
            return ret;
        } else {
            return fetchPlus(url, {
                method: "POST",
                body: js,
                headers: {
                    'Content-Type': 'application/json'
                },
                signal: signal
            });
        }
    } else {
        return fetchPlus(getActionUrl(controller, action, undefined, queryVals(vals)));
    }
}

export function performActionT<T>(controller: string, action: string, vals: Record<string, any>, usePost?: boolean, signal?: AbortSignal) {
    //console.log('requesting ' + action);
    if (usePost) {
        const url = getActionUrl(controller, action, undefined);
        var hfile = hasFile(vals)// = Object.values(vals).some(x => x instanceof File) || Object.values(vals).some(x => Object.values(x).some(y => y instanceof File));
        if (hfile) {
            const formData = new FormData();
            var flat = flatten(vals);
            flat.forEach(x => {
                formData.append(x[0], x[1]);
            })

            var ret = fetchJson<T>(url, {
                method: "POST",
                body: formData,
                signal
            });
            return ret;
        }
        else {
            const jvals = myStringify(vals)
            const ret = fetchJson<T>(url, {
                method: "POST",
                body: jvals,
                headers: {
                    'Content-Type': 'application/json'
                },
                signal
            });
            return ret
        }
    } else
        return fetchJson<T>(getActionUrl(controller, action, undefined, queryVals(vals)), { signal });
}

export interface ControlProps2 extends ControlProps {
    onChange?: (value?: any, field?: ControlProps, newrow?: RecordData, idx?: number) => Promise<void>;
    changeProp?: (fieldName: string, newValue: any, letAbort?: boolean) => Promise<void>;
    onBlur?: (value?: any) => void;
    onChanged?: (value?: any) => void;
    placeholder?: string;
    modelGetter?: () => any;
    commandInputGetter?: () => ControlProps[];
    mainRecordType?: string;
    mainCommand?: string;
    items?: ControlProps2[];
    expanded?: boolean;
    recordID?: number;
    //recordType?: string;
    style?: React.CSSProperties;
    recordKey?: string;
    serverRefresh?: boolean;
    reloader?: () => void;
    showChanges?: boolean;
    showTools?: boolean;
    commandParam?: string
}

export interface CommandInputProps extends CommandResult {
    command: ControlProps2;
    reloader?: () => void;
    selectedKeys: Set<string>;
    mainRecordType?: string;
    tempFields: MiniReportField[]
}

export function defaultMiniReportField(Name: string) {
    const ret: MiniReportField = { FieldName: Name, SortDescending: false, BarFilterValues: [], IncludeEmpties: false, ReverseFilter: false, SelectName: Name, Visible: true }
    return ret
}

export function defaultGridProps(RecordID?: number): GridProps {
    const ret = new GridProps()
    ret.Page = 1
    ret.SettingGroup = SettingGroup.Columns
    if (RecordID || RecordID === 0) {//0 is important for commands that work on lists (like delete) should happen on all recs when invoked on new rec
        ret.RecordKeys = new Set([RecordID.toString()])
    }
    return ret
}

export function defaultRecordData(RecordType: string, RecordID?: number) {
    const ret: RecordData = { RecordType: RecordType, RecordID: RecordID, Fields: [], Reports: [], QuickAdds: [], IsDeleted: false, Index: 0, SimpleFields:[] }
    return ret
}

export function defaultReport(): ReportLite {
    const ret: ReportLite = {
        ID: 0, Count: 0, LastCount: new Date(), Type: 0, Position: 0, ForDashboard: false, Active: true, Frequency: 0, IsAlert: false, ForTopMenu: false, ForRestDays: false, ReverseJoin: false, ForPortal: false, Uses: 0, ForMainRecord: false, Paused: false, ShowWhenEmpty: true, FilterDescriptions: [], ShowCount: true, HasFilter: false, AddedOn: new Date(), IsUnion: false, KeyFields: [], ForPrint: false
    }
    return ret
}

export function defaultControlProps(): ControlProps2 {
    const ret: ControlProps2 = { Editable: true, Picklist: undefined as any, LetTime: true, IsCommand: false, Uses: 0, AutoComplete: false, Useful: true, SubTableProps: defaultRecordData('', 0), Required: false, NeedsField: false, Visible: true, IsNew: false, NeedsReport: false, serverRefresh: false, NeedsList: false, Multiline: false, AutoClose: false, Internal: false, WordWrap: false, CausesUnconfirm: false, NoChange: false, Locked: false, DefaultPosition: 200, IsStatic: false, SpecialPicker: false, ShowAll: false, CommandMembers:[] }
    return ret
}

export function range(first: number, last: number): number[] {
    return [...new Array(last + 1 - first)].map((_, i) => i + first);
}

export function weekFirstDay(date: Date) {
    return new Date(new Date(date).setDate(date.getDate() - date.getDay()))
}
export function monthFirstDay(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1)
}
export function redirect(reportID?: number, controller?: string, recordID?: number, sametab = true) {
    const url = getUrl(reportID, controller, recordID);
    if (sametab) window.location.hash = url;
    else window.open(window.location.href.replace(window.location.hash, '#') + url);
}

//const compareInputs = (oldInputs: any, newInputs: any) => {
//    var oflts = flatten(oldInputs);
//    var nflts = flatten(newInputs);
//    var nObj = Object.fromEntries(nflts);
//    oflts.forEach(kv => {
//        var key = kv[0];
//        var oldInput = kv[1];
//        //var newInput = nflts.find(x => x[0] === key)?.[1];
//        var newInput = nObj[key]
//        if (oldInput !== newInput) {
//            devLog("change detected", key, "old:", oldInput, "new:", newInput);
//        }
//    })
//};

export function parseDate(input:string,format:string) {
    // if (v === "") return v
    if (input === "") return undefined
    let fmt =format 
    fmt = fmt.replace(/d+/i, "(?<a>\\d+)")
    fmt = fmt.replace(/m+/i, "(?<b>\\d+)")
    fmt = fmt.replace(/y+/i, "(?<c>\\d+)")
    const regex1 = RegExp(fmt);
    let [, a, b, c] = regex1.exec(input) || [];
    if (!a) return undefined
    var dt = new Date(+c, +b - 1, +a)
    return dt
}




export function divideFloat(firstNum: number, secondNum: number) {
    var ndI = 1 + ''.indexOf.call(firstNum, '.'); //Index of the Number's Dot
    var ddI = 1 + ''.indexOf.call(secondNum, '.'); // Index of the Divisors Dot
    if (ndI || ddI) { // IF its a float
        var deci = Math.max(('' + firstNum).length - ndI, ('' + secondNum).length - ddI); //Longest Decimal Part
        var pow = Math.pow(10, deci);
        return (firstNum * pow) / (secondNum * pow);
    }
    return firstNum / secondNum;
}

export function smartState(newRecord: RecordData, oldRecord: RecordData, changeField?: string, changeValue?: any, newrow?: RecordData) {
    //aa
    var newdata = newRecord.Fields.map(x => {
        var cp2: ControlProps2 = x
       // cp2.recordType = newRecord.RecordType
        cp2.recordID = newRecord.RecordID
        cp2.serverRefresh = true
        var fld = cp2.Name
        var olddata = oldRecord.Fields.find(y => y.Name === fld)
        if (fld === "FirstName") {
            var a = olddata
            var b = olddata?.Value
            var c = 1
        }
        //cp2.originalValue = olddata?.Value
        if (fld === "File") {
            if (olddata) {
                var oldval: FileProps = olddata.Value;
                if (oldval) {
                    var newval: FileProps = cp2.Value;
                    if (newval) {
                        newval.File = oldval.File;
                        if (oldval.Bytes) newval.Bytes = oldval.Bytes;
                        if (oldval.Type) newval.Type = oldval.Type;
                        cp2.Value = newval;
                    }
                }
            }
        }
        if (cp2.SubTableName) {
            var newv = newRecord.Fields.find(y => y.Name === fld)
            var v2: RecordData[] = newv?.Value
            if (newrow && newrow.RecordType == cp2.SubTableName) v2 = v2.concat(newrow)
            cp2.Value = v2
        } else {
            if (changeField && fld === changeField) {
                cp2.Value = changeValue
                //if (cp2.CausesUnconfirm && cp2.Value !== cp2.originalValue) cp2.changeAlert = getLocalText("CausesUnconfirm")
            }
            var olddata = oldRecord.Fields.find(y => y.Name === fld)
            if (olddata) {
                var oldlist = olddata.Picklist;
                if (oldlist) {
                    var newlist = cp2.Picklist;
                    if (!newlist) { cp2.Picklist = oldlist }
                }
            }
        }
        return cp2
    })
    return newdata
}

export async function downloadFile(rsp: Response) {
    const disposition = rsp.headers.get('Content-Disposition');
    const a = document.createElement('a');
    var blob = await rsp.blob();
    var url = URL.createObjectURL(blob);
    a.style.display = 'none';
    a.href = url;
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(disposition!);
    if (matches != null && matches[1]) {
        var fn = matches[1];
        if (fn.startsWith('UTF-8')) {
            fn = fn.replace("UTF-8''", "");
            fn = decodeURI(fn);
        }
        a.download = fn.replace(/['"]/g, '');
    }
    document.body.appendChild(a);
    a.click();
}

export function getUrl(reportID?: number, controller?: string, recordID?: number, newtab: boolean = false, action?: string) {
    var urlstart = '/'
    if (reportID) {
        urlstart += 'Report/' + reportID
    } else {
        if (controller) urlstart += controller + '/';
        if (action) urlstart += action + '/';
        if (recordID != undefined) urlstart += 'Edit/' + (recordID || 0);
    }
    return urlstart
}

export function hasFile(obj: any) {
    var ents = Object.entries(obj)

    var ret = ents.some(x => {
        var val: any = x[1]
        if (val) {
            if (val instanceof File) {
                return true
            }
            else if (typeof val == 'object') {
                var ret = hasFile(val)
                return ret
            }

        }
        return false
    })
    return ret
}

export function docSource(recordType: string, recordID: number, Column: string, page?: number, download?: boolean) {
    var ret = getActionUrl(recordType, 'GetDoc', recordID, 'Column=' + Column + (page ? '&Page=' + page : '') + (download ? "&Download=True" : ""))
    return ret
}

export function flatten(obj: any) {
    var ret: [string, string | Blob][] = [];
    Object.entries(obj).forEach(x => {
        let key = x[0];
        let val = x[1];
        var isary = Array.isArray(obj);
        var isary2 = Array.isArray(val);
        if (val !== null) {
            if (typeof val == 'object' && !(val instanceof File)) {
                var flts = flatten(val)
                var mp = flts.map(y => {
                    //var ky = isary ? '[' + key + '].' + y[0] : key + (isary2 ?'': '.') + y[0];
                    var ky = (isary ? '[' + key + ']' : key) + (isary2 ? '' : '.') + y[0];
                    var vl = y[1]
                    return [ky, vl] as [string, string | Blob];
                })
                ret = ret.concat(mp);
            } else {
                var v: string | Blob = val as any;
                ret.push([key, v]);
            }
        }

    })
    return ret;
}

const detectIE = (): number | boolean => {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
};

const addZero = (num: number) => {
    return num.toString().padStart(2, '0');
};

function leftCutTill(input: string, till: string) {
    return input.split(till).pop();
};

export function rightCut(input: string, toCut: string) {
    var ret = input;
    if (ret.endsWith(toCut)) {
        ret = input.substring(0, input.length - toCut.length);
    }
    return ret;
};

export function leftCut(input: string, toCut: string) {
    var ret = input;
    if (ret.startsWith(toCut)) {
        //ret = input.substring(0, input.length - toCut.length);
        ret = input.substring(toCut.length);
    }
    return ret;
};

//export var parseDateString: (s: string) => Date | undefined;
//export var getOrAddDateTimeFromDDMMYYY: (input: string, addYears?: number, addMonths?: number, addDays?: number) => string;
//(() => {
//    var parsers = {
//        getDateFromDDMMYYY: (s: string) => /(\d{1,2})\D+(\d{1,2})\D+(\d{2,4})/g.exec(s),
//        parseDateString: (s: string) => s.split(/[\s:]/),
//        getOrAddDateTimeFromDDMMYYY: (s: string) => /(\d{1,2})\D+(\d{1,2})\D+(\d{2,4})\D+(\d{2,4})\D+(\d{2,4})\D+(\d{2,4})/g.exec(s)
//    };
//    var monthNames = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
//    var mapper = (parts: string[] | null) => {
//        if (!parts) { return null; }
//        var monthIndex = monthNames.indexOf(parts[2]);
//        if (monthIndex !== -1) { parts[2] = monthIndex.toString(); }
//        const parts1 = parts.map(x => Number(x)) as [number, number, number, ...number[]];
//        return new Date(...parts1);
//    };
//    parseDateString = s => {
//        var parts = parsers.parseDateString(s);
//        return mapper(parts) || undefined;
//    };
//    getOrAddDateTimeFromDDMMYYY = (input, addYears = 0, addMonths = 0, addDays = 0) => {
//        var parts = parsers.getOrAddDateTimeFromDDMMYYY(input);
//        var dt = mapper(parts);
//        if (!dt) { return ''; }
//        var dmy = [addZero(dt.getDate() + addDays), addZero((dt.getMonth() + 1) + addMonths), dt.getFullYear() + addYears].join('/');
//        var hms = [addZero(dt.getHours()), addZero(dt.getMinutes()), addZero(dt.getSeconds())];
//        return dmy + ' ' + hms;
//    };
//})();

//export interface DateOffsets {
//    year?: number;
//    month?: number;
//    date?: number;
//    hour?: number;
//    minute?: number;
//    second?: number;
//}

export interface MenuItemViewProps {
    menu_item: ControlProps2;
    onCommand: (command: ControlProps, parameter?: any) => void;
}

export interface Stored<T> {
    expires: string,
    readonly item: T
}

export class Stored<T> implements Stored<T> {
    expires: string;
    constructor(readonly item: T) {
        const expires = new Date();
        expires.setHours(expires.getHours() + 1);
        this.expires = expires.toISOString();
    }
    store(key: string) {
        localStorage.setItem(key, JSON.stringify(this));
    }
    static tryLoad<T>(key: string): T | null {
        let serialized = localStorage.getItem(key);

        if (serialized) {
            const stored = JSON.parse(serialized) as Stored<T>;
            if (new Date() < new Date(stored.expires)) {
                return stored!.item;
            }
        }

        return null;
    }
}

//export function stored<T, Y>(key: string, value: () => Y, fetcher?: () => Promise<T>): T | Y {
//    const st = Stored.tryLoad<T>(key);
//    if (st) {
//        return st;
//    } else {
//        const data = value();
//        if (fetcher) {
//            fetcher().then(data => new Stored(data).store(key));
//            return data;
//        } else {
//            new Stored(data).store(key);
//            return data;
//        }
//    }
//};

export const ReadBytes = (file: File) => new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        var res = reader.result as string
        resolve(res)
    }
    reader.onerror = error => reject(error);
});

export const ReadText = (file: File) => new Promise<string>(callback => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
        var res = reader.result as string
        callback(res)
    };
    reader.onerror = () => { throw reader.error }
})

export function controlRecord(input: ControlProps[], recordID: number, domainID?: number) {
    var maxbyts = 2573253
    var rec = input.reduce((acc, cur) => {
        var newval = cur.Value
        if (cur.DataType === DataTypes.Bytes && cur.Name === "File"  ) {
            var fp: FileProps = newval;
            if (fp.Bytes?.length > maxbyts) fp.Bytes = ''
            newval = fp;
        }
        if (cur.DataType === DataTypes.Bytes) {
            var nv = newval ? newval + '' : undefined
            if (nv && nv.length > maxbyts) nv = ''
            newval = nv;
        }
        if (cur.SubTableName && cur.SubTableProps) {
            var recs: RecordData[] = newval;
            var newsubrecs: any[] = [];
            recs.filter(x => !x.IsDeleted).forEach(x => {
                var newsubrec: Record<string, any> = {}
                x.Fields.forEach(y => {
                    newsubrec[y.Name!] = y.Value
                })
                newsubrec['ID'] = x.RecordID;
                newsubrec['InUserInterface'] = true;
                newsubrecs.push(newsubrec)
            })
            newval = newsubrecs;
        }
        if (cur.Name == "DomainID" && newval) domainID = newval
        return (acc[cur.Name!] = newval, acc)
    }, {} as any);
    var ret = { ...rec, ID: recordID }
    if (domainID) ret = { ...ret, DomainID: domainID }
    return ret

}

export function reloadPage() {
    document.location.reload();
}

export function colorNumber(hex: string) {
    let clr = hex.replace('#', '');
    return parseInt(clr, 16);
}

export function numberColor(input: number | undefined) {
    if (!input) return ''
    return '#' + input.toString(16).padStart(6, '0')
}

export function enumList(app: MainAppType, enumObject: any) {
    const keysList = Object.keys(enumObject).filter(key => key !== String(parseInt(key, 10)))
    const ret = keysList.map(x => ({ Key: +enumObject[x], Value: app.localized(x) }))
    return ret
}
export function myStringify(input: any) {
    const ret = JSON.stringify(input, function (k, v) {
        if (v === "") return null
        if (v instanceof Set) return [...v]
        return v
    });
    return ret
}

export function handleCommandResult(input: CommandResult, command: ControlProps, gridProps: GridProps, context: AppContextType, reloader?: () => void, mainRecordType?: string, colSetter?: (state: MiniReportField) => void) {
    const res = input
    const obj = res.Object
    const objtyp = res.ObjectType

    if (objtyp) {

        if (objtyp.endsWith('Input')) {
            const cip: CommandInputProps = { ...res, command: command, selectedKeys: gridProps.RecordKeys, mainRecordType: mainRecordType, tempFields: gridProps.Fields }
            context.openDialog(cip);
        } else if (objtyp === 'ClipboardData') {
            var cd: ClipboardData = obj
            var clipitms: ClipboardItem[] = []
            if (cd.Text) {
                const type = "text/plain";
                const blob = new Blob([cd.Text], { type });
                const data = new ClipboardItem({ [type]: blob })
                clipitms.push(data)
            }
            if (cd.Html) {
                const type = "text/html";
                const blob = new Blob([cd.Html], { type });
                const data = new ClipboardItem({ [type]: blob })
                clipitms.push(data)
            }
            navigator.clipboard.write(clipitms)
        } else if (objtyp === 'Uri') {
            window.open(obj, "_blank")
        } else if (objtyp === 'CommandMessage') {
            context.openMessage(obj)
        } else if (objtyp === 'ColumnChoice') {
            context.openChooser(obj, reloader)
        } else if (objtyp === 'CommandList') {
            const gp = defaultGridProps()
            var cmlist: CommandList = res.Object
            cmlist.Filters?.forEach(x => {
                var col1 = defaultMiniReportField(x.FieldName!)
                col1.IDFilter = x.IDFilter
                col1.FilterGroup = x.FilterGroup
                col1.Formula = x.Formula
                col1.FilterList = x.FilterList
                col1.FilterText = x.FilterText
                col1.MinFilter = x.MinFilter
                col1.MaxFilter = x.MaxFilter
                gp.Fields.push(col1)
            })
            context.openTable(cmlist, gp)
        } else if (objtyp === 'MiniReportField') {
            colSetter!(res.Object)
        } else if (res.Record) {
            context.openRecord(res.Record, reloader);

        }






    } else {
        if (reloader) reloader()
        else reloadPage()
    }
}

export async function handleResult(response: Response, command: ControlProps, gridProps: GridProps, context: AppContextType, reloader?: () => void, recordType?: string, colSetter?: (state: MiniReportField) => void) {

    const disposition = response.headers.get('Content-Disposition');
    if (disposition && disposition.startsWith('attachment')) {
        const prnt = response.headers.get('X-ForPrint');
        if (prnt) {
            const reader = new FileReader();
            const blb = await response.blob()
            reader.readAsText(blb);
            reader.onload = function () {
                context.printHtml(reader.result!.toString())
            };
        } else {
            await downloadFile(response)
        }
    } else {
        const res = await response.json()
        handleCommandResult(res, command, gridProps, context!, reloader, recordType, colSetter)
    }
}

export async function doCommand(command: ControlProps, recordType: string, TotalRowCount: number, gridProps: GridProps, param?: string, reloader?: () => void, context?: AppContextType, record?: any, fieldName?: string, colSetter?: (state: MiniReportField) => void, value?: any) {
    if (command.Warning) {
        let recordCount = gridProps.RecordKeys.size
        if (!recordCount) recordCount = TotalRowCount;
        const warning = command.Warning.replace("{#}", recordCount.toString());
        if (!window.confirm(warning)) return;
    }
    const url = window.location.href
    const rsp = await MachshevetClient.DoCommandMulti(recordType, command.Name!, param?.toString()!, gridProps, record, fieldName, value, url)
    await handleResult(rsp, command, gridProps, context!, reloader, recordType, colSetter)
}

export function useMediaQuery(query: string) {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => {
            setMatches(media.matches);
        };
        media.addListener(listener);
        return () => media.removeListener(listener);
    }, [matches, query]);
    return matches;
}

export function useMaxWidth(pixels: number) {
    return useMediaQuery(`(max-width: ${pixels}px)`)
}

export type TabType = { titleText?: string, style?: React.CSSProperties, onClick?: () => void, titleCount?: number, onResultsFetched?: (count: number) => void, titleElement?: ReactElement, children?: ReactNode,reportID?:number}
//export type TabType2 = { content: ReactElement, title: ReactElement | string, style?: React.CSSProperties, onClick?: () => void }

export interface ContextMenuProps extends MenuProps {
    position?: [number, number];
    //addCommand?: ControlProps;
    quickAdds?: {Key:string,Value:string}[]
};

export function prioritizeCommands(ctx: MainAppType, cmnds: ControlProps[]): ControlProps2[] {
    const flt = cmnds.filter(x => x.Uses > 0).sortBy(x => x.DisplayName)
    const mor: ControlProps2 = defaultControlProps()
    mor.Name = "More"
    mor.DisplayName = ctx.localized("More")
    mor.items = cmnds.filter(x => x.Uses == 0).sort((one, two) => {
        return one.DisplayName! < two.DisplayName! ? -1 : 1;
    })
    return [...flt, mor];

    //}
}

export interface MarkerProps { name: string, lat: number, long: number }

export interface VecIconProps extends React.HTMLAttributes<HTMLSpanElement> { name: string, width?: number, color?: string, position?: 'absolute', backColor?: string }
export interface MenuProps {
    onCommand: (command: ControlProps, parameter?: any) => void,
    items: ControlProps2[];
    hoverable?: boolean;
};
export type AppContextType = {
    openRecord: (record: RecordData, onSaved?: (id: number) => void, presetValues?: any) => void;
    closeRecord: () => boolean;
    openTable: (cmdList: CommandList, gp: GridProps) => void;
    openChooser: (input: ColumnChoice,reloader?:()=>void) => void;
    openMessage: (input: CommandMessage) => void;
    openDialog: (result: CommandInputProps) => void;
    printHtml: (body: string) => void;
    setPageTitle: (title: string) => void;
    closeTable: () => void;
    data?: NavData;
    docActive: () => boolean;
};

var dt: AppData = {
    UseLocalText: false,
    Language: undefined,
    IsRtl: false,
    IsRemote: false,
    CommandsInSameTab: false,
    IsSysAdmin: false,
    IsSandbox: false,
    IsDebug: false,
    GridColumns: 3,
    //OldDatePicker: false
};
export const MainContext = createContext<MainAppType>({ data: dt, localized: () => "", translations: {}, setUser: () => { } });

export type MainAppType = {
    data: AppData;
    localized: (input: string) => string;
    translations?: { [index: string]: string };
    setUser: (input?: number) => void
}
export const AppContext = createContext<AppContextType | undefined>(undefined);
export interface RecordFormProps {
    setSaveHandler?: any,
    onSaved?: (id: number, oldId?: number) => void,
    onDirty?: (isDirty: boolean) => void,
    Record?: RecordData,
    presetValues?: any,
    isPopup?: boolean
}
export async function openFieldSetting(recordType: string, fieldName: string, context: AppContextType) {
    var id = await MachshevetClient.GetFieldSettingID(recordType, fieldName);
    var rd = defaultRecordData('FieldSetting', id)
    context.openRecord(rd);
};

export function normalDate2(badDate: Date) {
    var dt = badDate
    var newdt = Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), dt.getHours(), dt.getMinutes(), dt.getSeconds())
    var d2 = new Date(newdt)
    return rightCut(d2.toISOString(), 'Z')
}
//export interface PlacePickProps {
//    placeHolder?: string,
//    type?: string,
//    onChange: (address: string) => void
//}
//export interface GlanceProps {
//    table: string, id: number
//}

export function closestInterval(seconds: number): [Intervals, number] {
    let number = 0, interval = Intervals.Second;

    var isminus = (seconds < 0);
    seconds = Math.abs(seconds)
    if (seconds > 0) {
        if (seconds >= 31536000) {
            number = seconds / 31536000;
            interval = Intervals.Year;
        } else if (seconds >= 2628000) {
            number = seconds / 2628000;
            interval = Intervals.Month;
        } else if (seconds >= 604800) {
            number = seconds / 604800;
            interval = Intervals.Week;
        } else if (seconds >= 86400) {
            number = seconds / 86400;
            interval = Intervals.Day;
        } else if (seconds >= 3600) {
            number = seconds / 3600;
            interval = Intervals.Hour;
        } else if (seconds >= 60) {
            number = seconds / 60;
            interval = Intervals.Minute;
        } else {
            number = seconds;
            interval = Intervals.Second;
        }
        if (isminus) number = -number;
    }
    return [interval, number];
}

export function multipliers() {
    var ret = {
        [Intervals.Millisecond]: 0.001,
        [Intervals.Second]: 1,
        [Intervals.Minute]: 60,
        [Intervals.Hour]: 3600,
        [Intervals.Day]: 86400,
        [Intervals.Week]: 604800,
        [Intervals.Month]: 2628000,
        [Intervals.Year]: 31536000
    }
    return ret
}
export function isSameDay(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
}
export function getWeekNumber(d: Date): number {
    // Copy date so don't modify original
    d = new Date(d);
    // Get first day of year
    var yearStart = new Date(d.getFullYear(), 0, 1);
    const sunday = new Date(d.getFullYear(), d.getMonth(), d.getDate() - d.getDay());
    const millis_from_one_jan = sunday.getMilliseconds() - yearStart.getMilliseconds();
    const millis_per_day = 8.64e+7;
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(millis_from_one_jan / millis_per_day) / 7;
    // Return array of year and week number
    return weekNo;
}

export function GetMonthName(ctx: MainAppType, month: number) {
    var mnth = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][month]
    return ctx.localized(mnth)
}

export function GetDayName(ctx: MainAppType, day: number): string {
    return ctx.localized((['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'])[day]) || day.toString();
}

