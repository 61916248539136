//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, PointerEvent, useContext, useEffect, useRef, useState, CSSProperties } from 'react';
import { Preview, VecIcon, NavLink, ContextMenu, useInterval, HamburgerMenu, Avatar2, useEffect2 } from './misc';
import { Control } from './Control';
import { ColumnHeader } from './Column';
import { ControlProps, FieldTypes, GridCommands, GridProps, MachshevetClient, RecordData, ReportTypes, SettingGroup, ViewTable } from './Declarations';
import { AppContext, ControlProps2, defaultGridProps, defaultMiniReportField, doCommand, leftCut, MainContext, myStringify, numberColor, prioritizeCommands, range, redirect } from './globals';
import { Calendar } from './calendar';
import { DisplayBox } from './pickers';
import { devLog } from './shared';

export interface TableBodyProps {
    onRowClicked?: (id: number, name: string) => void;
    controller: string;
    onResultsFetched?: (count: number) => void;
    fullPage?: boolean;
    modelGetter?: () => any;
    commandInputGetter?: () => ControlProps[];
    gridProps: GridProps;
    autoRefresh?: boolean;
    idxSelected?: number;
    handleSelection?: (id: number, name: string) => void;
    resetSelected?: (count: number) => void;
    saveState?: boolean;
    showTools?: boolean;
    records?: RecordData[];
    listType?: string;
    onPropsChanged?: (newprops: GridProps) => void;
    commands?: ControlProps[];
    doCommand?: (command: ControlProps, param: any) => void;
    onSelected?: (key: string) => void;
    activeTab: boolean;
}

export const TableBody: FC<TableBodyProps> = (props) => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext);
    const [state, setState] = useState<ViewTable>();
    const [totalRows, setTotalRows] = useState(0);
    const [menuPosition, setMenuPosition] = useState<[number, number] | undefined>();
    const [menuField, setMenuField] = useState<string>();
    const [menuFieldValue, setMenuFieldValue] = useState();
    const [suggestions, setSuggestions] = useState<string[]>();
    const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
    const [menuShown, setMenuShown] = useState(false);
    const [gridProps, setGridProps] = useState(props.gridProps || defaultGridProps());
    const [selectedRecord, setSelectedRecord] = useState<RecordData>();
    const [selectedRecordIndex, setSelectedRecordIndex] = useState(0);
    const [editColumns, setEditColumns] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [dropValue, setDropValue] = useState<string>();
    const [commands, setCommands] = useState<GridCommands>();
    const selectedIndex = useRef(props.idxSelected);
    const ref = useRef<HTMLDivElement>(null);
    const lastRefresh = useRef<number>(0);
    const aborter = React.useRef(new AbortController());
    const lsttyp = props.controller;

    useInterval(() => {
        if (app.docActive()) {

            if (lastRefresh.current) {
                const diff = Date.now() - lastRefresh.current;
                if (diff > 10000) {
                    if (!isLoading) refreshTable();
                }
            }
        }
        else if (!ctx.data.UserID) {
            redirect();
            return;
        }
    }, 500);
    //}, 50000000);

    const curstt = state;
    const curgp = gridProps || defaultGridProps();
    if (!gridProps.SettingKey) gridProps.SettingKey = lsttyp + '.' + lsttyp;

    useEffect2(async () => {
        //setActiveTab(props.activeTab === undefined || props.activeTab);
        if (props.activeTab) {
            await refreshTable();
            devLog('activTab')
        }
    }, [props.activeTab]);

    async function firstLoad() {
        let gp: GridProps | undefined
        if (props.saveState !== false) {
            const ky = stateKey();
            const stateString = window.sessionStorage[ky];
            if (stateString) {
                gp = JSON.parse(stateString) as GridProps;
                gp.RecordKeys = new Set([])//hack!!
                if (!curstt || !curstt.Columns.length) setGridProps(gp);
            }
        }
        await refreshTable(gp);
    }

    useEffect(() => {
        filterChanged(false);
    }, [gridProps.ParentRecordID, gridProps.Term]);//term here is for entitypick mainly

    useEffect(() => {
        firstLoad()
    }, [gridProps.ParentRecordID]);

    useEffect(() => {
        const idxSel = props.idxSelected!;
        if (idxSel >= lastNum && props.resetSelected) props.resetSelected(0);
        else moveSelection(idxSel);
    }, [props.idxSelected])

    function stateKey() {
        const cnt = props.controller;
        const gp = gridProps
        const rid = gp.ReportID;
        const mem = gp.Member
        const prid = gp.ParentRecordID;
        return cnt + '/DataTable' + (mem ? '.' + mem : '') + (rid ? '#' + rid : '') + (prid ? '.' + prid : '')
    }

    async function filterChanged(savestate: boolean) {
        if (savestate) setBrowserState()
        await refreshTable()
    }

    async function refreshTable(gp?: GridProps) {
        setIsLoading(true)
        aborter.current.abort();
        aborter.current = new AbortController();
        let mdl = props.modelGetter && props.modelGetter();
        const cmdinp = props.commandInputGetter && props.commandInputGetter();
        if (!mdl) mdl = { ID: 0 }
        mdl.CheckValidations = false;
        if (props.activeTab || !lastRefresh.current) {
            const gprops = gp || gridProps
            const r = await MachshevetClient.QuickSearch(props.controller, mdl, gprops, cmdinp, props.showTools, aborter.current.signal);
            setState(r);
            if (props.fullPage) app!.setPageTitle(r.DisplayName!);
            if (r.AllowedKeys && gridProps.RecordKeys.size)
                setGridProps(prev => ({ ...prev, RecordKeys: new Set([...prev.RecordKeys].filter(x => r.AllowedKeys.indexOf(x) > -1)) }));
            if (!gridProps.DisplayType && r.ReportType) setGridProps(prev => ({ ...prev, DisplayType: r.ReportType }));
            if (!commands) {
                const cmnds = await MachshevetClient.Global.GetCommands(r.ListType!, gridProps.ReportID);
                setCommands(cmnds);
            }
        }
        const cnt = await MachshevetClient.GetCount(props.controller!, mdl, gp || gridProps, cmdinp, aborter.current.signal);
        setTotalRows(cnt);
        props.onResultsFetched && props.onResultsFetched(cnt);
        lastRefresh.current = Date.now();//means data has been filled. necessary for inactive tabs on first show
        setIsLoading(false)
    }

    function setBrowserState() {
        if (props.saveState != false) {
            const js = myStringify(gridProps);
            const ky = stateKey()
            window.sessionStorage[ky] = js;
        }
    }

    const firstID = (curstt?.PageRows! * (curgp.Page - 1))
    const rowcount = curstt?.Records?.length
    const lastNum = firstID + (rowcount || 0);
    const pageCount = (Math.ceil(totalRows / curstt?.PageRows!)) || 0;
    const end = (Math.min(pageCount, curgp.Page + 5)) || 0;
    let around_the_page = [1];

    function inputKeyDown(event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) {
        const key = event.key;
        const cursel = selectedIndex.current === undefined ? -1 : selectedIndex.current
        if (key === 'ArrowDown') moveSelection(cursel + 1);
        else if (key === 'ArrowUp') moveSelection(cursel - 1);
        else if (key === "Esc" || key === "Escape") {
            curgp.Term = "";
            setSuggestions(undefined);
        }
    };

    function moveSelection(index: number) {
        let newidx = index
        if (index < 0) {
            newidx = lastNum - 1;
            if (props.resetSelected) props.resetSelected(newidx);
        }
        if (curstt) {
            const toRow = curstt.Records[newidx];
            setKeys(new Set([toRow.RecordKey!]), true, true);
            setSelectedRecord(toRow);
            setSelectedRecordIndex(newidx);
        }
        selectedIndex.current = newidx;
    }

    function getMultiKeys(toIndex: number) {
        var arr = curstt!.Records.filter((r, idx) => {
            if ((idx >= selectedRecordIndex && idx <= toIndex) || (idx <= selectedRecordIndex && idx >= toIndex)) return true;
            return false;
        }).map(r => r.RecordKey!);
        return new Set(arr)
    }

    function setKeys(keys: Set<string>, select: boolean, unselectOthers?: boolean) {
        var seld = [...gridProps.RecordKeys];
        if (unselectOthers) seld = [...keys];
        else if (select) seld = seld!.concat([...keys]);
        else seld = seld.filter(x => x !== [...keys][0]);
        setGridProps(prev => ({ ...prev!, RecordKeys: new Set(seld) }));
        if (props.handleSelection) handleSelection(seld);
    }

    function handleSelection(selected: string[]) {
        let selRowData: { id: number, name: string } = { id: 0, name: "" };
        const rData = curstt?.Records?.find(x => x.RecordKey === selected[0]);
        if (rData) selRowData = { id: rData.RecordID!, name: rData.RecordName! };
        if (selRowData.id > 0) props.handleSelection && props.handleSelection(selRowData.id, selRowData.name);
    }

    if (pageCount == 2) around_the_page = around_the_page.concat([2]);
    else if (pageCount > 2) {
        if (curgp.Page == 1) around_the_page = around_the_page.concat(makeNext());
        else if (curgp.Page! == pageCount) around_the_page = around_the_page.concat(makePrev(), [curgp.Page!]);
        else if (curgp.Page! == 2) around_the_page = around_the_page.concat([curgp.Page!], makeNext());
        else around_the_page = around_the_page.concat(makePrev(), [curgp.Page!], makeNext());
    }

    function makeNext(): number[] {
        var next;
        if (curgp.Page! == pageCount - 1) next = [pageCount];
        else {
            next = [pageCount + 1].concat(range(curgp.Page! + 2, end));
            if (end < pageCount - 1) next = next.concat([-1, pageCount]);
            else if (end == pageCount - 1) next = next.concat([pageCount]);
        }
        return next;
    }

    function makePrev(): number[] {
        let prev: number[];
        if (gridProps.Page == 3) prev = [0];
        else if (gridProps.Page <= 7) prev = range(2, gridProps.Page - 2).concat([0]);
        else prev = [-1].concat(range(gridProps.Page - 5, gridProps.Page - 2), [0]);
        return prev;
    }

    const navdata = around_the_page.map(x => ({
        num: x == 0 ? curgp.Page - 1 : x == pageCount + 1 ? curgp.Page + 1 : x,
        text: x == 0 ? ctx.localized('Previous') : x == pageCount + 1 ? ctx.localized('Next') : x == -1 ? '...' : x.toString()
    }));

    const topstyle: React.CSSProperties = { position: "sticky", top: 0, backgroundColor: "white", whiteSpace: "nowrap", padding: 4 }
    const actionItems = commands && prioritizeCommands(ctx, commands.Commands);

    const sortcols = curstt?.Columns.sortBy(x => x.SelectedPosition);

    const sButtons: React.CSSProperties = { backgroundColor: "var(--primary)", color: "white", borderRadius: 5, whiteSpace: "nowrap", padding: 4, fontSize: 12, cursor: "pointer" }
    const sCell: React.CSSProperties = { padding: "4px 8px", color: "var(--primary)" }
    if (curstt?.ValueBorderColor) {
        sCell.borderColor = numberColor(curstt?.ValueBorderColor)
        sCell.borderStyle = "solid"
        sCell.borderWidth = 1
    }

    async function docmd(c: ControlProps, p: any) {
        const mdl = props.modelGetter && props.modelGetter()
        await doCommand(c, props.controller!, totalRows, gridProps, p,()=> refreshTable(), app, mdl, menuField, async x => {
            let fld = gridProps.Fields.find(z => z.SelectName === x.SelectName)
            if (!fld) {
                fld = defaultMiniReportField(x.FieldName!)
                fld.SelectName = x.SelectName
                gridProps.Fields.push(fld)
            }
            Object.assign(fld, x);
            setGridProps(prev => ({ ...prev, Fields: curgp.Fields }))
            await refreshTable();
        }, menuFieldValue);
    }

    const suggAborter = React.useRef(new AbortController());
    const hasfilter = gridProps.Term || gridProps.Fields.some(x => x.AutoFilter || x.DateRange || x.FilterList || x.FilterText || x.IDFilter || x.MinFilter || x.MaxFilter || x.StartSpan || x.EndSpan || x.BarFilterValues.length);
    const inpstyle: React.CSSProperties = { borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: "var(--secondary)", padding: 2 }

    const dispTypes = [ReportTypes.List, ReportTypes.Calendar, ReportTypes.Cards]
    return <div id={"TestGrid" + props.gridProps.ReportID} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", overflowY: "hidden", height: "100%" }} ref={ref}>
        <div id="div6" style={{ margin: 5 }}>
            {(state?.Filters?.length || 0) > 0 && <div style={{ padding: 6, display: "flex", gap: 10 }}>
                {state?.Filters.map(x => {
                    if (x.Group == 0) return x.Descriptions.map(y => <span key={y.Key}>{y.Key}:&nbsp;<b>{y.Value}</b></span>);
                    else {
                        const orFilters = x.Descriptions.map<React.ReactNode>(y => <span key={y.Key}>{y.Key}:&nbsp;<b>{y.Value}</b></span>)
                        if (orFilters.length > 0)
                            return orFilters.map<React.ReactNode>(t => <span>{t}</span>).reduce((prev, current) => [prev, (<b style={{ color: "lightblue" }}> {ctx.localized("Or")} </b>), current]);
                        else
                            return null;
                    }
                })}
            </div>}
            {props.showTools !== false && <div style={{ display: "flex", gap: 3 }}>
                {commands?.Commands.filter(x => !x.NeedsField).sortBy(x => [-x.Uses, x.DefaultPosition]).slice(0, 6).map(x => <Avatar2 key={x.Name} {...x} RecordType={state?.ListType} gridProps={gridProps} totalRows={totalRows} reloader={() => refreshTable()} />)}
                <button type="button" onClick={() => refreshTable()} style={sButtons}>{ctx.localized("Refresh")}</button>
                <div style={{ width: "100%" }} onMouseLeave={() => setShowSuggestions(false)}><input id="TestSearch" type="search" value={gridProps.Term} placeholder={ctx.localized("Search")} style={{ ...inpstyle, width: "100%", height: "100%" }} onChange={async e => {
                    const trm = e.currentTarget.value;
                    setGridProps(prev => ({ ...prev!, Term: trm }));

                    if (suggAborter.current) suggAborter.current.abort();
                    suggAborter.current = new AbortController();
                    gridProps.Term = trm//state might not ye be set, react....
                    const suggs = await MachshevetClient.GetSuggestions(props.controller!, gridProps, suggAborter.current.signal);
                    setSuggestions(suggs);
                    setShowSuggestions(true);
                }} onKeyDown={inputKeyDown} onMouseEnter={() => setShowSuggestions(true)} />
                    {showSuggestions && suggestions && <div style={{ position: "absolute", backgroundColor: "white", zIndex: 2, borderColor: "lightgray", borderWidth: 2, borderStyle: "solid", padding: 3, borderRadius: "0px 0px 5px 5px", width: "100%" }} >{suggestions?.map(x => <div key={x} style={{ padding: 2 }} onClick={e => {
                        setGridProps(prev => ({ ...prev!, Term: x }));
                        setSuggestions(undefined);
                    }}>{x}</div>)}</div>}
                </div>
                <VecIcon name="ClearFilters" width={28} color={hasfilter ? "orange" : ""} onClick={() => {
                    gridProps.Fields = []
                    gridProps.Term = "";
                    gridProps.RecordKeys.clear();
                    gridProps.Page = 1;
                    gridProps.InputParams = [];
                    setGridProps(gridProps);
                    setSuggestions(undefined);
                    filterChanged(true);
                }} />
                <select style={inpstyle} onChange={e => {
                    var rid = +e.currentTarget.value
                    gridProps.FilterReportID = rid
                    setGridProps(gridProps)
                    refreshTable()
                }}><option key="">{ctx.localized("ReportFilter") + "..."}</option>{state && state.FilterReports && state.FilterReports.map(x => <option key={x.Key} value={x.Key}>{x.Value}</option>)}</select>
                <NavLink controller={state?.ListType} action="Import" style={sButtons}>{ctx.localized("Import")}</NavLink>
                <NavLink controller={state?.ListType} action="Insert" style={sButtons}>{ctx.localized("Insert")}</NavLink>
                <VecIcon color={numberColor(ctx.data.PrimaryColor)} name={state?.ShowPreview ? "HidePreview" : "ShowPreview"} width={28} onClick={async () => {
                    var newvalprevw = !state?.ShowPreview
                    setState(prev => ({ ...prev!, ShowPreview: newvalprevw }))
                    MachshevetClient.Global.SetSetting(gridProps.SettingKey!, newvalprevw.toString(), SettingGroup.ShowPreview, state?.ListType, props.gridProps?.ReportID)
                }} />
                <VecIcon id="TestShowHamburger" color={numberColor(ctx.data.PrimaryColor)} name={menuShown ? "HideMenu" : "ShowMenu"} width={28} onClick={async () => {
                    setMenuShown(!menuShown)
                }} />
            </div>}

            <div style={{ display: "flex", gap: 10, padding: 5 }}>
                {state?.InputParams?.map(x => <div key={x.Name} style={{ minWidth: 300, display: "flex" }}><div style={{ whiteSpace: "nowrap" }} >{x.DisplayName}</div><Control term={gridProps.Term} field={{
                    ...x, RecordType: state.ListType, Value: gridProps.InputParams.find(y => y.Key === x.Name)?.Value,
                    onChange: async e => {
                        if (gridProps) {
                            const ip = gridProps.InputParams.find(y => y.Key === x.Name);
                            if (ip) ip.Value = e;
                            else gridProps.InputParams.push({ Key: x.Name!, Value: e });
                            setGridProps(gridProps);
                        }
                        await filterChanged(true)
                    }
                }} /></div>
                )}
            </div>
        </div>
        {actionItems && menuPosition && <ContextMenu onCommand={(c, p) => docmd(c, p)} items={actionItems} position={menuPosition} quickAdds={commands!.QuickAdds} />}
        <div id="div3" style={{ display: "flex", gap: 3, overflowY: "hidden", flexGrow: 1, borderWidth: 1, padding: 10, borderStyle: "solid", borderColor: "var(--secondary)", margin: 5, borderRadius: 6 }}>
            {state && state.BarFilters && <div id="TestBarFilters" style={{ padding: 4, overflowY: "auto" }}>
                {state.BarFilters.map(x => {
                    const bfs = gridProps.Fields
                    const fld = x.FieldName!
                    let bf = bfs.find(z => z.FieldName === fld)
                    return <div key={x.DisplayName}>
                        <span style={{ fontWeight: "bold" }}>{x.DisplayName}</span>
                        <div>
                            {x.Options.map(y => {
                                const ischk = bf && bf.BarFilterValues?.includes(y.Key!) ? true : false
                                const isdropping = y.Key === dropValue
                                return (
                                    <div style={{ whiteSpace: "nowrap" }} key={y.Key} onDragOver={e => e.preventDefault()} onDragEnter={() => setDropValue(y.Key)} onDrop={async () => {
                                        await MachshevetClient.Global.SetValue(state.ListType!, gridProps.RecordKeys, x.FieldName!, y.Key);
                                        await refreshTable();
                                        setDropValue(undefined);
                                    }}>
                                        <input id="TestBarFilterOption" type="checkbox" onChange={async e => {
                                            if (!bf) {
                                                bf = defaultMiniReportField(fld);
                                                bfs.push(bf);
                                            }
                                            if (e.target.checked) {
                                                if (!bf.BarFilterValues) bf.BarFilterValues = [];
                                                bf.BarFilterValues.push(y.Key!);
                                            } else {
                                                bf.BarFilterValues = bf.BarFilterValues.filter(x => x.toString() !== String(y.Key))
                                            }
                                            setGridProps(prev => ({ ...prev, Fields: bfs }));
                                            await filterChanged(true);
                                        }} checked={ischk} /><span style={{ padding: 2, fontWeight: isdropping ? 900 : "unset", fontSize: isdropping ? 16 : "unset" }}> {y.Value}</span><span style={{ fontSize: 10, padding: 2 }}> {y.Count}</span>
                                    </div>)
                            })}
                        </div>
                    </div>
                })}
            </div>}
            <>
                {gridProps.DisplayType == ReportTypes.Calendar ?
                    <Calendar {...props} records={state?.Records} listType={state?.ListType} gridProps={gridProps} onPropsChanged={async e => {
                        setGridProps(e)
                        //filterChanged()
                        await refreshTable()
                    }} />
                    : gridProps.DisplayType === ReportTypes.Cards ? <CardList controller={props.controller} activeTab={props.activeTab} gridProps={gridProps} records={state?.Records} commands={actionItems} doCommand={docmd} onSelected={e => setKeys(new Set([e]), true, true)} />
                        :
                        <table id="TestGridTable" className="dataTableTable" tabIndex={1} style={{ display: "block", whiteSpace: "nowrap", flexGrow: 1, flexBasis: 0, overflowY: "auto", borderCollapse: 'collapse' }}>
                            <thead>
                                <tr className="dataTableHeader" style={{ backgroundColor: "white" }} >
                                    <th style={topstyle}></th>
                                    <th className="thCheck" style={topstyle}>
                                        <input type="checkbox" onClick={async e => {
                                            var ischecked = e.currentTarget.checked
                                            if (ischecked) {
                                                const data = await MachshevetClient.GetAllIDs(props.controller!, gridProps);
                                                //var kys=data!.map((x: { toString: () => any; }) => x.toString())
                                                var kys = data!.map(x => x.toString())
                                                setKeys(new Set(kys), true, true);
                                            } else setKeys(new Set([]), false, true);
                                        }} />
                                        <span>{gridProps.RecordKeys.size}</span>
                                    </th>
                                    {sortcols && sortcols.map(x => <ColumnHeader controller={props.controller!} key={x.Name} columnData={x} viewTable={curstt!} gridProps={curgp!}
                                        fullPage={props.fullPage!} refresher={() => filterChanged(true)}
                                        stateSetter={y => {
                                            var fld = curgp.Fields.find(z => z.SelectName === x.SelectName);
                                            if (!fld) {
                                                fld = defaultMiniReportField(x.Name!);
                                                fld.SelectName = x.SelectName;
                                                curgp.Fields.push(fld);
                                            }
                                            Object.assign(fld, y);
                                            setGridProps(prev => ({ ...prev!, Fields: curgp.Fields }));
                                            filterChanged(true);
                                        }}
                                        editModeToggler={() => {
                                            if (editColumns.includes(x.Name!)) {
                                                var newlst = editColumns.filter(z => z !== x.Name);
                                                setEditColumns(newlst);
                                            } else {
                                                var newlst = editColumns.concat(x.Name!);
                                                setEditColumns(newlst);
                                            }
                                        }} />)}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    curstt && curstt.Records && curstt.Records.map((rowData, i) => {
                                        var recid = rowData.RecordID!
                                        var rectyp = rowData.RecordType!
                                        var backclr = rowData.BackColor
                                        var reckey = rowData.RecordKey || (rectyp + '.' + recid + '.' + i)
                                        //const isSelected = gridProps.RecordKeys.indexOf(reckey) > -1
                                        const isSelected = gridProps.RecordKeys.has(reckey)
                                        var visflds = rowData.Fields.sortBy(x => x.Position)
                                        return <tr id={"TestRow" + i} draggable key={reckey} className={`gridRow hoverElem ${isSelected ? 'selectedrow' : ''}`}
                                            onDoubleClick={_ => redirect(undefined, rectyp, recid)}
                                            onClick={e => {
                                                var shiftKey = e.shiftKey;
                                                props.onRowClicked && props.onRowClicked(recid, rowData.RecordName!);
                                                setMenuPosition(undefined);
                                                if (shiftKey) {
                                                    var selctdKeys = getMultiKeys(i);
                                                    setKeys(selctdKeys, true, true);
                                                }
                                                else {
                                                    setKeys(new Set([rowData.RecordKey!]), true, true);
                                                    setSelectedRecord(rowData);
                                                    setSelectedRecordIndex(i);
                                                }
                                            }}
                                            onContextMenu={e => {
                                                e.preventDefault();
                                                if (!isSelected) {
                                                    setKeys(new Set([rowData.RecordKey!]), true);
                                                    setSelectedRecord(rowData);
                                                    setSelectedRecordIndex(i);
                                                }
                                                setMenuPosition([e.clientX, e.clientY]);
                                            }}
                                            style={{ backgroundColor: numberColor(backclr), color: numberColor(rowData.ForeColor), borderBottom: "solid 1px", borderBottomColor: " var(--secondary-light)" }}
                                        >
                                            <td><span style={{ color: 'var(--secondary)' }}>{i + 1}</span></td>
                                            <td className="grid-cell">
                                                <input type="checkbox" style={{ width: "100%" }}
                                                    checked={isSelected}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setMenuPosition(undefined);
                                                    }}
                                                    onChange={e => {
                                                        var shiftKey = (e.nativeEvent as unknown as PointerEvent).shiftKey;
                                                        const checked = e.currentTarget.checked;
                                                        var kys: Set<string>;
                                                        if (shiftKey && checked && selectedRecordIndex !== undefined && selectedRecord !== rowData) kys = getMultiKeys(i);
                                                        else kys = new Set([rowData.RecordKey!]);
                                                        setKeys(kys, checked);
                                                        if (checked && !(shiftKey && selectedRecord !== undefined)) {
                                                            setSelectedRecord(rowData);
                                                            setSelectedRecordIndex(i);
                                                        }
                                                        return false;
                                                    }}
                                                />
                                            </td>
                                            {visflds.map(x => {
                                                var stl = { ...sCell }
                                                var y: ControlProps2 = x
                                                y.recordID = rowData.RecordID
                                                y.recordKey = rowData.RecordKey
                                                y.reloader = refreshTable
                                                if (editColumns.indexOf(x.Name!) > -1) y.Editable = true
                                                if (x.BackColor) stl.backgroundColor = numberColor(x.BackColor)
                                                if (x.FieldType === FieldTypes.Money) {
                                                    var nVal = +x.Value;
                                                    stl.backgroundColor = !nVal ? "" : nVal > 0 ? "lightgreen" : "pink";
                                                }
                                                if (x.Name === menuField) {
                                                    stl.borderWidth = 1
                                                    stl.borderColor = "var(--primary)"
                                                    stl.borderStyle = "dotted"
                                                }
                                                if (y.Editable) {
                                                    if (!y.style) y.style = {};
                                                    y.style.fontWeight = 'bold';
                                                }
                                                return <td key={x.Name} style={stl} onContextMenu={e => {
                                                    e.preventDefault();
                                                    setMenuPosition([e.clientX, e.clientY]);
                                                    setMenuField(x.Name);
                                                    setMenuFieldValue(x.Value);
                                                    document.addEventListener('click', () => setMenuPosition(undefined));
                                                }
                                                } ><Control term={gridProps.Term} field={{
                                                    ...y, onChange: async () => { }, onBlur: async e => {
                                                        var fldnm = x.IDFieldName || x.Name!;
                                                        await MachshevetClient.ApiSaveEdit(rowData.RecordType!, { ID: rowData.RecordID, [fldnm]: e }, window.location.href);
                                                        refreshTable();
                                                    }
                                                }} /></td>
                                            })}
                                        </tr>
                                    })}
                            </tbody>
                            {state && <tfoot style={{ backgroundColor: "white" }}>
                                <tr style={{ fontWeight: "bold" }}>
                                    <td style={{ position: "sticky", bottom: 0, backgroundColor: "white" }} />
                                    <td style={{ position: "sticky", bottom: 0, backgroundColor: "white" }} />
                                    {sortcols && sortcols.map(x => {
                                        return <td key={x.Name} style={{ position: "sticky", bottom: 0, backgroundColor: "white", padding: "3px 5px" }}
                                        >{state.Sums[x.Name!]}</td>
                                    })}
                                </tr>
                            </tfoot>}
                        </table>
                }
            </>
            {state?.ShowPreview && selectedRecord && <div style={{ overflow: "auto", margin: 10, maxWidth: "40%" }}>{<Preview recordType={selectedRecord.RecordType!} id={selectedRecord.RecordID!} miniMode={false} />}</div>}
            {menuShown && commands && <HamburgerMenu onCommand={async (c, p) => {
                await docmd(c, p)
                setMenuShown(false)
            }} items={commands.Commands} />}
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
                <span>{ctx.localized('Showing') + ' ' + (firstID + 1) + ' ' + ctx.localized('To') + ' ' + lastNum + ' ' + ctx.localized('Of')}</span><span id="TestTotalRows">{totalRows}</span><span>{ctx.localized('Records')}</span>
            </div>
            <span>
                {ctx.localized('Show') + ' '}
                <select
                    value={curstt?.PageRows}
                    onChange={async e => {
                        await MachshevetClient.Global.SetSetting(gridProps.SettingKey!, e.currentTarget.value, SettingGroup.PageRows, state?.ListType, props.gridProps?.ReportID)
                        await refreshTable();
                    }}
                >{curstt?.PageRows} {ctx.data.ResultOptions && leftCut(ctx.data.ResultOptions, ',').split(',').map(x => <option value={x} key={x}>{x}</option>)}
                </select>
                {' ' + ctx.localized('Records')}
            </span>

            <div>{dispTypes.map(x => <span key={x} style={{ padding: 3 }}> <VecIcon width={16} name={ReportTypes[x]} onClick={() => { setGridProps(prev => ({ ...prev!, DisplayType: x })) }} /></span>)}</div>

            <div className='page-navigator'>
                {navdata.map(x => <span key={x.num} style={{ backgroundColor: x.num === curgp.Page ? 'var(--primary)' : 'var(--primary-light)' }}
                    onClick={x.num != -1 ? async () => {
                        curgp.Page = x.num;
                        await filterChanged(true)
                    } : async () => {
                        await filterChanged(true)
                    }}>{x.text}</span>)
                }
            </div>
        </div>
    </div>
}
TableBody.displayName = "TableBody";

export const CardList: FC<TableBodyProps> = props => {
    const [menuPosition, setMenuPosition] = useState<[number, number] | undefined>();
    return <>
        {menuPosition && <ContextMenu onCommand={props.doCommand!} items={props.commands!} position={menuPosition} />}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 7, flexWrap: 'wrap' }} >{props.records?.map(x => {
            const selkeys = props.gridProps?.RecordKeys
            //const isSelected = selkeys && selkeys.indexOf(x.RecordKey!) > -1
            const isSelected = selkeys && selkeys.has(x.RecordKey!)
            return <RecordCard key={x.RecordKey} record={x} isSelected={isSelected} />
        })}</div>
    </>
}

export const RecordCard: FC<{ record: RecordData, isSelected?: boolean, onSelected?: (key: string) => void, onContextMenu?: (coords: [number, number]) => void }> = props => {
    const rec = props.record
    let recname = rec.RecordName
    if (!recname) recname = rec.Fields.find(y => y.Name === 'Name')?.Value
    const relvfields = rec.Fields.filter(y => y.Name !== 'Name' && y.Value).sortBy(y => y.Position)
    var backclr = "var(--primary)"
    var stl: CSSProperties = { borderWidth: 1, borderStyle: 'solid', borderRadius: 8 }
    if (props.isSelected) {
        stl.borderWidth = 2
        backclr = "orange"
    }
    stl.borderColor = backclr
    return <div draggable key={rec.RecordKey} style={stl} onClick={() => props.onSelected!(rec.RecordKey!)} onDoubleClick={() => redirect(undefined, rec.RecordType, rec.RecordID)} onContextMenu={e => props.onContextMenu && props.onContextMenu([e.clientX, e.clientY])} onDragStart={() => props.onSelected!(rec.RecordKey!)}><div style={{ fontWeight: 'bold', color: "white", padding: 5, backgroundColor: backclr, borderRadius: "8px 8px 0px 0px" }}>{recname}</div> <div style={{ padding: 5 }}>{relvfields.map(y => <div key={y.Name} style={{ display: 'flex', gap: 3 }}><VecIcon name={y.Icon!} width={14} title={y.DisplayName} color="var(--primary-light)" /><DisplayBox {...y} /></div>)}</div> </div>
}