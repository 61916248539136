//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useRef, HTMLProps, useEffect, useContext } from 'react';
import { VecIcon, NavLink } from './misc';
import { GridProps, MachshevetClient, RecordData, SettingGroup } from './Declarations';
import { AppContext, ControlProps2, defaultGridProps, defaultRecordData, MainContext, numberColor } from './globals';
import { DisplayBox } from './pickers';
import { RecordCard, TableBody } from './tableBody';

export const EntityPick: FC<ControlProps2> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext)
    const [options, setOptions] = useState(props.Picklist)
    //const [searchResultsHidden, setSearchResultsHidden] = useState(true);
    const [showResults, setshowResults] = useState(false);
    const [search, setSearch] = useState<string>(props.RecordName || props.Value || '');//props.value is for grid editmode
    const [selection, setSelection] = useState<{ id: number, name: string }>({ id: 0, name: "" });
    const keyDownHandler = useRef<HTMLProps<HTMLInputElement>['onKeyDown']>();
    const pickRef = useRef<HTMLDivElement>(null);
    const refGrid = useRef<GridProps>({ ...defaultGridProps(), ReportID: props.PickReportID, Term: search, SettingGroup: SettingGroup.PickColumns, Member: props.Name || props.Member, Command: props.mainCommand });//putting props.name first cuz of loan>buildinggroupid>changevalue>newvalue
    const [selectedIndex, setSelectedIndex] = useState<number>();
    const [showCard, setShowCard] = useState(false);
    const [miniRec, setMiniRec] = useState<RecordData>();
    const timeout = useRef<number>();

    useEffect(() => {
        if (props.Editable) getRecordName();
    }, [props.Value])

    useEffect(() => {
        refGrid.current.Term = search;
    }, [search]);

    function handleSelection(id: number, name: string) {
        setSelection({ id, name });
    }

    useEffect(() => {
        if (props.Editable) {
            const a = (e: Event) => pickRef.current &&
                e.target instanceof HTMLElement &&
                !pickRef.current.contains(e.target) &&
                setshowResults(false);
            document.addEventListener('click', a);
            return () => document.removeEventListener('click', a);
        }
        return
    }, [])

    async function getRecordName() {
        var id = parseInt(props.Value)
        if (id && !options) {
            const name = await MachshevetClient.GetRecordName(props.ForeignTypeName!, id)
            setSearch(name!)
        }
        if (!props.Value && search) setSearch('')
    }

    async function refresh(force: boolean) {
        if (props.RecordType) {//options cant work with recordtype
            var rec = props.modelGetter && props.modelGetter()
            if (force || rec.ID || !options || options.length == 0 || props.RecordType.endsWith("Input")) {//it shouldnt work in new recordadapter, options length will be 0 in bulkedit popup
                if (props.RecordType.endsWith("Input")) {
                    const res = await MachshevetClient.Global.GetInputOptions(props.mainRecordType!, props.mainCommand!, props.Name!, rec);
                    setOptions(res);
                } else {
                    //const res = await MachshevetClient.GetSelectOptions(props.recordType, rec, props.Name!);
                    const res = await MachshevetClient.GetSelectOptions(props.RecordType, rec, props.Name!);
                    setOptions(res!);
                }
            }
        }
    }

    function handleNameInput(event: React.FormEvent<HTMLInputElement>) {
        const value = event.currentTarget.value;
        if (!value) props.onChange && props.onChange(null);
        else setshowResults(true);
        setSearch(value);
        event.stopPropagation();
        return false;
    };

    function handleSelect(id: number, name: string) {
        setSearch(name);
        props.onChange && props.onChange(id);
        setshowResults(false);
        props.onBlur && props.onBlur(id);
    };

    function inputKeyDown(event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) {
        const key = event.key;
        const strtidx = selectedIndex === undefined ? -1 : selectedIndex
        if (key === 'ArrowDown') {
            if (!showResults) setshowResults(true);
            else setSelectedIndex(strtidx + 1);
        }
        else if (key === 'ArrowUp') setSelectedIndex(strtidx - 1);
        else if (key === 'Enter' && selection.id > 0) handleSelect(selection.id, selection.name);
    };

    function triggerOpen() {
        timeout.current = window.setTimeout(async () => {
            let rid = props.ForeignID
            if (!props.ForeignTypeName) rid = props.recordID
            if (rid) {
                const pr = await MachshevetClient.Global.Preview(props.ForeignTypeName || props.RecordType!, rid)
                setMiniRec(pr)
            }
        }, 1800);
    }

    return <div>{props.Editable ? <div id='div7' style={{ position: 'relative' }} ref={pickRef}>
        <div style={{ display: 'flex' }}>
            {options ?
                <select id={"Test" + props.Name} value={props.Value || ""} style={{ ...props.style, width: '100%', padding: 3, fontSize: 16 }} onClick={() => refresh(false)} disabled={props.Locked} onChange={e =>  props.onChange!(e.currentTarget.value)}>
                    <option key='0'>{ctx.localized('Choose') + '...'}</option>
                    {options.options()}
                </select>
                : <>
                    <input disabled={props.Locked} tabIndex={0} autoComplete="off" onChange={handleNameInput} id={"Test" + props.Name} type='text' value={search} style={{ ...props.style, width: '100%', padding: 3, fontSize: 16 }} onDoubleClick={e => e.stopPropagation()} onFocus={() => setshowResults(true)} onKeyDown={e => {
                        !keyDownHandler.current || keyDownHandler.current(e);
                        inputKeyDown(e);
                    }}
                    />
                </>
            }
            <NavLink controller={props.ForeignTypeName} recordID={props.Value}>
                <VecIcon name="Record" onMouseEnter={() => {
                    setShowCard(true);
                    triggerOpen()
                }}
                    onMouseLeave={() => setShowCard(false)} color="var(--secondary)" />
            </NavLink>
            <VecIcon key='imgadd' name='Add' color='var(--secondary)' width={24} onClick={e => {
                const rd = defaultRecordData(props.ForeignTypeName!)
                app.openRecord(rd, async id => {
                    await refresh(true)
                    await props.onChange!(id)
                }, { Key: 'Name', Value: search });
            }} />
        </div>
        {showResults && <div key='divSearchResults' className='divSearchResults' style={{ border: '3px solid #2bb7ea', backgroundColor: 'white', zIndex: 10 }}>
            <TableBody activeTab={true} showTools={false} controller={props.mainRecordType || props.RecordType!} saveState={false}
                handleSelection={handleSelection}
                gridProps={refGrid.current}
                idxSelected={selectedIndex}
                resetSelected={e => setSelectedIndex(e)}
                onRowClicked={(id, name) => handleSelect(id, name)}
                modelGetter={props.modelGetter}
                commandInputGetter={props.commandInputGetter}
            />
        </div>}
    </div>
        :
        <NavLink controller={props.ForeignTypeName || props.RecordType} recordID={props.ForeignID || props.Value} style={{ backgroundColor: numberColor(props.BackColor), display: 'inline-block', position: 'relative' }}
            onMouseEnter={() => {
                setShowCard(true);
                triggerOpen()
            }}
            onMouseLeave={() => setShowCard(false)}
        ><DisplayBox {...props} />
        </NavLink>}
        {showCard && miniRec && <div onMouseLeave={() => setShowCard(false)} style={{ position: 'absolute', backgroundColor: 'white', zIndex: 1 }}><RecordCard record={miniRec} /></div>}
    </div>
}
EntityPick.displayName = 'EntityPick';