//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useEffect, FC, MouseEvent, useContext } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { Icon, VecIcon } from "./misc";
import TextareaAutosize from 'react-textarea-autosize';
import { Editor as TinyMCEEditor } from 'tinymce';
import { AppContext, closestInterval, colorNumber, ControlProps2, divideFloat, docSource, KeyValuePair, leftCut, MainContext, multipliers, normalDate2, numberColor, parseDate, ReadBytes } from "./globals";
import { FieldTypes, FileProps, Intervals, MachshevetClient, Units } from "./Declarations";
import { MiniCal } from "./calendar";
import { devLog } from "./shared";

export const PdfPick: FC<ControlProps2> = props => {
    const handleFileChosen = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            const file = event.currentTarget.files[0];
            const base64 = (await ReadBytes(file)).toString();
            const withoutHeader = base64.substring(base64.indexOf(',') + 1, base64.length);
            props.onChange!(withoutHeader);
        }
    }
    var src = docSource(props.RecordType!, props.recordID!, props.Name!)
    var toshow = props.recordID && !props.RecordType?.endsWith("Input") ? true : false
    return <div>{props.Editable && <div><input type="file" onChange={handleFileChosen} name={props.Name + 'PostFile'} accept="application/pdf" /><VecIcon name="Download" onClick={() => window.location.href = docSource(props.RecordType!, props.recordID!, props.Name!, undefined, true)} /></div>}
        {toshow && <embed src={src} style={{ width: "100%", height: 400 }} />}
    </div>
}

export const ImagePick: React.FC<ControlProps2> = props => {
    var src = props.Value ? 'data:image/*;base64,' + encodeURI(props.Value.toString()) : undefined
    const handleFileChosen = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            const file = event.currentTarget.files[0];
            const base64 = (await ReadBytes(file)).toString();
            const withoutHeader = base64.substring(base64.indexOf(',') + 1, base64.length);
            var newval = withoutHeader.length < 3315784 ? withoutHeader : file//pretty arbitrary number. but meanwhile works
            props.onChange!(newval)
            //if (withoutHeader.length < 3315784) props.onChange!(withoutHeader);//pretty arbitrary number. but meanwhile works

        }
    }
    return props.Editable ? <div>
        <input type="file" onChange={handleFileChosen} style={{ display: 'block' }} name={props.Name + 'PostFile'} accept="image/*" />
        <img src={src} style={{ maxWidth: "100%" }} />
    </div>
        : src ? <img src={src} style={{ width: "100%" }} /> : <></>
}

export const TifPick: FC<ControlProps2> = props => {
    const [page, setPage] = useState(1);
    var pgs = (props.Value as FileProps).Pages
    var paglist = Array.from(Array(pgs).keys())
    var src = docSource(props.RecordType!, props.recordID!, props.Name!, page)
    return <div><div style={{ display: "flex", gap: 5, justifyContent: "center" }}>{paglist.map(x => <span style={{ border: "var(--primary)", borderWidth: 1, borderStyle: "solid", padding: 3, cursor: "pointer", fontWeight: x + 1 === page ? 900 : "unset" }} onClick={() => setPage(x + 1)}>{x + 1}</span>)}</div><img src={src} /></div >
}

export const FilePick: FC<ControlProps2> = props => {
    var app = useContext(AppContext)
    const val: FileProps = props.Value
    var vref = React.useRef<HTMLVideoElement>(null);
    const [video, setVideo] = useState(false);
    const elm = () => {
        const enc = encodeURI(val.Bytes?.toString()!);
        switch (val.Type) {
            case "pdf":
                return <PdfPick {...props} Editable={false} />
            case "doc":
            case "txt":
                return <div>{val.Text}</div>
            case "tif":
                return <TifPick {...props} Editable={false} />
            case "docx":
            case "htm":
            case "html":
                return <div dangerouslySetInnerHTML={{ __html: val.Html! }} />
            default: return <>
                <VecIcon name="Print" onClick={() => {
                    //printUrl('', "<html><body><div><figure> <img src=\"data:image/*;base64," + val.Bytes + "\"style={width:\"100%\";}/></figure></div></body></html>")
                    app?.printHtml("<html><body><div><figure> <img src=\"data:image/*;base64," + val.Bytes + "\"style={width:\"100%\";}/></figure></div></body></html>")
                }} />
                <img src={'data:image/*;base64,' + enc} style={{ maxWidth: "100%" }} />
            </>
        }
    }

    return <div>
        <div><input type="file" onChange={async e => {
            const trg = e.currentTarget;
            if (trg.files && trg.files[0]) {
                const file = trg.files[0];
                const fp: FileProps = {
                    Bytes: '', Type: file.type, File: file, Pages: 0
                }
                if (props.changeProp) {
                    await props.changeProp('Title', file.name, false)
                    await props.changeProp('Name', file.name, false)
                    const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
                    await props.changeProp('FileType', ext, false)
                }
                await props.onChange!(fp)
            }
        }} /><VecIcon name="StartCamera" onClick={() => {
            setVideo(true)
            navigator.mediaDevices.getUserMedia({ video: true }).then(strm => {
                var vid = vref.current!
                vid.srcObject = strm
                vid.play()
            })
        }} />   <VecIcon name="Capture" onClick={() => {
            var vid = vref.current!
            var canvas = document.createElement('canvas');
            canvas.width = vid.offsetWidth;
            canvas.height = vid.offsetHeight
            var context = canvas.getContext('2d')!
            context.drawImage(vid, 0, 0, vid.offsetWidth, vid.offsetHeight);
            var strn = canvas.toDataURL("image/jpeg").split(';base64,')[1];
            canvas.toBlob(blob => {
                var fil = new File([blob!], "fileName.jpg", { type: "image/jpeg" })
                val.Bytes = strn
                val.Type = "jpg"
                val.File = fil
            }, 'image/jpeg');
            props.onChange!(val)

        }} />
            <VecIcon name="Download" onClick={() => window.location.href = docSource(props.RecordType!, props.recordID!, props.Name!, undefined, true)} /></div>
        {video && <video ref={vref} />}
        {elm()}
    </div>
}
FilePick.displayName = "FilePick"

export const PhonePick: FC<ControlProps2> = props => {
    var p2: ControlProps2 = { ...props }
    var stl: React.CSSProperties = { ...p2.style }
    stl.direction = "ltr"
    p2.style = stl
    const handleClick = () => {
        MachshevetClient.Global.Dial(props.Value as number)
    }
    return props.Editable ? <div style={{ display: 'flex' }}><BasePick  {...p2} />{props.Value && <VecIcon name='Dial' onClick={handleClick} width={20} />}
    </div> :
        <span><a href={"tel:" + props.Value} style={{ display: "inline-block", direction: "ltr" }} ><DisplayBox{...props} /></a>{props.Value && <VecIcon name={'Dial'} onClick={handleClick} width={20} />}</span>
}

export const EmailPick: FC<ControlProps2> = props => {
    return props.Editable ? <BasePick  {...props} /> : <a href={"mailto:" + props.Value} style={{ display: "inline-block" }} ><DisplayBox{...props} /></a>
}

export const NumberPick: FC<ControlProps2> = props => {
    return <BasePick {...props} />
}
NumberPick.displayName = "NumberPick"

export const MoneyPick: FC<ControlProps2> = props => {
    var nVal = +props.Value;
    var backclr = !nVal ? undefined : nVal > 0 ? 9498256 : 16761035;
    var cp2 = { ...props }
    cp2.BackColor = backclr
    return <BasePick {...cp2} onClean={v => {
        v = leftCut(v, 'Fr.');
        v = v.replace(/[^\d.-]/g, '');
        if (v === "-") return ""
        return v
    }} />
}
MoneyPick.displayName = "MoneyPick"

const BasePick: FC<ControlProps2 & { onClean?: (v: string) => any, onFocus?: React.FocusEventHandler<HTMLInputElement>, onClick?: React.MouseEventHandler<HTMLInputElement> }> = props => {
    const [editFlag, setEditFlag] = useState(false);
    //const [rawText, setRawText] = useState("");
    const [rawText, setRawText] = useState(props.DisplayString);
    const [refreshing, setRefreshing] = useState(false);
    var stl: React.CSSProperties = { ...props.style, width: "100%", borderWidth: 1 }
    if (props.BackColor) stl.backgroundColor = numberColor(props.BackColor)
    var edtbl = props.Editable && !props.Locked
    if (props.Name === "NewValue") {
        var aa = 11
    }


    var showrawtext = editFlag || !props.serverRefresh || refreshing
    //var showrawval = editFlag ||   refreshing

    if (!showrawtext) {

        var aa = 11
    }

    //var valbind = showlocalval ? dispValue : props.DisplayString
    // var valbind = showrawtext ? props.Value : props.DisplayString
    var valbind = showrawtext ? rawText : props.DisplayString
    valbind = valbind || ""//null makes problems


    if (valbind && valbind.toString().includes("2028")) {
        var a = 1
    }



    //useEffect(() => {//needed mainly for datepick, where value is changed not via userinput (but rather via minical click)
    //    if (!props.serverRefresh && !editFlag) {
    //        var newval = props.Value
    //        if (newval instanceof Date) newval = newval.toLocaleString()
    //        setDispValue(newval)
    //    }
    //}, [props.Value])
    useEffect(() => {//needed mainly for datepick, where value is changed not via userinput (but rather via minical click)
        //if (!props.serverRefresh && !editFlag) {
        //    var newval = props.Value
        //    if (newval instanceof Date) newval = newval.toLocaleString()
        //    //setDispValue(newval)
        //    setDispValue(newval)
        //}
        if (!props.serverRefresh) {
            var newval = props.Value
            if (newval instanceof Date) newval = newval.toLocaleString()
            setRawText(newval)
        }
        //setEditFlag(true);
    }, [props.Value])

    return edtbl ? <input type='text' id={"Test" + props.Name} value={valbind} style={stl} onContextMenu={e => e.stopPropagation()} autoComplete="off"
        onChange={async e => {
            setEditFlag(true);
            const rawVal = e.currentTarget.value
            setRawText(rawVal)
            //setDispValue(rawVal);
            let cleanVal = rawVal
            if (props.onClean) cleanVal = props.onClean(cleanVal)//without this line typing - in moneypicks throws err
            setRefreshing(true)
            await props.onChange!(cleanVal)
            setRefreshing(false)
        }}
        onBlur={async e => {
            let vl = e.currentTarget.value
            if (props.onClean) vl = props.onClean(vl)
            if (editFlag) {
                await props.onChange!(vl)
                setEditFlag(false);
            }
            props.onBlur && props.onBlur(vl);
        }} onDoubleClick={e => e.stopPropagation()} onClick={props.onClick} onFocus={props.onFocus} />
        //stopPropagation is mainly for grid, in edit mode
        : <DisplayBox {...props} />
}

export const DisplayBox: FC<ControlProps2 & { getToolTip?: () => string }> = props => {
    const [changeTime, setChangeTime] = useState(Date.now() - 10000);
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (!firstLoad) setChangeTime(Date.now());
        else setFirstLoad(false);
    }, [props.Value])

    const cp2 = { ...props }
    const datediff = (Date.now() - changeTime) / 1000
    if (props.showChanges !== false && props.recordKey !== "0" && !props.Editable && (datediff < 10)) cp2.BackColor = 65535
    const outstyle: React.CSSProperties = {}
    if (props.FieldType === FieldTypes.Percent) {
        outstyle.backgroundColor = "aquamarine"
        outstyle.width = (props.Value * 100) + '%'
    }
    let ttip = ""
    if (props.getToolTip) { ttip = props.getToolTip() }
    return <div style={outstyle}>
        <div id={"Test" + props.Name} title={ttip} data-value={props.Value} style={{ userSelect: "all", backgroundColor: numberColor(cp2.BackColor), whiteSpace: cp2.WordWrap ? "break-spaces" : "pre", fontSize: cp2.FontSize || props.style?.fontSize, fontWeight: props.style?.fontWeight, padding: props.style?.padding, color: numberColor(cp2.ForeColor) || props.style?.color }}>{props.DisplayString}</div>
    </div>
}

export const MeasurePick: React.FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [unit, SetUnit] = React.useState<{ Key: number, Value: string }>();
    const [editFlag, setEditFlag] = useState(false);
    const [dispValue, setDispValue] = useState('');
    const [value, setValue] = useState(props.Value);

    function refreshUnit() {
        if (value) {
            const descSorted = props.Picklist.sortBy(x => -x.Key);
            const u = descSorted.find(x => value / x.Key >= 1)// || descSorted[descSorted.length - 1] //: props.Picklist.sort((a, b) => Math.abs(a.Key - 1) - Math.abs(b.Key - 1))[0]
            SetUnit(u);
            var vl = +value
            var ky = +u?.Key
            var endval = divideFloat(vl, ky);
            setValue(endval)
            setDispValue(endval.toString())
        }
    }
    useEffect(() => {
        if (props.Editable) {
            if (props.Picklist) refreshUnit();
            if (!value && !unit) {
                var lst = props.Picklist.sortBy(x => x.Key)
                var newunt = lst[0]
                //if (props)
                if (props.MeasureUnit) {
                    var untnam = Units[props.MeasureUnit]
                    var trnslt = ctx.localized(untnam.toString())
                    newunt = props.Picklist.find(x => x.Value === trnslt)!
                }
                SetUnit(newunt);
            }
        }

    }, [])

    return props.Editable ? <div style={{ display: "flex" }}>
        <input type='number' id={"Test" + props.Name} style={{ ...props.style, width: 60 }} value={editFlag || !props.serverRefresh ? dispValue : value}
            onChange={e => {
                setEditFlag(true);
                var inpt = e.currentTarget.value
                setDispValue(inpt);
                setValue(+inpt);
                var val = +inpt * (unit!.Key)// * 100) / 100;
                props.onChange!(val);
            }}
            onBlur={() => {
                setEditFlag(false);
                //refreshUnit()
            }} />
        <select value={unit?.Value} style={props.style} onChange={e => {
            const u = props.Picklist.find(x => x.Value === e.currentTarget.value)!;
            SetUnit(u)
            props.onChange!(value * u.Key);
        }}>
            {props.Picklist && props.Picklist.sort((a, b) => a.Key - b.Key).map(x => <option key={x.Key} value={x.Value}>{x.Value}</option>)}
        </select>
    </div> : <DisplayBox {...props} />
}

export const PercentPick: FC<ControlProps2> = props => {
    function divideByHundred(value: string) {
        var v = value.replace(/[^\d.-]/g, '');
        var val = v.split(".");
        var wholeval = val[0];
        var decimalValue = val[1];

        if (wholeval.startsWith("-")) wholeval = '-00' + wholeval.substring(1, wholeval.length);
        else wholeval = '00' + wholeval;

        var sStart = wholeval ? wholeval.substring(0, wholeval.length - 2) : '';
        var sEnd = wholeval ? wholeval.substring(wholeval.length - 2, wholeval.length) : '';
        var st2 = (sStart ? sStart : '') + '.' + (sEnd ? sEnd : '') + (decimalValue ? decimalValue : '');
        var val2 = parseFloat(st2);
        return val2;
    }
    return <BasePick {...props} onClean={v => divideByHundred(v)} />
}
PercentPick.displayName = "PercentPick"

export const ColorPick: React.FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const hex = props.Value ? numberColor(+props.Value) : null;//that plus is cuz sometimes it comes in as string
    return props.Editable ? <div>
        <input type="color" value={hex || ''} onChange={e => {
            props.onChange!(colorNumber(e.currentTarget.value))
        }} />
        <button type="button" onClick={() => {
            props.onChange!(null)
        }} >{ctx.localized('Clear')}</button>
    </div> : <div style={{ backgroundColor: numberColor(props.Value) }}>&nbsp;</div>
}

export const SpanPick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [[interval, value], setValue] = useState<[Intervals, number | undefined]>(
        props.Value !== undefined ?
            closestInterval(props.Value) :
            [Intervals.Millisecond, undefined]);

    useEffect(() => {
        if (props.Value) {
            const val = closestInterval(props.Value);
            setValue(val);
        }
    }, [props.Value]);

    const handleNumberChange = (e: React.FormEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value ? +e.currentTarget.value : undefined;
        setValue([interval, value]);
        props.onChange!(value !== undefined ? value * multipliers()[interval] : undefined)
    }
    const handleRangeChange = (e: React.FormEvent<HTMLSelectElement>) => {
        const interval = +e.currentTarget.value as Intervals;
        setValue([interval, value]);
        props.onChange!(value !== undefined ? value * multipliers()[interval] : undefined)
    }

    return props.Editable ? <div style={{ display: 'flex' }}>
        <select value={interval} onChange={handleRangeChange} style={props.style}>
            <option>{ctx.localized('Choose') + '...'}</option>
            {/*{Object.keys(Intervals).filter(key => !isNaN(+key)).map((k) => <option value={k} key={k} >{ctx.localized(Intervals[+k])}</option>)}*/}
            {Object.keys(Intervals).filter(x => !isNaN(+x)).map(x => <option value={x} key={x} >{ctx.localized(Intervals[+x] + 's')}</option>)}
        </select>
        <input type='number' value={value ? value.toString() : ''} onChange={handleNumberChange} style={props.style} />
    </div> : <DisplayBox {...props} />
}

export const UrlPick: React.FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    var str = props.Value ? props.Value as string : '';
    return props.Editable ?
        <div style={{ display: "flex" }}>
            <BasePick {...props} />
            <a href={str}>{ctx.localized("Navigate")}</a>
        </div>
        : <a href={str} style={{ display: "block" }}>{str}</a>
}

export const DatePick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [opened, setOpened] = useState(false)
    var dat = props.Value ? new Date(props.Value) : undefined
    return props.Editable ? <div style={{ position: "relative" }}  >
        <div style={{ display: "flex" }} > <BasePick {...props} onClick={() => setOpened(true)} onFocus={() => setOpened(true)} onClean={v => {
            //if (v === "") return v
            //var fmt = ctx.data.PickerDateFormat!
            //fmt = fmt.replace(/d+/i, "(?<a>\\d+)")
            //fmt = fmt.replace(/m+/i, "(?<b>\\d+)")
            //fmt = fmt.replace(/y+/i, "(?<c>\\d+)")
            //const regex1 = RegExp(fmt);
            //let [, a, b, c] = regex1.exec(v) || [];
            //if (!a) return ""
            //var dt = new Date(+c, +b - 1, +a)
            var dt = parseDate(v, ctx.data.PickerDateFormat!)
            if (!dt) return ""
            devLog("clean " + dt.toString(), dt)
            return dt
        }} /></div>
        {opened && <div tabIndex={-1} style={{ /*position: "absolute", zIndex: 2, backgroundColor: "white" */ }} onMouseLeave={() => setOpened(false)}><MiniCal value={dat} onSelected={async e => {
            //setThisdate(e)
            //if (!(e instanceof Date)  ) {
            //    var aaa = 11

            //}
            devLog("selected " + e.toString(), e)

            await props.onChange!(e)
            setOpened(false)

        }} /></div>}
    </div> : <DisplayBox {...props} getToolTip={() => {
        if (!props.Value) return ""
        var returntext = (num: number, interval: Intervals) => { return (Math.round(num * 100) / 100) + ' ' + ctx.localized(Intervals[interval] + 's') }
        var dt = new Date(props.Value)
        var curtim = new Date().getTime()
        var valtim = dt.getTime()
        var diff = Math.abs(valtim - curtim)
        var ret = ""
        if (diff < 1000) ret = returntext(diff, Intervals.Millisecond)
        else if (diff < 1000 * 60) ret = returntext(diff / 1000, Intervals.Second)
        else if (diff < 1000 * 60 * 60) ret = returntext(diff / 1000 / 60, Intervals.Minute)
        else if (diff < 1000 * 60 * 60 * 24) ret = returntext(diff / 1000 / 60 / 60, Intervals.Hour)
        else if (diff < 1000 * 60 * 60 * 24 * 7) ret = returntext(diff / 1000 / 60 / 60 / 24, Intervals.Day)
        else if (diff < 1000 * 60 * 60 * 24 * 30.4167) ret = returntext(diff / 1000 / 60 / 60 / 24 / 7, Intervals.Week)
        else if (diff < 1000 * 60 * 60 * 24 * 30.4167 * 12) ret = returntext(diff / 1000 / 60 / 60 / 24 / 30.4167, Intervals.Month)
        else ret = returntext(diff / 1000 / 60 / 60 / 24 / 30.4167 / 12, Intervals.Year)
        return ret
    }} />
}

export const BitPick: React.FC<ControlProps2> = props => {
    var ctx = useContext(MainContext)
    var val: boolean = props.Value
    if (props.Editable) {
        if (props.Required)
            return <div id={"Test" + props.Name} style={{ backgroundColor: val ? "#1890ff" : "lightgray", borderRadius: 10, display: "flex", justifyContent: val ? "end" : "start" }} onClick={e => {
                var vl = !val
                props.onChange!(vl)
            }}>
                <div style={{ height: 20, width: 15, borderRadius: 10, backgroundColor: "white", boxShadow: "0 0.1em 0.3em rgba(0,0,0,0.3)" }} />
            </div>
        else
            return <select id={"Test" + props.Name} value={props.Value} onChange={e => props.onChange!(e.currentTarget.value)} style={{ ...props.style, width: "100%" }}>
                <option id={"Test" + props.Name + "_"} value="null">{ctx.localized("Default")}</option>
                <option id={"Test" + props.Name + "_1"} value="true">{ctx.localized("Yes")}</option>
                <option id={"Test" + props.Name + "_0"} value="false">{ctx.localized("No")}</option>
            </select>
    }
    else {
        return <input type="checkbox" checked={props.Value} disabled={true} />
    }
}

export const GenderPick: React.FC<ControlProps2> = props => {
    var ctx = useContext(MainContext)
    return props.Editable ? <select style={{ ...props.style, width: "100%" }} value={props.Value} onChange={e => props.onChange!(e.currentTarget.value)}>
        <option value="true">{ctx.localized("Male")}</option>
        <option value="false">{ctx.localized("Female")}</option>
    </select> : <DisplayBox {...props} />
}

export const AudioPick: React.FC<ControlProps2> = props => {
    var src = 'data:audio/wav;base64,' + props.Value?.toString()
    const handleFileChosen = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.currentTarget.files && event.currentTarget.files[0]) {
            const file = event.currentTarget.files[0];
            const base64 = (await ReadBytes(file)).toString();
            const withoutHeader = base64.substring(base64.indexOf(',') + 1, base64.length);
            props.onChange!(withoutHeader);
        }
    }
    return <div><input type="file" onChange={handleFileChosen} style={{ display: 'block' }} name={props.Name + 'PostFile'} accept="audio/*" />
        <audio controls>
            <source src={src} />
        </audio>
    </div>
}

export const TextPick: FC<ControlProps2> = props => {
    return <BasePick {...props} />
}

export const LongTextPick: FC<ControlProps2> = props => {
    var dval = props.Value ? props.Value.toString() : ''
    if (!dval) {
        var a = 1
    }
    return <TextareaAutosize value={dval} style={{ width: "100%", borderRadius: props.style?.borderRadius, borderWidth: props.style?.borderWidth, borderStyle: props.style?.borderStyle, padding: props.style?.padding, fontSize: props.style?.fontSize, fontWeight: props.style?.fontWeight, color: props.style?.color }}
        onChange={async e =>
            await props.onChange!(e.currentTarget.value, undefined, undefined, undefined)
        }
        onBlur={e => {
            props.onBlur && props.onBlur!(e.currentTarget.value)
        }}
    />
}

export const ExpiryPick: React.FC<ControlProps2> = props => {
    //var ctx = useContext(MainContext)
    //var dt = props.Value ? new Date(props.Value) : null;
    //const minyears = new Date().getFullYear() - 10;
    //const maxyears = new Date().getFullYear() + 10;
    //const months = [1];
    //const years = [minyears - 1];
    //for (var m = 2; m <= 12; ++m) months.push(m);
    //for (var y = minyears; y <= maxyears; ++y)  years.push(y);
    //if (dt?.getFullYear()! < years[0]) {
    //    years.push(dt?.getFullYear()!);
    //    years.sort();
    //}

    //function setDate(dt: Date) {
    //    var newdt = normalDate2(dt)
    //    //props.onChange!(strDate);
    //    props.onChange!(newdt);
    //}


    return props.Editable ? <BasePick {...props} onClean={e => {
        const match = e.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/);
        if (match) {
            const month = +match[1] - 1;
            const year = '20' + match[2];
            return new Date(+year, month, 1);
        }
        return undefined
    }} /> : <DisplayBox {...props} />
}

export const HtmlPick: FC<ControlProps2> = props => {
    const [count, setCount] = useState("");
    var ctx = useContext(MainContext)
    function setupHandler(editr: TinyMCEEditor) {
        editr.ui.registry.addButton('ClearMargin', {
            text: ctx.localized("ClearMargin"),
            onAction: function () {
                const body = editr.getBody();
                const pEls = body.querySelectorAll('p');
                pEls.forEach(p => {
                    if (p.style.marginBlock !== '0px') {
                        p.style.marginBlock = '0';
                        p.style.marginBottom = '0';
                        p.setAttribute('data-mce-style', 'margin-block: 0px;margin-bottom: 0px')
                    } else {
                        p.style.marginBlock = '16px';
                        p.style.marginBottom = '16px';
                        p.setAttribute('data-mce-style', 'margin-block: 16px;margin-bottom: 16px')
                    }
                });
                editr.save();
            }
        });
        editr.ui.registry.addMenuButton('fields', {
            text: ctx.localized("DataFields"),
            fetch: function (callback: any) {

                var items = props.Picklist ? props.Picklist.map(x => {
                    return {
                        type: 'menuitem',
                        text: x.Value,
                        onAction: function () {
                            editr.insertContent("{" + x.Key + "}");
                        }
                    };
                }) : [];


                callback(items);
            }
        });
    }

    return props.Editable ?
        <Editor
            init={{
                menubar: 'edit view format tools',
                plugins: ['directionality', 'pagebreak'],
                toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ltr rtl | pagebreak | fields | ClearMargin ',
                setup: setupHandler,
                pagebreak_separator: '<div style="page-break-before: always"></div>',
                //content_style: `body p{color: red}`
            }}
            apiKey="lxi00e9pxi95iuxlk5byy1l5jaq7a6jxx3a6fib0miqtqlkd"
            value={props.Value as string || ''}
            onEditorChange={e => props.onChange!(e)}
            plugins={'code'}
        /> : <span dangerouslySetInnerHTML={{ __html: props.Value }} />
}

export const FormatPick: FC<ControlProps2> = props => {
    return props.Editable ? <div style={{ display: "flex" }}>
        <BasePick  {...props} />
        <select style={{ ...props.style, width: "100%" }} onChange={e => props.onChange!(e.currentTarget.value)}>{props.Picklist?.map(x => <option value={x.Key} key={x.Key}>{x.Key + '  - -  ' + x.Value} </option>)}</select>
    </div> : <DisplayBox {...props} />
}

export const PathPick: FC<ControlProps2> = props => {
    const [sign, setSign] = useState(props.Value);
    const [mouseDown, setMouseDown] = useState(false);

    function isTouchEvent(e: MouseEvent) { return e.type.match(/^touch/); }

    function getCoords(e: MouseEvent) {
        if (isTouchEvent(e)) {
            var t = e as unknown as TouchEvent
            return t.targetTouches[0].clientX + ',' + t.targetTouches[0].clientY;
        }
        var parentOffset = e.currentTarget.getBoundingClientRect();
        return (e.pageX - parentOffset.left) + ',' + (e.pageY - parentOffset.top);
    }

    function handleMouseDown(e: MouseEvent) {
        setMouseDown(true);
        var coords = getCoords(e);
        var newsign = (sign || "") + 'M' + coords + ' ';
        setSign(newsign);
    }

    function handleMouseMove(e: MouseEvent) {
        if (mouseDown) {
            var coords = getCoords(e);
            var newsign = (sign || "") + 'L' + coords + ' ';
            setSign(newsign);
        }
    }

    function handleMouseUp() {
        if (mouseDown) {
            setMouseDown(false);
            props.onChange!(sign);
        }
    }

    function handleClear() {
        setSign(null);
        props.onChange!(null);
    }

    return props.Editable ? < div style={{ display: "flex" }}>
        <svg style={{ color: "transparent", height: "50px", width: "300px" }} fill="White" >
            <rect style={{ color: "transparent", width: "100%", height: "50px" }} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} onMouseLeave={handleMouseUp} />
            <path stroke="navy" strokeWidth="2" fill="none" pointerEvents="none" d={sign} />
        </svg>
        <VecIcon name="Clear" onClick={handleClear} />
    </div>
        : <svg style={{ color: "transparent", height: "50px", width: "300px" }}  >
            <path stroke="navy" strokeWidth="2" fill="none" pointerEvents="none" d={sign} />
        </svg>
}

//export const AddressPick: React.FC<ControlProps2> = props => {
//    const ctx = useContext(MainContext)
//    const [results, setResults] = useState<KeyValuePair<string, string>[]>([]);
//    const handleSearch = async (text: string) => {
//        const arr = await MachshevetClient.Global.FindPlaces(text);
//        setResults(arr!);
//    }
//    return <><AutoComplete style={{ width: 200 }} onSearch={handleSearch} onSelect={(e: any) => {
//        props.onChange!(e)
//    }}>
//        {results.map(x => <AutoComplete.Option key={x.Key} value={x.Key}>{x.Value}</AutoComplete.Option>)}
//    </AutoComplete>
//        <a>{ctx.localized('GoogleMaps')}</a>
//    </>
//}

export const PickListControl: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    // const [options, setOptions] = useState<KeyValuePair[]>()//(props.Picklist || []);
    const [options, setOptions] = useState<{ Key: any, Value: string }[]>()//(props.Picklist || []);
    useEffect(() => {
        if (props.Picklist && !options) setOptions(props.Picklist)
    }, [props.Picklist])

    async function refresh() {
        var mdl = props.modelGetter && props.modelGetter();
        if (props.RecordType?.endsWith('Input')) {
            const res = await MachshevetClient.Global.GetInputOptions(props.mainRecordType!, props.mainCommand!, props.Name!, mdl);
            setOptions(res);

        } else {
            //this is neccessary for permissionzone>fieldname
            const res = await MachshevetClient.GetSelectOptions(props.RecordType!, mdl, props.Name!);
            setOptions(res!);
        }
    }

    var val = props.Value
    if (val === undefined) val = ""
    return <select id={"Test" + props.Name} style={{ ...props.style, width: "100%" }} placeholder={ctx.localized("Choose") + '...'} onChange={e => props.onChange!(e.currentTarget.value)}
        value={val} onMouseDown={async e => refresh()} >
        <option value="" id={"Test" + props.Name + "_"}>{ctx.localized("Choose") + '...'}</option>
        {options && options.map(x => <option id={"Test" + props.Name + "_" + x.Key} value={x.Key} key={x.Key}>{x.Value}</option>)}
    </select>
}