//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import { MyApp } from './newReact/MyApp';
//import 'antd/dist/antd.css';

Array.prototype.options = function <T>(this: Array<Record<any, any>>) {
    var ret = this.map(x => <option value={x.Key} key={x.Key} >{x.Value}</option>)
    return ret
};

class ReactRoot extends HTMLElement {
    connectedCallback() {
        this.style.display = "contents";
        const root = createRoot(this); // createRoot(container!) if you use TypeScript
        root.render(<MyApp />);
        //ReactDOM.render(<MyApp />, this);
    }
    constructor() {
        super();
    }
}
if (!customElements.get('react-root')) customElements.define('react-root', ReactRoot);