//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useEffect, useRef, useContext } from 'react';
import { Control } from './Control';
import { VecIcon, HamburgerMenu, NavLink, ContextMenu, Avatar2, useInterval, SmartButton, useEffect2 } from './misc';
import { ControlProps, FieldTypes, MachshevetClient, RecordData, SettingGroup } from './Declarations';
import { AppContext, ControlProps2, controlRecord, defaultControlProps, defaultGridProps, doCommand, MainContext, numberColor, RecordFormProps, smartState } from './globals';
import { devLog, groupBy } from './shared';

export const RecordForm: FC<RecordFormProps> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext)
    const [state, setState] = useState(props.Record);
    const [commands, setCommands] = useState<ControlProps[]>([]);
    const originalVals = useRef<Record<string, any>[]>()
    const [menuShown, setMenuShown] = useState(false);
    const [fieldChanged, setFieldChanged] = useState(0);
    const [menuPosition, setMenuPosition] = useState<[number, number] | undefined>();
    const [menuField, setMenuField] = useState<string>();
    const [menuFieldValue, setMenuFieldValue] = useState();

    const isDirty = useRef(false);
    const aborter = useRef(new AbortController());

    //useChangeLogger({ state })
    const countGridColumns = ctx.data.GridColumns

    function setIsDirty(isDir: boolean) {
        isDirty.current = isDir;
        props.onDirty && props.onDirty(isDir);
    }

    async function loadRecord() {
        const recid = props?.Record?.RecordID
        const prec = recid ? controlRecord(state!.Fields, props.Record!.RecordID!) : undefined
        const kvs = props.presetValues && Object.entries(props.presetValues).map(x => { return { Key: x[0], Value: x[1] } })
        const rec = await MachshevetClient.PageRecord(props.Record!.RecordType!, recid, prec, kvs);
        if (!originalVals.current || !originalVals.current.length) {
            var ovals = rec.Fields.map(x => {
                const r: Record<string, any> = [x.Name, x.Value]
                return r
            })
            originalVals.current = ovals
        }
        setState(rec);
    }

    async function firstLoad() {
        if (!state || !state.Fields.length) {//cant check recordid, since it ruins data that was set by changesothers
            await loadRecord();
            if (state?.RecordID) await MachshevetClient.Global.MarkRecordSeen(state.RecordType!, state.RecordID);
        }
        const cmnds = await MachshevetClient.Global.GetCommands(props.Record!.RecordType!)
        setCommands(cmnds.Commands)
    }

    useEffect2(async () => {
        await firstLoad();
        window.addEventListener('beforeunload', onBeforeUnload);
        return () => { window.removeEventListener('beforeunload', onBeforeUnload) }
    }, []);

    useEffect(() => {
        if (props.setSaveHandler) { props.setSaveHandler(() => save) }
    }, [state])


    useInterval(() => {
        if (app.docActive() && !isDirty.current && props.Record?.RecordID) {
            loadRecord();
        }
    }, 15000);


    function onBeforeUnload(e: BeforeUnloadEvent) {
        if (isDirty.current) {
            e.preventDefault();
            e.returnValue = '';
            return;
        }
        delete e['returnValue'];
    }
    async function setFieldValue(fieldName: string, newValue: any, letAbort = true, index?: number, subFieldName?: string, newrow?: RecordData) {
        if (state) {
            smartSetState(state, fieldName, newValue, newrow);
            setIsDirty(true);
            if (letAbort) aborter.current.abort();
            aborter.current = new AbortController();
            const signal = aborter.current.signal;
            const prec = controlRecord(state!.Fields, props.Record!.RecordID!)
            const data = await MachshevetClient.RecordData(props.Record!.RecordType!, prec, fieldName, index, subFieldName, signal);
            if (data) smartSetState(data, fieldName, newValue);
            setFieldChanged(prev => prev++);
        }
    }

    async function handleFieldChange(value: any, field: ControlProps, newrow?: RecordData, subPropertyName?: string, idx?: number) {
        if (state) {
            if (subPropertyName && (idx != undefined)) {
                var newrows: RecordData[] = state.Fields.find(x => x.Name === subPropertyName)!.Value;
                var nrow = newrows[idx!]
                nrow.Fields = nrow.Fields.map(x => x.Name === field.Name ? { ...x, Value: value, DisplayString: value } : x);
                newrows[idx!] = nrow
                await setFieldValue(subPropertyName!, newrows, undefined, idx, field.Name)
                setIsDirty(true);
            } else {
                var fieldName = field.Name!;
                if (newrow) {
                    var rows: RecordData[] = state.Fields.find(x => x.Name === subPropertyName)!.Value;
                    var a = rows.concat([newrow]);
                    value = a;
                }
                await setFieldValue(fieldName, value, undefined, undefined, undefined, newrow)
            }
        }
    }

    function smartSetState(data: RecordData, changeField?: string, changeValue?: any, newrow?: RecordData) {
        var newdata = smartState(data, state!, changeField, changeValue, newrow)
        setState(prev => ({ ...prev!, Fields: newdata }));
    }

    async function save() {

        var warns = state!.Fields.filter(x => x.Warning)
        if (warns.length) {
            var wrnmsg = warns.map(x => x.DisplayName + ": " + x.Warning).join("\n")
            var cnf = window.confirm(wrnmsg + "\n\n" + ctx.localized("SaveAnyway") + "?")
            if (!cnf) return
        }

        var curid = props.Record!.RecordID;
        var prec = controlRecord(state!.Fields, curid!);
        devLog('saving', prec);
        const record = await MachshevetClient.ApiSaveEdit(props.Record!.RecordType!, prec, window.location.href);
        setIsDirty(false);
        var id = record.RecordID!;
        props.onSaved && props.onSaved(id, curid);
        setState(record);
        return id;
    }
    var mappedflds = state && state.Fields.map(x => {
        var y: ControlProps2 = x
        y.recordID = state.RecordID
        y.serverRefresh = true
        return y
    })
    const groupedFields = mappedflds && groupBy(mappedflds, x => x.Group!)

    var modelGetter = React.useCallback(() => {
        var mdl = controlRecord(state!.Fields, props.Record!.RecordID!);
        return mdl
    }, [state, props.Record, fieldChanged])

    var flderr = state?.Fields.filter(x => x.ErrorText)[0]

    var haserr = !isDirty.current || flderr != undefined
    var fldcommands = commands.filter(x => x.NeedsField && !x.NeedsReport && !x.NeedsList)
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const PublicUrl = urlParams.get('PublicUrl')
    var gp = defaultGridProps()
    gp.SettingGroup = SettingGroup.EditFields
    if (state?.RecordID) gp.RecordKeys = new Set([state.RecordID.toString()])

    var topbuttons: ControlProps2[] = []
    if (state) {
        topbuttons = commands.filter(x => !x.NeedsField).sortBy(x => -x.Uses).slice(0, 6)
        if (state.QuickAdds) {
            const qads = state.QuickAdds.map(x => {
                const cp = defaultControlProps()
                cp.RecordType = state?.RecordType
                cp.Name = 'Add'
                cp.DisplayName = '+' + ctx.localized(x.Key)
                cp.commandParam = x.Key + '.' + x.Value
                return cp
            })
            topbuttons = topbuttons!.concat(qads)
        }
    }

    return <div className="div1" style={{ display: "flex", height: "100%" }}>
        {menuPosition && <ContextMenu onCommand={(c, p) => {
            doCommand(c, state?.RecordType!, 0, gp, p, loadRecord, app, undefined, menuField, undefined, menuFieldValue)
        }} items={fldcommands!} position={menuPosition} />}
        {/*overflow x hidden in next line makes entityp ick results hidden/cut off when towards bottom of page*/}
        <div className="div2" style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 10 }}>
            {state && <div className="div3" style={{ display: 'flex', gap: 7 }}>{topbuttons.map(x => <Avatar2 {...x} key={x.Name + (x.commandParam || "")} gridProps={gp} reloader={async () => {
                if (x.Name === "Delete" && !props.isPopup)
                    //document.querySelector('')
                    window.history.back()
                else
                    await loadRecord()
            }} RecordType={state.RecordType} param={x.commandParam} style={{ flexGrow: 1, borderWidth: x.commandParam ? 3 : "" }} />)}</div>}

            <div id="radiv4" style={{ overflowY: 'auto', flexGrow: 1 }}>
                {groupedFields && Object.keys(groupedFields!).map(grp => {
                    const allflds = groupedFields![grp];
                    const cmnds = allflds?.filter(x => x.IsCommand)
                    const files = allflds?.filter(x => x.Name == "File" || x.FieldType == FieldTypes.Pdf)
                    const editTables = allflds?.filter(x => x.SubTableName)
                    const reports = allflds?.filter(x => x.SubReportID && !x.ForeignTypeName);
                    const flds = allflds?.filter(x => !x.IsCommand && x.Name != "File" && x.FieldType != FieldTypes.Pdf && !x.SubReportID && !x.SubTableName)

                    function showControl(x: ControlProps2) {
                        const stl: React.CSSProperties = { backgroundColor: numberColor(x.BackColor), color: numberColor(x.ForeColor), fontSize: x.FontSize, flexGrow: 1, opacity: x.Useful ? 1 : 0.2 }
                        if ((x.MaxLength && x.MaxLength > 300) || x.FieldType === FieldTypes.Html) stl.gridColumn = "span " + countGridColumns
                        if (!x.Visible) stl.display = "none"
                        if (x.NoChange && x.Value && state && state.RecordID! > 0) x.Locked = true
                        let waschanged = false
                        if (x.CausesUnconfirm && originalVals.current) {
                            const origval = originalVals.current.find(y => y[0] === x.Name)
                            if (origval && origval[1] !== x.Value) waschanged = true
                        }

                        const cp2 = x
                        cp2.changeProp = (f, v, la) => setFieldValue(f, v, la)
                        cp2.modelGetter = modelGetter
                        cp2.onChange = (v, f, nr, i) => handleFieldChange(v, f || x, nr, x.Name, i)
                        cp2.reloader = loadRecord

                        return <div className="dragEl" key={x.Name} style={stl} >
                            <span onContextMenu={e => {
                                e.preventDefault()
                                setMenuPosition([e.clientX, e.clientY]);
                                setMenuField(x.Name)
                                setMenuFieldValue(x.Value)
                                document.addEventListener('click', () => setMenuPosition(undefined));
                            }} style={{ padding: 3, fontSize: 12, cursor: 'default' }} title={x.Tooltip}>{x.DisplayName}</span>
                            <span style={{ color: "red" }}>{x.ErrorText}{x.ErrorRecordID ? <NavLink controller={state?.RecordType!} recordID={x.ErrorRecordID} >{x.ErrorRecordID}</NavLink> : <></>}</span>
                            {!x.IsCommand && <span style={{ color: "orange" }}>{x.Warning}</span>}
                            <div><Control field={cp2} /></div>
                            {waschanged && <div style={{ color: "orangered" }}>{ctx.localized("CausesUnconfirm")}</div>}
                        </div>
                    }
                    return <fieldset key={grp}>

                        <legend>{grp || ctx.localized('General')}</legend>
                        <div style={{ display: 'flex', gap: 5 }}>
                            {cmnds!.sortBy(x => x.Position).map((x) => { return showControl(x) })}
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(" + countGridColumns + ",1fr)", gridGap: 15, padding: 10, position: 'relative' }}>
                            {flds!.sortBy(x => x.Position).map((x) => { return showControl(x) })}
                        </div>
                        <div style={{ padding: 10, display: "flex", flexWrap: "wrap", gap: "15px" }}>
                            {editTables!.sortBy(x => x.Position).map((x) => { return showControl(x) })}
                        </div>
                        <div style={{ padding: 10 }}>
                            {files!.sortBy(x => x.Position).map((x) => { return showControl(x) })}
                        </div>
                        <div style={{ padding: 10 }}>
                            {reports!.sortBy(x => x.Position).map((x) => { return showControl(x) })}
                        </div>
                    </fieldset>
                })}
            </div>

            {!props.setSaveHandler && <div style={{ textAlign: "center" }}>
                <SmartButton testName="Save" disabled={haserr} onClick={save}>{ctx.localized('Save')}</SmartButton>
            </div>
            }
            {
                PublicUrl && <div style={{ textAlign: "center" }}><a
                    style={{ fontSize: '1.3em' }}
                    href={PublicUrl}>{ctx.localized("Back")}</a></div>
            }
        </div>



        <div style={{ padding: 7 }}>
            <VecIcon name={menuShown ? "HideMenu" : "ShowMenu"} onClick={async () => {
                setMenuShown(!menuShown)
            }} />
            {menuShown && <HamburgerMenu onCommand={async (c, p) => {
                var gp = defaultGridProps(props.Record!.RecordID)
                gp.SettingGroup = SettingGroup.EditFields
                await doCommand(c, props.Record?.RecordType!, 0, gp, p, () => {
                    if (c.Name === 'Delete') {
                        props.onSaved && props.onSaved(props.Record!.RecordID!);
                        var closed = app.closeRecord() //if deleted on popup
                        if (!closed) window.history.back() //if deleted in edit page
                    } else {
                        loadRecord();
                    }
                }, app);
            }} items={commands.filter(x => !x.NeedsField)} />}

        </div>

    </div >

}
