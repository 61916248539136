//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, useEffect, useRef, useState } from "react";
import { ControlProps, MachshevetClient, RecordData, SettingGroup } from "./Declarations";
import { AppContext, ControlProps2, controlRecord, defaultGridProps, doCommand, MainContext, prioritizeCommands } from "./globals";
import { Control } from "./Control";
import { ContextMenu, HamburgerMenu, NavLink, VecIcon } from "./misc";

export const EditGrid: React.FC<{ recordType: string }> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext)
    const [state, setState] = useState<RecordData[]>([]);
    const [sample, setSample] = useState<RecordData>();
    const [commands, setCommands] = useState<ControlProps[]>([]);
    const [menuPosition, setMenuPosition] = useState<[number, number] | undefined>();
    const [hamburgerShown, setHamburgerShown] = useState(false);
    const aborter = useRef(new AbortController());

    var gp = defaultGridProps()
    gp.SettingGroup = SettingGroup.EditColumns

    async function getSample() {
        const stt = await MachshevetClient.InsertGrid(props.recordType);
        setSample(stt)
        const cmnds = await MachshevetClient.Global.GetCommands(props.recordType);
        setCommands(cmnds.Commands)
    }
    useEffect(() => {
        getSample()
    }, []);

    var cmds = sample && prioritizeCommands(ctx, commands)

    function docmd(c: ControlProps, p: any) {
        doCommand(c, props.recordType, 0, gp, p, undefined, app)
    }

    var relevrecs = state.filter(x => !x.IsDeleted).sortBy(x => x.Index)
    return <div style={{ display: 'flex' }}>
        {menuPosition && cmds && <ContextMenu onCommand={(c, p) => docmd(c, p)} items={cmds} position={menuPosition} />}
        <div style={{ flexGrow: 1 }}>
            <table>
                <thead style={{ fontSize: 12 }} >
                    <th />{sample && sample.Fields.filter(x => x.Visible).map(x => <th>{x.DisplayName}</th>)}<th />
                </thead>
                <tbody>
                    {relevrecs.map(x => <tr key={x.Index} onContextMenu={e => {
                        e.preventDefault()
                        setMenuPosition([e.clientX, e.clientY]);
                        document.addEventListener('click', () => setMenuPosition(undefined));
                    }}><td>{x.Index}</td>{x.Fields.filter(x => x.Visible).map(y => {
                        var cp: ControlProps2 = y
                        //cp.recordType = x.RecordType
                        cp.recordID = x.RecordID
                        cp.serverRefresh = true
                        return <td>{<Control field={{
                            ...cp, modelGetter: () => {
                                var ret = controlRecord(x.Fields, x.RecordID!);
                                return ret;
                            }, onChange: async (v, f) => {

                                var newrow = x.Fields.map(z => z.Name === y.Name ? { ...z, Value: v, DisplayString: v } : z)
                                setState(prev => prev!.map(rd => rd.Index === x.Index ? { ...rd, Fields: newrow } : rd))


                                aborter.current.abort();
                                aborter.current = new AbortController();
                                const signal = aborter.current.signal;
                                var prec = controlRecord(newrow, 0)
                                var data = await MachshevetClient.RecordData(x.RecordType!, prec, y.Name, undefined, undefined, signal);
                                setState(prev => prev!.map(rd => {
                                    if (rd.Index === x.Index) {
                                        rd.Fields = data.Fields// newdata
                                        return rd
                                    }
                                    else
                                        return rd
                                    //return rd.Index === x.Index ? { ...rd, Fields: data.Fields } : rd
                                }))
                            }
                        }} />}</td>
                    })}
                        <td>
                            {x.Fields.filter(y => y.ErrorText).map(y => <span style={{ color: "red" }}>{y.ErrorRecordID ? <NavLink controller={x.RecordType} recordID={y.ErrorRecordID} >{y.ErrorText}</NavLink> : <span>{y.DisplayName + " " + y.ErrorText}</span>}</span>)}
                            <VecIcon name="Delete" width={20} onClick={() => {
                                setState(prev => prev!.map(y => {
                                    var rw = y
                                    if (rw.Index === x.Index) {
                                        rw.IsDeleted = true
                                    }
                                    return rw//x.Index === y.Index ? { ...x, IsDeleted: true } : x
                                }));

                            }} /></td>
                    </tr>
                    )}
                </tbody>
                <tfoot>
                    <td colSpan={2} >
                        <VecIcon name='Add' width={20} onClick={() => {
                            var newrow = { ...sample! }
                            var idxs = state.map(x => x.Index)
                            var newidx = idxs.length ? Math.max(...idxs) + 1 : 0
                            newrow.Index = newidx// idxs.length ? newidx + 1 : 0
                            var newstt = state.concat(newrow)
                            setState(newstt)
                        }} />
                    </td>
                </tfoot>
            </table>
            <button onClick={async () => {
                await MachshevetClient.Insert(props.recordType, state.filter(x => !x.IsDeleted).map(x => controlRecord(x.Fields, 0)))
                setState([])

            }}>{ctx.localized("Save")}</button></div>
        <VecIcon name="Menu" onClick={() => setHamburgerShown(!hamburgerShown)} />
        {hamburgerShown && <HamburgerMenu onCommand={(c, p) => docmd(c, p)} items={commands} />}
    </div>
}
