//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, FC, useEffect, useRef, useContext, HTMLProps, ReactElement, ReactNode, useLayoutEffect, EffectCallback } from 'react';
import { ColumnChooser } from './chooseColumns';
import { RecordForm } from './RecordAdapter';
import { Control } from './Control';
import { Link } from 'react-router-dom';
import { HtmlPick } from './pickers';
import { CommandMessage, CommandResult, DashboardData, DashboardTile, FieldTypes, GridCommands, GridProps, MachshevetClient, PortalData, RecordData, SettingGroup, ViewTable } from "./Declarations";
import { AppContext, ContextMenuProps, ControlProps2, controlRecord, defaultControlProps, defaultGridProps, defaultRecordData, doCommand, downloadFile, handleCommandResult, useMaxWidth, KeyValuePair, MenuProps, numberColor, prioritizeCommands, ReadText, reloadPage, VecIconProps, MenuItemViewProps, getUrl, redirect, TabType, MainContext, getActionUrl, CommandInputProps, parseDate } from "./globals";

//export const useChangeLogger = (inputs: any) => {
//    if (false) {
//        //const oldInputsRef = useRef(inputs);
//        //const inputValuesArray = Object.values(inputs);
//        //useMemo(() => {
//        //    const oldInputs = oldInputsRef.current;
//        //    compareInputs(oldInputs, inputs);
//        //    oldInputsRef.current = inputs;
//        //}, inputValuesArray); // eslint-disable-line react-hooks/exhaustive-deps
//    }
//}

export function useInterval(callback: () => void, delay: number) {
    const savedCallback = useRef(() => { });

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay === null) return undefined;
        const tick = () => savedCallback.current();
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}
export function useEffect2(effect: () => void, deps?: React.DependencyList) {
    useEffect(() => {
        effect();
    }, deps);
}

export const NavLink: FC<React.HTMLProps<HTMLAnchorElement> & { reportID?: number, controller?: string, recordID?: number, action?: string, query?: string }> = props => {
    const url = getUrl(props.reportID, props.controller, props.recordID, undefined, props.action);
    return <Link id={"TestLink" + props.controller + "_" + props.action} title={props.title} to={url} onClick={props.onClick} style={props.style} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>{props.children}</Link >
}

export const Attention: FC<{ recordType: string, recordID: number, personID?: number, personName?: string, title?: string, titleLtr?: boolean }> = props => {
    const [state, setState] = useState(true)
    if (!state) return <span />
    return <div style={{ backgroundColor: 'white', padding: 2, color: 'var(--primary)', borderColor: 'gray', borderStyle: 'solid', borderWidth: 1, display: 'flex', justifyContent: "space-between" }}>
        <div style={{ padding: 10 }}>
            <NavLink controller={props.recordType} recordID={props.recordID} ><VecIcon name={props.recordType} width={14} /></NavLink>
            <NavLink style={{ fontWeight: 'bold', }} controller='Person' recordID={props.personID}>{props.personName}</NavLink>
            <div style={{ direction: props.titleLtr ? "ltr" : "inherit" }}>{props.title}</div>
        </div>
        <div ><VecIcon name="Hide" width={16} onClick={() => setState(false)} /></div>
    </div>
}

export const Preview: FC<{ recordType: string, id: number, miniMode: boolean }> = (props) => {
    const [state, setState] = useState<RecordData>();

    //useEffect(() => {
    //    const fetchDataAsync = async () => {
    //        var flds = await MachshevetClient.Global.Preview(props.recordType, props.id);
    //        setState(flds)
    //    }
    //    fetchDataAsync()
    //}, [props.id]);



    useEffect2(async () => {
        const flds = await MachshevetClient.Global.Preview(props.recordType, props.id);
        setState(flds)
    }, [props.id]);

    let flds = state?.Fields
    if (props.miniMode) flds = flds?.filter(x => x.Value && !x.SubTableName)

    return <div style={{ borderColor: "#DDD", borderStyle: "solid", borderRadius: 5 }}>
        {!props.miniMode && <div style={{ backgroundColor: "#f5f5f5", borderColor: "#DDD", padding: 15, borderWidth: 1, display: "flex" }}>
            <span style={{ fontWeight: "bold", fontSize: 14, flexGrow: 1 }}>{state?.RecordName} </span>
            <ColumnChooser Group={SettingGroup.PreviewFields} RecordType={props.recordType} Width={16} />
        </div>
        }
        <div style={{ padding: 15 }}> {flds && flds.map(x => {
            const cp2: ControlProps2 = x
            //cp2.recordType = props.recordType
            cp2.recordID = props.id
            cp2.showChanges = false
            cp2.style = { padding: "1px 5px", fontWeight: 600, display: 'table-cell' }
            cp2.showTools = false
            cp2.WordWrap = true
            return <div style={{ display: x.SubReportID || (x.MaxLength && x.MaxLength > 300) ? '' : 'table-row' }}>
                <span style={{ padding: "1px 5px", display: 'table-cell' }}>{x.DisplayName}</span>
                <Control field={{ ...cp2 }} />
            </div>
        })}</div>
        {state && !props.miniMode && <QuickAdds {...state} />}
    </div>
}

export const QuickAdds: FC<RecordData> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext)!;
    return <div style={{ display: "flex", gap: 4, padding: 5 }}>
        {props.QuickAdds.map(x => <button type="button" style={{ color: 'var(--primary)', flexGrow: 1 }} name={x.Key} title={ctx.localized('Add') + ' ' + ctx.localized(x.Key) + ' (' + ctx.localized(x.Value) + ')'} onClick={() => {
            var cp = defaultControlProps()
            cp.RecordType = props.RecordType
            cp.Name = 'Add'
            doCommand(cp, props.RecordType!, 0, defaultGridProps(props.RecordID), x.Key + '.' + x.Value, undefined, app)
        }} >
            <VecIcon name={x.Key} />
            <span style={{ color: 'var(--primary)', fontSize: 22, fontWeight: "bold" }}>+</span>
        </button>
        )}
    </div>
}

export const SvgPick: FC<ControlProps2> = props => {
    return <div>{props.Editable && <><input type="file" onChange={e => {
        const trg = e.currentTarget;
        if (trg.files && trg.files[0]) {
            const file = trg.files[0];
            ReadText(file).then(x => props.onChange!(x));
        }
    }} style={{ display: 'block' }} accept="image/svg+xml" />
        <input type="text" value={props.Value as string} onChange={e => {
            var trg = e.currentTarget
            props.onChange!(trg.value)
        }} /></>}
        <span dangerouslySetInnerHTML={{ __html: props.Value! as string }} />
    </div>
}

export const EditButton: FC<{ RecordType: string, RecordID: number }> = props => {
    const ctx = useContext(MainContext)!;
    return ctx && ctx.data.UserID ?
        <NavLink {...props}>
            <VecIcon name="Edit" width={18} />
        </NavLink> : <></>
}

export const EmailDropDown: FC<ControlProps2 & { Selected: { Key: string, Value: string }[] }> = props => {
    const [options, setOptions] = useState<KeyValuePair<string, string>[]>([]);
    const [selected, setSelected] = useState<{ Key: string, Value: string }[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [opened, setOpened] = useState(false);


    useEffect(() => {
        if (props.Selected) {
            props.Selected.map(x => {
                setSelected(prev => [...prev, x]);
            })
        }
    }, [])

    useEffect(() => {
        props.onChange && props.onChange(selected);
    }, [selected])

    function addEmail(text: string) {
        const emailPerson = text.split(',');
        const newarr = selected.concat({ Key: emailPerson[0], Value: emailPerson.length > 1 ? emailPerson[1] : "" });
        setSelected(newarr);
    }
    function removeEmail(address: string) {
        setSelected(prev => [...prev.filter(x => x.Key != address)]);
    }

    return <div style={{ display: "flex", gap: 2, flexWrap: 'wrap' }}>
        {selected.map((email, ind) =>
            <div style={{
                display: "flex", background: "#e8e6e6",
                border: "2px solid gray", borderRadius: "5px",
                padding: "0 3px", gap: "10px"
            }} key={ind}>
                <i style={{ cursor: "pointer" }} onClick={x => removeEmail(email.Key)}>x</i>
                <div style={{ display: "grid", lineHeight: "100%", whiteSpace: "nowrap", fontSize: "0.8em" }}>
                    <div style={{ fontWeight: "bold" }}>{email.Value}</div>
                    <div>{email.Key}</div>
                </div>
            </div>)}
        <div onMouseLeave={() => setOpened(false)}>
            <input value={inputValue} onChange={async e => {
                const trm = e.currentTarget.value
                setOpened(true)
                setInputValue(trm);
                const res = await MachshevetClient.Global.FindEmailAddresses(trm);
                setOptions(res!);
            }} onFocus={() => setOpened(true)} />
            {opened && <div>
                {options.map(x => <div key={x.Key} style={{ display: "flex" }} onClick={e => {
                    setInputValue("")
                    addEmail(x.Key)
                }}><div style={{ fontWeight: "bold" }}>{x.Value}</div> ({x.Key})</div>)}
            </div>}
        </div>
    </div>
}

export const EditTable: FC<ControlProps2> = props => {
    const [menuPosition, setMenuPosition] = useState<[number, number] | undefined>();
    const [fieldName, setFieldName] = useState("");
    const [selectedID, setSelectedID] = useState<number>();
    //const [commands, setCommands] = useState<ControlProps[]>([]);
    const [commands, setCommands] = useState<GridCommands>();
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext);
    const rows: RecordData[] = props.Value;

    //async function loadCommands() {
    //    if (!commands) {
    //        const cmnds = await MachshevetClient.Global.GetCommands(props.RecordType!);
    //        setCommands(cmnds);
    //    }
    //}

    useEffect2(async () => {
        //loadCommands();
        if (!commands) {
            const cmnds = await MachshevetClient.Global.GetCommands(props.RecordType!);
            setCommands(cmnds);
        }
    }, []);

    return <>
        {menuPosition && commands && <ContextMenu onCommand={async (c, p) => {
            const gp = defaultGridProps(selectedID)
            gp.SettingGroup = SettingGroup.EditColumns
            await doCommand(c, props.SubTableName!, 1, gp, undefined, undefined, app, undefined, fieldName)
            setMenuPosition(undefined);
        }} items={prioritizeCommands(ctx, commands.Commands)} position={menuPosition} />}
        <table>
            <thead style={{ fontSize: 12 }} >
                {props.SubTableProps.Fields.filter(x => x.Visible).map(x => <th key={x.Name}>{x.DisplayName}</th>)}<th />
            </thead>
            <tbody>
                {rows && rows.map((x, i) => <tr key={i} style={{ position: 'relative' }} onClick={() => setMenuPosition(undefined)}>{x.Fields.filter(x => x.Visible).map(y => {
                    const cp: ControlProps2 = y
                    cp.recordID = x.RecordID
                    cp.serverRefresh = props.serverRefresh
                    cp.reloader = props.reloader
                    return <td key={y.Name} onContextMenu={e => {
                        e.preventDefault();
                        setMenuPosition([e.clientX, e.clientY]);
                        setSelectedID(x.RecordID)
                        setFieldName(y.Name!)
                    }}>{<Control field={{
                        ...cp, modelGetter: () => {
                            const ret = controlRecord(x.Fields, x.RecordID!, props.DomainID);
                            return ret;
                        }, onChange: async v => {
                            await props.onChange!(v, y, undefined, i)
                        }
                    }} />}</td>
                })}
                    <td>
                        {x.Fields.filter(y => y.ErrorText).map(y => <span style={{ color: "red" }}>{y.ErrorRecordID ? <NavLink controller={x.RecordType} recordID={y.ErrorRecordID} >{y.ErrorText}</NavLink> : <span>{y.DisplayName + " " + y.ErrorText}</span>}</span>)}
                        <VecIcon name="Delete" width={20} onClick={() => {
                            var currows = rows;
                            if (x.RecordID === 0) {
                                var v = currows.map(y => {
                                    if (y.RecordID === 0) y.IsDeleted = true
                                    return x
                                });
                                props.onChange!(v, props)
                            } else {
                                var v = currows.map(y => {
                                    if (y.RecordID === x.RecordID) x.IsDeleted = true
                                    return x
                                });
                                props.onChange!(v, props)
                            }
                        }} /></td>
                </tr>
                )}
            </tbody>
            <tfoot>
                <td colSpan={2} >
                    <VecIcon name='Add' width={20} onClick={() => {
                        var newrow = { ...props.SubTableProps }
                        props.onChange!(undefined, props, newrow)
                    }} />
                    {/*<ColumnChooser RecordType={props.SubTableName} Group={SettingGroup.EditColumns} SettingKey={props.recordType + '.' + props.Name} OnChanged={() => reloadPage()} />*/}
                </td>
            </tfoot>
        </table></>
}

export const CheckList: FC<ControlProps2> = props => {
    const keyFieldName = props.SubTableProps.Fields.find(x => x.Name?.endsWith('ID') && !x.Name?.startsWith(props.RecordType!))?.Name;
    const rows: RecordData[] = props.Value;
    var ids = rows.map(x => + x.Fields.find(y => y.Name == keyFieldName)?.Value);
    return <div>
        {props.Picklist.map((x, i) => <label> <input type="checkbox" checked={ids.indexOf(x.Key) >= 0} onChange={e => {
            var chkd = e.currentTarget.checked;
            if (chkd) {
                var newrow = { ...props.SubTableProps }
                newrow.Fields.map(y => { if (y.Name == keyFieldName) y.Value = x.Key; })
                props.onChange!(undefined, props, newrow)
            }
            else {
                rows.map(y => { if (y.Fields.find(z => z.Name == keyFieldName)?.Value == x.Key) y.IsDeleted = true; })
                props.onChange!(rows, props)
            }
        }} />{x.Value}</label>)}
    </div>
}

export const CommandPopup: FC<CommandInputProps & { onClose: () => void }> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext);
    const [state, setState] = useState(props.Fields.map(x => {
        var cp: ControlProps2 = x
        cp.mainRecordType = props.mainRecordType
        return cp
    }));


    async function setFieldValue(column: ControlProps2, newValue: any, letAbort: boolean = true, index?: number, subFieldName?: string, newrow?: RecordData) {
        if (state) {
            var newval = newValue;
            //if (isMoment(newval)) newval = normalDate(newval);
            setState(prev => prev.map(x => x.Name === column.Name ? { ...x, Value: newval } : x));
        }
    }

    return <Popup2 {...props} title={props.command.DisplayName!} footer={state.filter(x => x.IsCommand).map(x => <SmartButton key={x.Name} testName={x.Name!} onClick={async e => {
        const vals = state.filter(y => !y.IsCommand).map(y => { return { Key: y.Name!, Value: y.Value } });
        //const vals = state.filter(y => !y.IsCommand).map(y => return { Key: y.Name!, Value: y.Value } );
        const response = await MachshevetClient.Global.InputCommand(props.mainRecordType!, props.command.Name!, x.Name!, props.selectedKeys, vals, window.location.href, props.tempFields);
        const disposition = response.headers.get('Content-Disposition');
        if (disposition && disposition.startsWith('attachment')) {
            const prnt = response.headers.get('X-ForPrint');
            if (prnt) {
                let reader = new FileReader();
                let blb = await response.blob()
                reader.readAsText(blb);
                reader.onload = function () {
                    app.printHtml(reader.result!.toString());
                };
            } else {
                await downloadFile(response);
            }
        } else {
            const res: CommandResult = await response.json()
            if (res.ObjectType === props.ObjectType) {
                const flds = res.Fields.map(x => {
                    const y: ControlProps2 = x
                    y.mainRecordType = props.mainRecordType
                    return y
                })
                setState(flds)
            }
            else handleCommandResult(res, x, defaultGridProps(), app, props.reloader);
        }
        if (x.AutoClose) props.onClose()
    }} >{x.DisplayName}</SmartButton>)}
    >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
            {state.filter(x => !x.IsCommand && !x.Internal).map(column => {
                var cp2 = column
                cp2.mainCommand = props.command.Name;
                cp2.modelGetter = () => { return state };//this is probably wrong. should bring the main/parent state
                cp2.commandInputGetter = () => { return state };
                return <div style={{ flexGrow: 1 }}>
                    <div>{column.DisplayName}</div>
                    <Control field={{ ...cp2, onChange: async v => { setFieldValue(column, v); } }} />
                </div>;
            })}
        </div>
    </Popup2>
}

export const DashboardButton: FC<DashboardTile> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext);
    const [hover, setHover] = useState(false)
    const forecolor = props.ForeColor ? numberColor(props.ForeColor) : "var(--primary)";
    const backcolor = props.BackColor ? numberColor(props.BackColor) : undefined;
    const iconName = props.RecordType!;
    var countdisplay = props.Count?.toString();
    const direction = document.getElementsByTagName("body")[0].dir === "rtl" ? { right: 0 } : { left: 0 }

    if (props.Count) {
        switch (props.AggregationFieldType) {
            case FieldTypes.Span:
                countdisplay = new Date(props.Count * 1000).toISOString().substr(11, 8);
                break
            case FieldTypes.Money:
                countdisplay = ctx.data.CoinSymbol + ' ' + props.Count;
                break
        }
    }
    return <NavLink reportID={props.ReportID} controller={props.RecordType} style={{ color: forecolor, backgroundColor: backcolor, boxShadow: "0 0 5px rgba(0, 0, 0, .4)", textAlign: "center", padding: 20, position: "relative", borderRadius: 7 }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <span style={{ display: hover ? "inline-block" : "none", color: backcolor || 'white', backgroundColor: forecolor, padding: 4, position: 'absolute', top: 0, borderRadius: 7, ...direction }}
            onClickCapture={(e) => {
                e.stopPropagation();
                e.preventDefault();
                app.openRecord!(defaultRecordData(props.RecordType!));
            }}><VecIcon name="Add" width={22} /></span>
        {props.Count && <CountBadge countdisplay={countdisplay!} />}
        <VecIcon name={iconName} width={48} />
        <span style={{ display: "block", textAlign: "center" }}>{props.DisplayName}</span>
        {props.IsLicensed ? undefined : <span >🔒</span>}
    </NavLink>
}

export const Dashboard: FC = () => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext);
    const [state, setState] = useState<DashboardData>();

    async function getData() {
        const dt = await MachshevetClient.Global.GetDashboardData();
        setState(dt);
    }

    useEffect(() => {
        app.setPageTitle(ctx.localized("Dashboard"));
        getData();//this line works, but very slow
        const refreshTimer = setInterval(() => { if (app.docActive()) getData() }, 10000);
        return () => clearInterval(refreshTimer);
    }, []);

    const imgurl = app.data ? getActionUrl('Global', 'DashboardBackground') : "";
    return <div style={{ padding: 30, backgroundImage: `url(${imgurl})`, backgroundRepeat: "no-repeat", backgroundPosition: 'center', backgroundSize: 'cover' }}>
        <ColumnChooser Group={SettingGroup.Dashboard} OnChanged={getData} />
        <div style={{ display: 'grid', gridTemplateColumns: "repeat(auto-fit, minmax(6em, 12em))", gap: 10 }}  >
            {state && state.Entities.map(x => <DashboardButton {...x} key={x.Key} />)}
        </div>
        <span>{ctx.localized('Recent')}</span>
        <div style={{ display: "flex", padding: 10, gap: 5 }}>
            {state && state.Recents.map(x => {
                const forecolor = x.ForeColor ? numberColor(x.ForeColor) : "var(--primary)";
                const backcolor = x.BackColor ? numberColor(x.BackColor) : undefined;
                const iconName = x.RecordType!;
                return <NavLink key={x.RecordType + '.' + x.RecordID} controller={x.RecordType} recordID={x.RecordID} style={{ color: forecolor, backgroundColor: backcolor, boxShadow: "0 0 5px rgba(0, 0, 0, .4)", textAlign: "center", padding: 10, borderRadius: 5 }}>
                    <VecIcon name={iconName} width={24} />
                    <span style={{ display: "block", textAlign: "center" }}>{x.DisplayName}</span>
                </NavLink>
            })}
        </div>
    </div>
}

export const CountBadge: FC<{ countdisplay: string }> = (props) => {
    const direction = document.getElementsByTagName("body")[0].dir === "rtl" ? { left: '5px' } : { right: '5px' }
    const defaultStyle: React.CSSProperties = { display: "inline-block", color: "white", backgroundColor: "red", padding: 4, borderRadius: 30, position: "absolute", top: '5px', ...direction, }
    return (<span style={defaultStyle}>{props.countdisplay}</span>)
}

export const Login: FC = () => {
    const ctx = useContext(MainContext);
    const [uName, setUName] = useState<string>("");
    const [pass, setPass] = useState<string>("");
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState<string>("");
    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'gainsboro', flexGrow: 1 }}>
        <div style={{ gap: 20, backgroundColor: 'white', flexDirection: 'column', display: 'flex', borderRadius: 8, padding: 20 }}>
            <div style={{ textAlign: 'center' }}><img src={getActionUrl('Global', 'Logo')} /></div>
            <div style={{ flexDirection: 'column', display: 'flex', borderRadius: 5, backgroundColor: 'white' }}>
                <label>{ctx.localized("Email") + ":"}</label>
                <input id="TestEmail" style={{ padding: 10, borderRadius: 5 }} placeholder="Email" type="text" onChange={e => setUName(e.currentTarget.value)} />
            </div>
            <div style={{ flexDirection: 'column', display: 'flex', borderRadius: 5, backgroundColor: 'white', paddingBottom: 5 }}>
                <label>{ctx.localized("Password") + ":"}</label>
                <input id="TestPassword" style={{ padding: 10, borderRadius: 5 }} placeholder={ctx.localized("Password")} type="password" onChange={e => setPass(e.currentTarget.value)} />
            </div>
            <SmartButton testName="Login" onClick={async () => {
                const uId = await MachshevetClient.Login.Login(uName, pass);
                if (uId?.Key != undefined) {
                    ctx.setUser(uId.Key);
                    redirect();
                } else {
                    setError(uId?.Value!);
                    setShowError(true);
                }
            }} autoFocus={true} >{ctx.localized("Login")}</SmartButton>
            <SmartButton testName="GetNewPassword" onClick={async () => {
                const cnfm = window.confirm(ctx.localized("GenerateANewPassword") + "?")
                if (cnfm) {
                    await MachshevetClient.Login.GenerateTempPassword(uName)
                }
            }}>{ctx.localized("GetNewPassword")}</SmartButton>
            {showError && <div style={{ padding: 5 }}><b style={{ fontSize: 20, color: "red" }}>{error}</b></div>}
        </div>
    </div>
}

export const VecIcon: FC<VecIconProps> = props => {
    const [svg, setSvg] = useState<string>();
    useEffect(() => {
        (async () => {
            const res = await MachshevetClient.Global.VectorIcon(props.name);
            var text = await res.text();
            if (text !== '') setSvg(text);
        })();
    }, [props.name]);

    const svg2 = svg || '<svg viewBox="0 0 512 512" ><path d="M256,0C114.509,0,0,114.497,0,256c0,141.491,114.497,256,256,256c141.491,0,256-114.497,256-256 C512,114.509,397.503,0,256,0z  M256,477.867c-122.337,0-221.867-99.529-221.867-221.867S133.663,34.133,256,34.133 S477.867,133.663,477.867,256S378.337,477.867,256,477.867z" /></svg>'
    const wdt = props.width || 32
    const colr = props.color || "currentColor"
    const bcolr = props.backColor || undefined
    return <span {...props} id={"TestIcon" + props.name} dangerouslySetInnerHTML={{ __html: svg2 }} style={{ ...props.style, width: wdt, display: "inline-block", fill: colr, stroke: colr, position: props.position, backgroundColor: bcolr, minWidth: wdt }} />
}

export const Icon: FC<{ name: string, onClick?: React.MouseEventHandler, onMouseEnter?: React.MouseEventHandler, onMouseLeave?: React.MouseEventHandler }> = props => {
    const [text, setText] = useState<string>();

    useEffect(() => {
        load()
    });

    async function load() {
        const res = await MachshevetClient.Global.VectorIcon(props.name);
        const tx = await res.text();
        setText(tx);
    }

    const svg2 = text || '<svg viewBox="0 0 512 512" ><path d="M256,0C114.509,0,0,114.497,0,256c0,141.491,114.497,256,256,256c141.491,0,256-114.497,256-256 C512,114.509,397.503,0,256,0z  M256,477.867c-122.337,0-221.867-99.529-221.867-221.867S133.663,34.133,256,34.133 S477.867,133.663,477.867,256S378.337,477.867,256,477.867z" /></svg>'
    return <svg dangerouslySetInnerHTML={{ __html: svg2 }} style={{ fill: 'currentcolor', height: "1em", width: "1em" }} onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} />
}

export const Popup2: FC<{ title?: string, onClose: () => void, footer: ReactNode, children?: ReactNode }> = props => {
    return <div id="TestPopup" style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "rgba(0,0,0,.45)", zIndex: 2, overflowY: 'auto' }}>
        <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', maxWidth: "90vw", maxHeight: "90vh" }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: 16, borderBottom: "1px solid #f0f0f0" }}>
                <div style={{ padding: 10 }} >{props.title}</div>
                <span style={{ padding: 10, cursor: 'pointer' }} onClick={() => {
                    props.onClose()
                }}>X</span>
            </div>
            <div style={{ padding: 20, overflowY: 'auto', height: "100%", display: 'flex', flexDirection: 'column' }}> {props.children}</div>
            <div style={{ padding: 10, borderTop: "1px solid #f0f0f0", display: 'flex', justifyContent: 'end', gap: 4 }} >
                {props.footer}
            </div>
        </div>
    </div>
}

export const SmartButton: FC<{ onClick: React.MouseEventHandler<HTMLButtonElement>, testName?: string, disabled?: boolean, autoFocus?: boolean }> = props => {
    const [isWorking, setIsWorking] = useState(false)
    const [hover, setHover] = React.useState(false)

    const stl: React.CSSProperties = { padding: "5px 10px", color: 'var(--primary)', borderWidth: 1, borderRadius: 5, borderColor: 'var(--primary)' }
    if (isWorking || props.disabled) {
        stl.cursor = isWorking ? 'wait' : 'not-allowed'
        stl.color = "lightgray"
        stl.borderColor = "lightgray"
    }
    else {
        if (hover) stl.backgroundColor = "var(--secondary-light)"

    }
    return <button id={"Test" + props.testName} style={stl} disabled={isWorking} autoFocus={props.autoFocus} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={async e => {
        setIsWorking(true)
        if (props.onClick) {
            try {
                await props.onClick(e)
            } catch (e) {
                setIsWorking(false)
                throw e
            }
        }
        setIsWorking(false)
    }}>{props.children}</button>
}

export const EditDialog: FC<{ onSaved?: (id: number) => void, record?: RecordData, presetValues?: any, onClose: () => void }> = props => {
    const [record, setRecord] = useState(props.record);
    const [IsDirty, setIsDirty] = useState(false);
    const [saveHandler, setSaveHandler] = useState<() => number>();
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext)!;

    //const ttl = ctx.localized('Edit') + ' ' + ctx.localized(props.record?.RecordType!) + ' #' + props.record?.RecordID
    const ttl = record?.RecordName


    return <Popup2 title={ttl} onClose={() => {
        if (IsDirty) {
            const sure = window.confirm(ctx.localized('CloseWithoutSaving') + '?');
            if (sure) props.onClose()
        }
        else props.onClose()
    }}
        footer={[
            <SmartButton key="Open" onClick={e => {
                redirect(undefined, props.record?.RecordType, props.record?.RecordID || 0, ctx.data.CommandsInSameTab)
                props.onClose()
            }}>{ctx.localized('Open')}</SmartButton>,
            <SmartButton key="SaveAndOpen" onClick={async e => {
                const id = await saveHandler!();
                if (!id) { return; }
                await doCommand({ ...defaultControlProps(), Name: "Copy" }, props.record?.RecordType!, 0, defaultGridProps(), undefined, undefined, app)
                props.onClose()
            }}>{ctx.localized('SaveAndCopy')}</SmartButton>,
            <SmartButton key="SaveAndOpen" testName="SaveAndOpen" onClick={async e => {
                const id = await saveHandler!();
                if (!id) { return; }
                redirect(undefined, props.record?.RecordType, id, ctx.data.CommandsInSameTab)
                props.onClose()
            }}>{ctx.localized('SaveAndOpen')}</SmartButton>,
            <SmartButton key="Save" testName="Save" onClick={async () => {
                await saveHandler!();
                props.onClose()
            }}>{ctx.localized('Save')}</SmartButton>,
        ]}    >
        <RecordForm Record={record} setSaveHandler={setSaveHandler} onSaved={props.onSaved} onDirty={setIsDirty} presetValues={props.presetValues} />
    </Popup2>;
}

export const MessageEditor: FC<CommandMessage & { onClose: () => void }> = props => {
    const ctx = useContext(MainContext);
    const [state, setState] = useState(props);
    const [mailboxes, setMailboxes] = useState<KeyValuePair<number, string>[]>();

    useEffect(() => {
        (async () => {
            const res = await MachshevetClient.Global.Mailboxes();
            setMailboxes(res!.Value);
        })();
    }, []);

    const setEmails = async (addresses: { Key: string, Value: string }[]) => {
        setState(prev => ({ ...prev, Recipients: addresses }));
    }

    const setSelectedMailbox = (x: number) => {
        setState(prev => ({ ...prev, MailboxID: x }))
    }

    //const handleSend = async (e: any) => {
    //    await MachshevetClient.Global.SendMail(state);
    //    //props.onCancel!(e)
    //    props.onClose()
    //};

    return <Popup2 {...props} title={ctx.localized('NewMessage')} footer={[<SmartButton key="Send" onClick={async () => {
        await MachshevetClient.Global.SendMail(state);
        props.onClose()
    }}>{ctx.localized('Send')}</SmartButton>]}>
        <table style={{ width: "100%" }}>
            <tbody>
                <tr><td>{ctx.localized('From')}</td><td>
                    <select onChange={e => setSelectedMailbox(+ e.currentTarget.value)} >
                        <option />
                        {mailboxes && mailboxes.options()}
                    </select>
                </td></tr>
                <tr><td>{ctx.localized('ScreenName')}</td><td>
                    <input value={state.ScreenName} onChange={e => {
                        const vl = e.currentTarget.value;
                        setState(prev => ({ ...prev, ScreenName: vl }));
                    }} />
                </td></tr>
                <tr><td>{ctx.localized('ReplyAddress')}</td><td>
                    <input value={state.ReplyAddress} onChange={e => {
                        const vl = e.currentTarget.value;
                        setState(prev => ({ ...prev, ReplyAddress: vl }));
                    }} />
                </td></tr>
                <tr><td>{ctx.localized('Email')}</td><td>
                    <EmailDropDown {...defaultControlProps()} onChange={setEmails} Selected={state.Recipients} />
                    <input type="checkbox" checked={state.SendSplit} onChange={e => {
                        const chk = e.currentTarget.checked
                        setState(prev => ({ ...prev, SendSplit: chk }))
                    }} /><span>{ctx.localized("SendSplit")}</span>
                </td></tr>
                <tr><td>{ctx.localized('Fax')}</td><td> <input type="text" value={state.FaxNumber} style={{ width: "100%" }} onChange={x => {
                    const vl = +x.currentTarget.value;
                    setState(prev => ({ ...prev, FaxNumber: vl }));
                }} /></td></tr>
                <tr><td>{ctx.localized('Subject')}</td><td> <input type="text" value={state.Subject} style={{ width: "100%" }} onChange={x => {
                    const vl = x.currentTarget.value;
                    setState(prev => ({ ...prev, Subject: vl }));
                }} /></td></tr>
            </tbody>
        </table>
        <HtmlPick {...defaultControlProps()} Value={state.Body} onChange={async (x: any) => {
            const vl = x
            setState(prev => ({ ...prev, Body: vl as string }));
        }} />
        <div style={{ padding: 5 }}>{props.Attachments.map(x => <div>🗎 {x.Title + '.' + x.Extension}</div>)}</div>
    </Popup2>;
}

export const ContextMenu: FC<ContextMenuProps> = props => {
    const ctx = useContext(MainContext)!;
    const div = React.useRef<HTMLDivElement>(null);
    const [offset, setOffset] = React.useState<[number, number]>([0, 0]);
    const [addKey, setAddKey] = React.useState("");

    useLayoutEffect(() => {
        if (div.current && props.position) {
            const dimensions = div.current.getBoundingClientRect();
            const left = dimensions.left < 0 ? 0 - dimensions.left : 0;
            const right = dimensions.right > window.innerWidth ? dimensions.right - window.innerWidth : 0;
            const offset = dimensions.bottom;
            const bottom = offset > window.innerHeight ? offset - window.innerHeight : 0;
            setOffset([left - right, - bottom]);
        }
    }, (props.position || [undefined, undefined]));

    const stl: React.CSSProperties = props.position ? { position: 'fixed', left: props.position[0] + offset[0], top: props.position[1] + offset[1], zIndex: 1001 } : { display: 'none' }
    stl.borderRadius = 6
    stl.backgroundColor = "white"
    stl.border = "solid 1px var(--secondary)"

    const qads = props.quickAdds?.map(x => {
        const cp = defaultControlProps()
        cp.RecordType = props.items[0].RecordType
        cp.Name = 'Add'
        cp.DisplayName = '+' + ctx.localized(x.Key)
        cp.commandParam = x.Key + '.' + x.Value
        return cp
    })

    return <div ref={div} id="TestContextMenu" className="vertical-menu" style={stl}>
        <div style={{ display: 'flex', gap: 4, padding: 1, justifyContent: "space-evenly" }}>{qads && qads.map(x => <div key={x.commandParam} title={x.DisplayName} onMouseEnter={e => setAddKey(x.commandParam!)} onMouseLeave={() => setAddKey("")} style={{ padding: 3, backgroundColor: x.commandParam == addKey ? "var(--secondary)" : "" }}> <Icon name={String(x.commandParam).split(".")[0]} onClick={() => props.onCommand(x, x.commandParam)} /></div>)}</div>
        {props.items.map(x => <MenuItemView key={x.Name} menu_item={x} onCommand={props.onCommand} style={{ /*padding: 3,*/ fontSize: 12 }} />)}
    </div>
}

export const VerticalMenu: FC<MenuProps & HTMLProps<HTMLElement>> = props => {
    return <div className={props.hoverable ? "hoverable-menu frame" : ""} style={{ ...props.style, position: "absolute", backgroundColor: "white" }}>
        {props.items.map(x => <MenuItemView key={x.Name} menu_item={x} onCommand={props.onCommand} />)}
    </div>
}

export const MenuItemView: FC<MenuItemViewProps & HTMLProps<HTMLElement>> = props => {
    const ctx = useContext(MainContext)
    if (props.menu_item.items) {
        return <Avatar  {...props.menu_item} style={props.style} onClick={() => { }}>
            {ctx.data.IsRtl ? '◁' : '▷'}
            <VerticalMenu items={props.menu_item.items} hoverable onCommand={props.onCommand} style={props.style} />
        </Avatar>
    }
    return <Avatar  {...props.menu_item} style={props.style} onClick={() => !props.menu_item.Picklist && props.onCommand && props.onCommand(props.menu_item)} >
        {props.menu_item.Picklist ?
            <>
                {ctx.data.IsRtl ? '◁' : '▷'}
                <div className="hoverable-menu" style={{ backgroundColor: "white" }}>
                    {props.menu_item.Picklist.map(pick => {
                        var cp = defaultControlProps()
                        cp.DisplayName = pick.Value
                        cp.Name = props.menu_item.Name
                        return <Avatar style={props.style} {...cp} key={pick.Key} onClick={() => {
                            //props.onCommand ? props.onCommand(props.menu_item, pick.Key) : undefined
                            props.onCommand && props.onCommand(props.menu_item, pick.Key)
                        }}
                        />
                    })}
                </div>
            </> :
            undefined}
    </Avatar>
}

export const CommandButton: FC<ControlProps2> = props => {
    var cx = useContext(AppContext)
    return <button type="button" id={"Test" + props.Name} style={{ padding: 2, textAlign: "inherit", width: "100%" }}    >
        <MenuItemView menu_item={props} onCommand={async (cmd, prm) => {
            const gp = defaultGridProps(props.recordID)
            await doCommand(props, props.RecordType!, 0, gp, prm, props.reloader, cx)
        }} />
    </button>
}

export const Avatar: FC<ControlProps2 & { onClick: React.MouseEventHandler, style?: React.CSSProperties, hoverStyle?: React.CSSProperties, icon?: string, iconWidth?: number, children?: ReactNode }> = props => {
    const [hover, setHover] = React.useState(false)
    let stl = { ...props.style } || {}
    if (hover && props.hoverStyle) stl = { ...stl, ...props.hoverStyle }
    stl.whiteSpace = "nowrap"
    stl.display = "flex"
    stl.gap = 3
    stl.alignItems = 'center'
    stl.userSelect = 'none'
    if (props.IsNew) stl.backgroundColor = "fuchsia"
    return <div style={stl} id={"Test" + (props.commandParam || props.Name)} className="mch-menu-item" onClick={props.onClick} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}><VecIcon name={props.icon || props.Name!} width={props.iconWidth || 16} />
        <span>{props.DisplayName}</span>
        {props.children}
    </div>;
}

export const Avatar2: FC<ControlProps2 & { gridProps: GridProps, icon?: string, tooltip?: string, menuField?: string, menuFieldValue?: string, param?: any, totalRows?: number, children?: ReactNode }> = props => {
    const [hover, setHover] = React.useState(false)
    const [subMenu, setSubMenu] = React.useState(false)
    const [inProgress, setInProgress] = React.useState(false)
    const app = useContext(AppContext)!;
    var stl: React.CSSProperties = { ...props.style, color: 'var(--primary)', borderRadius: 5, borderStyle: 'solid', padding: 7, cursor: 'pointer', fontSize: 16, fontWeight: 600 }
    if (!stl.borderWidth) stl.borderWidth = 1
    if (hover) stl = { ...stl, backgroundColor: "var(--secondary)" }
    stl.whiteSpace = "nowrap"
    //stl.gap = 3
    stl.justifyContent = 'space-between'
    if (props.IsNew) stl.backgroundColor = "fuchsia"

    var hasrecs = props.gridProps.RecordKeys.size || props.IsStatic

    var isenabled = !inProgress && hasrecs

    if (!isenabled) {
        stl.pointerEvents = "none";
        stl.opacity = 0.3;
    }
    async function docmd(key?: any) {
        try {
            await doCommand(props, props.RecordType!, props.totalRows || 0, props.gridProps, key || props.param, props.reloader, app, undefined, props.menuField, undefined, props.menuFieldValue);
        } catch (e) {
            setInProgress(false);
            throw e
        }
        setInProgress(false);
    }

    return <div style={{ ...stl, display: 'flex', justifyContent: 'space-evenly' }}
        onClick={async () => {
            if (props.Picklist) {
                setSubMenu(!subMenu)
            } else {
                setInProgress(true)
                await docmd()
            }
            //props.Picklist ? setSubMenu(!subMenu) : docmd()
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => {
            setHover(false);
        }}>
        <div style={{ display: 'flex', gap: 3, alignItems: 'center' }}>
            <Icon name={props.icon || props.Name!} />
            <div id={"Test" + props.Name} title={props.tooltip}>{props.DisplayName}</div>
        </div>
        {subMenu && <div style={{ ...stl, position: 'absolute', backgroundColor: 'white', zIndex: 2 }} onMouseLeave={() => {
            setSubMenu(false)
        }}>{props.Picklist.map(x => <div key={x.Key} id={"Test" + x.Key} style={{ padding: 2 }} onClick={async () => {
            setInProgress(true);
            await docmd(x.Key);
            setSubMenu(false);
        }}>{x.Value}</div>)}</div>}
        {
            props.Picklist ? <span>▼</span> : <></>
        }
        {props.children}
    </div >;
}

export const HamburgerItem: FC<{ field: ControlProps2, onClick: (back: boolean) => void, onCommand: (field: ControlProps2) => void }> = props => {
    const ctx = useContext(MainContext)
    const stHover: React.CSSProperties = { backgroundColor: numberColor(ctx.data.SecondaryColor) }
    if (props.field.items)
        if (props.field.expanded && props.field.items) return <>
            <div>{ctx.localized(props.field.Name!)}</div>
            {props.field.items.map(subItem => <HamburgerItem key={subItem.Name} field={subItem} onClick={() => props.onClick(true)} onCommand={props.onCommand} />)}
        </>
        else return <Avatar {...props.field} onClick={() => props.onClick(false)} hoverStyle={stHover} >
            {ctx.data.IsRtl ? '◁' : '▷'}
        </Avatar>
    else {
        if (props.field.Picklist)
            return <Avatar {...props.field} onClick={() => props.onClick(false)} hoverStyle={stHover} >
                {ctx.data.IsRtl ? '◁' : '▷'}
            </Avatar>
        else return <Avatar {...props.field} hoverStyle={stHover} onClick={() => {
            props.onCommand(props.field);
        }} />
    }
}

export const HamburgerMenu: FC<MenuProps> = props => {
    const ctx = useContext(MainContext)
    const [state, setState] = useState(prioritizeCommands(ctx, props.items));
    const [moreActive, setMoreActive] = useState(false);
    const [item, setItem] = useState<ControlProps2>();

    return <div style={{ padding: 7, display: 'flex', flexDirection: 'column', height: "100%" }}>
        <input type="text" placeholder="⌕" style={{ borderWidth: 1, borderRadius: 5, borderColor: "lightgray", fontSize: 10, borderStyle: "solid" }} onChange={e => {
            var trm = e.currentTarget.value.toLowerCase()
            setState(props.items.filter(x => x.Name?.toLowerCase().includes(trm) || x.DisplayName?.toLowerCase().includes(trm)))
        }} />
        {(state.length > 1 && moreActive) ?
            <div onClick={() => {
                setMoreActive(!moreActive);
                setState(prioritizeCommands(ctx, props.items));
            }} >{ctx.data.IsRtl ? '▷' : '◁'}&nbsp;{ctx.localized("Back")}</div>
            : <></>}
        {item ?
            <div onClick={() => {
                setItem(undefined);
                var itms = prioritizeCommands(ctx, props.items)
                if (moreActive) setState(itms[itms.length - 1].items!);
                else setState(itms);
            }} >{ctx.data.IsRtl ? '▷' : '◁'}&nbsp;{ctx.localized("Back")}</div>
            : <></>}
        <div style={{ overflowY: 'auto' }}>
            {(() => {
                if (state.length)
                    return state.map(item => <HamburgerItem key={item.Name} field={item} onCommand={props.onCommand} onClick={(back) => {
                        if (!back) {
                            if (item.Picklist) {
                                setState([]);
                                setItem(item);
                            }
                            else {
                                setMoreActive(!moreActive);
                                setState(state[state.length - 1].items!);
                            }
                        }
                    }} />)
                else
                    return item?.Picklist.map(pick => {
                        const cp = defaultControlProps()
                        cp.DisplayName = pick.Value
                        cp.Name = item?.Name
                        cp.commandParam = pick.Key
                        return <Avatar {...cp} key={pick.Key} onClick={() => props.onCommand ? props.onCommand(item, pick.Key) : undefined} />
                    })

            })()}
        </div>
    </div>
}

export const MyTab: FC<TabType> = props => {
    //const [count, setCount] = useState(props.titleCount);

    return <div style={{ height: "100%" }}>{props.children}</div>
}

export const MyTabs: FC<{ children: ReactElement[], selectedTabChanged?: (index: number) => void }> = props => {
    //export const MyTabs: FC<{ children: TabType2[], selectedTabChanged?: (index: number) => void }> = props => {
    const [selectedTab, setSelectedTab] = useState(0);

    function selectTab(index: number) {
        setSelectedTab(index);
        props.selectedTabChanged && props.selectedTabChanged(index);
    }

    return <>
        <div style={{ display: 'flex', gap: 1 }}>
            {props.children.map((item, i) => {
                //var it = item as ReactElement
                var tt = item.props as TabType
                // var tt = item//.props as TabType
                //var tt2 = item.props// as TabType
                var bkclr = tt.style?.backgroundColor || "var(--secondary)"
                var topmrg = selectedTab == i ? 0 : 4
                var bordrwdt = (selectedTab == i ? 5 : 1) + "px 1px " + (selectedTab == i ? 0 : 1) + "px 1px"
                var ttl = tt.titleText || tt.titleElement  //|| tt.children?.title//|| "test"
                return <div key={i} id={"TestTab" + tt.reportID} onClick={() => {
                    selectTab(i);
                    //setSelectedTab(i);
                    tt.onClick && tt.onClick();
                }} style={{ flexGrow: 1, backgroundColor: bkclr, padding: 10, cursor: 'pointer', borderColor: "var(--primary)", borderRadius: "10px 10px 0px 0px", borderWidth: bordrwdt, borderStyle: "solid", marginTop: topmrg }}>{ttl}</div>
            })}
        </div>
        <div className="divcontent" style={{ padding: 10, borderColor: "var(--primary)", borderStyle: "solid", borderWidth: "0px 1px 1px 1px", height: "100%", overflowY: 'auto' }}>{props.children.map((x, i) => <div key={i} className="divcontentinternal" style={{ height: "100%", display: i == selectedTab ? "block" : "none" }}>{x}</div>)}</div>
    </>
}

export const Portal: FC = () => {
    const ctx = useContext(MainContext)
    const [state, setState] = useState<PortalData>()
    const [user, setUser] = useState("")
    const [password, setPassword] = useState("")
    const [loginMessage, setLoginMessage] = useState<string>()

    async function getData() {
        var newstt = await MachshevetClient.Global.GetPortalData();
        setState(newstt);
    }

    useEffect(() => {
        getData();
    }, []);

    async function tryLogin() {
        var lgn = await MachshevetClient.Login.Login(user, password)
        if (!lgn.Value) getData();
        else setLoginMessage(lgn.Value);
    }

    return state ? <div style={{ width: "100%" }}>
        <div style={{ backgroundColor: 'var(--primary-light)', padding: 5, borderRadius: 4, fontWeight: 'bold', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: 10 }}>
                <img src={getActionUrl('Global', 'Logo')} style={{ maxWidth: 100 }} />
                {state.CompanyName}
            </div>
            <div>
                {state.UserName}
                <input type="button" value={ctx.localized("LogOut")} onClick={async () => {
                    await MachshevetClient.Login.LogOut()
                    getData()
                }} /></div>

        </div>
        {state.UserID ? <>
            <MyTabs>
                {state.Reports.map(x => <MyTab titleText={x.LocalName!} key={x.ID} >
                    <PortalGrid reportID={x.ID} />
                </MyTab>)
                }
            </MyTabs></> : <div style={{ textAlign: "center" }}>
            <label>{ctx.localized("Email") + ":"}</label><br />
            <input type="text" style={{ backgroundColor: 'white', borderRadius: 8 }} onChange={e => setUser(e.target.value)} /><br />
            <label>{ctx.localized("Password") + ":"}</label><br /><input type="password" style={{ backgroundColor: 'white', borderRadius: 8 }} onChange={e => setPassword(e.target.value)} /><br />
            <input type="button" value="כניסה" onClick={() => tryLogin()} />
        </div>}
        <div style={{ textAlign: "center" }}>
            <span style={{ textAlign: "center", color: "red" }}>{loginMessage}</span>
        </div>
    </div> : <div />
}

export const PortalGrid: FC<{ reportID: number }> = props => {
    const [state, setState] = useState<ViewTable>()
    const isMobile = useMaxWidth(600)

    async function getData() {
        var dt = await MachshevetClient.Global.PortalList(props.reportID);
        setState(dt);
    }

    useEffect(() => {
        getData();
    }, []);

    if (!state) return <div />

    if (isMobile) {
        return <div> {state.Records.map((x, i) => <table>{x.Fields.filter(x => x.Visible).map(y => {
            var cp: ControlProps2 = y
            //cp.recordType = x.RecordType
            cp.recordID = x.RecordID
            return <tr><td>{y.DisplayName}</td><td>{<Control field={{
                ...cp, modelGetter: () => {
                    var ret = controlRecord(x.Fields, x.RecordID!);
                    return ret;
                }
            }} />}</td></tr>
        })}
        </table>
        )}</div>
    } else {
        return <table>
            <thead style={{ backgroundColor: "#daeff1", color: "#2b686e" }}>
                {state.Columns.filter(x => x.Visible).map(x => <th style={{ padding: 10 }}>{x.LocalName}</th>)}<th />
            </thead>
            <tbody>
                {state.Records.map((x, i) => <tr>{x.Fields.filter(x => x.Visible).map(y => {
                    var cp: ControlProps2 = y
                    //cp.recordType = x.RecordType
                    cp.recordID = x.RecordID
                    cp.ForeColor = 2844782
                    return <td style={{ padding: 10, backgroundColor: i % 2 === 1 ? "#edf7f8" : "" }}>{<Control field={{
                        ...cp, modelGetter: () => {
                            var ret = controlRecord(x.Fields, x.RecordID!);
                            return ret;
                        }
                    }} />}</td>
                })}
                </tr>
                )}
            </tbody>
        </table>
    }
}

//export const ReportTab: FC<TabType> = props => {
//    const ctx = useContext(MainContext)
//    const [count, setCount] = useState<number>()
//    //const [report, setReport] = useState(props.report)
//    //var rep =report// props.report
//    var rep = props.report

//    function getGridProps(reportID: number, parentRecordID?: number) {
//        var gp = defaultGridProps();
//        gp.ReportID = reportID;
//        gp.ParentRecordID = parentRecordID;
//        return gp;
//    }

//    var ttl = <div style={{ display: 'flex', justifyContent: 'space-between' }}><span style={{ fontWeight: 'bold' }}>{rep.LocalName!}</span><span>{count === undefined ? null : count}</span></div>
//    //(props.title = ttl)
//    return <MyTab style={{ backgroundColor: numberColor(rep.BackColor) }} titleElement={ttl} onClick={async () => {
//        if (rep.ID > 0) await MachshevetClient.Global.MarkReportSeen(rep.ID);
//    }} >
//        {rep.ID === -1 ?
//            <RecordForm Record={props.record} onSaved={(id, oldid) => {
//                if (oldid) message.success(ctx.localized('Saved'));
//                else redirect(undefined, props.record.RecordType, id);
//            }} />
//            :
//            <TableBody controller='Report' gridProps={getGridProps(rep.ID, props.record.RecordID)} onResultsFetched={e => setCount(e)} activeTab={props.activetab} />
//        }
//    </MyTab>
//}