//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useContext, useRef, useEffect } from "react";
import { Control } from "./Control";
import { VecIcon } from "./misc";
import { ColumnData, DataTypes, DateRanges, FieldCountData, FieldTypes, GridProps, MachshevetClient, MiniReportField, ViewTable } from "./Declarations"
import { AppContext, defaultControlProps, defaultMiniReportField, defaultRecordData, enumList, MainContext, openFieldSetting } from "./globals";

interface colProps {
    columnData: ColumnData,
    viewTable: ViewTable,
    refresher: () => void,
    stateSetter: (state: MiniReportField) => void,
    gridProps: GridProps,
    fullPage: boolean,
    controller: string,
    editModeToggler: () => void
}

export const ColumnHeader: FC<colProps> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext);
    const topstyle: React.CSSProperties = { position: "sticky", top: 0, backgroundColor: "white", padding: 4, zIndex: 1 }
    if (!props.columnData.Visible) topstyle.color = "lightgray"
    const [showButton, setShowButton] = useState<boolean>();
    const [state, setState] = useState<MiniReportField>(() => {
        let rf = props.gridProps.Fields.find(x => x.SelectName == props.columnData.SelectName);
        if (!rf) {
            rf = defaultMiniReportField(props.columnData.Name!);
            rf.SelectName = props.columnData.SelectName;
            rf.RecordType = props.columnData.RecordType;
            props.gridProps.Fields.push(rf)
        }
        return rf;
    });
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [searchOptions, setSearchOptions] = useState<FieldCountData[]>([]);
    const [mouseMove, setMouseMove] = useState(0);
    const cd = props.columnData;
    const cp = defaultControlProps();

    cp.DataType = cd.DataType;
    cp.FieldType = cd.FieldType;
    cp.ForeignTypeName = cd.ForeignTypeName;
    cp.RecordType = props.viewTable.ListType
    cp.Name = cd.Name;
    cp.Picklist = cd.Picklist;
    cp.Visible = cd.Visible

    useEffect(() => {
        let rf = props.gridProps.Fields.find(x => x.SelectName == props.columnData.SelectName);
        if (!rf) {
            rf = defaultMiniReportField(props.columnData.Name!);
            rf.SelectName = props.columnData.SelectName;
            props.gridProps.Fields.push(rf)
        }
        setState(rf);
    }, [props.gridProps.Fields]);

    function handleCheck(Key: string, toAdd: boolean) {
        const newstt = state
        let selected = cd.ForeignTypeName ? (state.IDFilter || "").split(",") : JSON.parse(state.FilterList || "[]") as string[]
        if (toAdd) {
            selected = selected.concat(Key)
        }
        else {
            const i = selected.indexOf(Key);
            selected.splice(i, 1);
        }
        if (cd.ForeignTypeName) {
            newstt.IDFilter = selected.join()
            setState(prev => ({ ...prev, IDFilter: newstt.IDFilter }));
        }
        else {
            const filter = JSON.stringify(selected)
            newstt.FilterList = filter
            setState(prev => ({ ...prev, FilterList: filter }));
        }
        props.stateSetter(newstt);
        props.refresher();
    }
    function isSelected(value: string): boolean {
        let checked = false
        let selected: string[] = JSON.parse(state.FilterList || "[]")
        if (cd.ForeignTypeName) selected = (state.IDFilter || "").split(",")// JSON.parse('[' + state.IDFilter + ']' || "[]")
        if (value != undefined) checked = selected.some(x => x === value)!;
        return checked;
    }

    const abortController = useRef(new AbortController());
    async function checkOptions(forceRefresh?: Boolean) {
        const torefresh = !searchOptions.length || forceRefresh
        if (torefresh) {
            abortController.current.abort();
            abortController.current = new AbortController();
            const data = await MachshevetClient.FieldCount(props.controller, props.gridProps, cd.Name!, state.FilterText!, abortController.current.signal);
            setSearchOptions(data)
        }
    }

    function Filter(Name: string, FieldName: 'MinFilter' | 'MinFilter' | 'MaxFilter' | 'FilterText' | 'IncludeEmpties' | 'DateRange' | 'AutoFilter' | 'ReverseFilter' | 'StartSpan' | 'EndSpan', DataType?: DataTypes, FieldType?: FieldTypes, Picklist?: { Key: any, Value: string }[]) {
        const cp2 = defaultControlProps();
        cp2.DataType = DataType || cd.DataType;
        if (DataType == DataTypes.String) cp2.DataType = DataTypes.String;
        cp2.FieldType = FieldType || cd.FieldType;
        cp2.LetTime = cd.LetTime
        cp2.Picklist = cd.Picklist
        cp2.Name = FieldName;
        cp2.Value = state[FieldName];
        cp2.DisplayString = cp2.Value;
        if (Picklist) cp2.Picklist = Picklist;
        if (['IncludeEmpties', 'ReverseFilter'].includes(FieldName)) cp2.FieldType = undefined

        return <div style={{ display: "flex", fontSize: 12, gap: 3 }}><span style={{ fontSize: 12 }}>{ctx.localized(Name)}</span>
            <Control field={{
                ...cp2, onChange: async e => {
                    const fltval = e;
                    const stt = state;
                    if (FieldName == 'IncludeEmpties') stt.IncludeEmpties = fltval;
                    else if (FieldName == 'ReverseFilter') stt.ReverseFilter = fltval;
                    else stt[FieldName] = fltval;
                    setState(stt);
                    props.stateSetter(stt);
                }
            }} /></div>
    }

    const stlname: React.CSSProperties = { color: "#296067", cursor: "default", display: "flex", justifyContent: "center", gap: 5 }
    if (state.AutoFilter || state.DateRange || state.FilterList || state.FilterText || state.IDFilter || state.MinFilter || state.MaxFilter) stlname.color = "orange";

    return <th id={"TestColumn" + props.columnData.Name} style={topstyle} onMouseEnter={() => setShowButton(true)} onMouseLeave={() => setShowButton(false)}  >

        <div style={stlname} onClick={e => {
            const colSelName = cd.SelectName!;
            const stt = state;
            if (e.ctrlKey) {
                if (state.SortOrder !== undefined) stt.SortDescending = !state.SortDescending;
                else {
                    const last = Math.max(...props.gridProps.Fields.map(y => y.SortOrder || -1));
                    stt.SortOrder = last + 1;
                }
            } else {
                if (state.SortOrder !== undefined) stt.SortDescending = !state.SortDescending;
                for (const col of props.gridProps.Fields) {
                    const reportField = col;
                    if (reportField.SelectName === colSelName) reportField.SortOrder = 1;
                    else delete reportField.SortOrder;
                }
            }
            setState(stt);
            props.stateSetter(stt)
        }}>{cd.LocalName}

            {state.SortOrder !== undefined && <span>{state.SortDescending ? "▲" : "▼"}</span>}

        </div>

        <div style={{ display: "flex" }}>
            <input type="text" id={"TestQuickFilter" + state.FieldName} placeholder="⌕" style={{ borderWidth: 1, borderRadius: 5, borderColor: "lightgray", fontSize: 10, borderStyle: "solid", flexGrow: 1 }}
                value={state.FilterText === undefined ? "" : state.FilterText}
                onChange={e => {
                    const term = e.currentTarget.value;
                    const stt = state
                    stt.FilterText = term;
                    setState(stt);
                    props.stateSetter(stt);
                }} /><VecIcon name="Filter" width={16} onClick={async() => {
                    setShowFilter(!showFilter)
                    await checkOptions()
                }} style={{ visibility: showButton ? "visible" : "hidden" }} /></div>

        {showFilter && <div id={"TestFilterBox" + state.FieldName} style={{ position: "absolute", display: "flex", flexDirection: "column", backgroundColor: "white", gap: 3, padding: 10, borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: "var(--secondary)" }} onMouseMove={() => setMouseMove(Date.now())} onMouseLeave={() => {
            if (mouseMove > Date.now() - 900) setShowFilter(false)
        }}>
            {cd.RelevantAutoFilters.length > 0 && Filter("Auto", "AutoFilter", DataTypes.Enum, undefined, cd.RelevantAutoFilters)}
            {cd.DataType === DataTypes.DateTime && <> {Filter("Range", "DateRange", DataTypes.Enum, undefined, enumList(ctx, DateRanges))} {Filter("From", "MinFilter")} {Filter("Till", "MaxFilter")}  {Filter("StartSpan", "StartSpan", DataTypes.Integer, FieldTypes.Span)}{Filter("EndSpan", "EndSpan", DataTypes.Integer, FieldTypes.Span)}</>}
            {cd.DataType === DataTypes.Integer && <>{Filter("From", "MinFilter")} {Filter("Till", "MaxFilter")} </>}
            {Filter("IncludeEmpties", "IncludeEmpties", DataTypes.Boolean)}
            {Filter("Reverse", "ReverseFilter", DataTypes.Boolean)}
            <div>
                <div>
                    <span>{ctx.localized("Search")}</span><input id="TestFilterText" style={{ padding: 3, borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: "var(--secondary)" }} onChange={async e => {
                        const term = e.currentTarget.value
                        const stt = state
                        stt.FilterText = term;
                        setState(stt);
                        props.stateSetter(stt);
                        await checkOptions(true)
                    }} />
                </div>
                <table cellPadding="0" cellSpacing="0" style={{ width: "100%", borderSpacing: 0 }}>
                    {searchOptions.map(x =>
                        <tr key={x.Key} style={{ fontStyle: x.Distance > 0 ? "italic" : "normal" }}>
                            <td style={{ lineHeight: '10px' }}>
                                <input id={x.Value} style={{ margin: 2 }} type="checkbox" checked={isSelected(x.Key!)} onChange={e => {
                                    const checked = e.currentTarget.checked;
                                    handleCheck(x.Key!, checked);
                                }} />
                            </td>
                            <td style={{ textAlign: "start", lineHeight: '10px' }}><label htmlFor={x.Value} style={{ fontWeight: "normal", fontSize: 10, lineHeight: '10px' }} >{x.Value}</label></td>
                            <td style={{ lineHeight: '10px' }}><span style={{ fontWeight: "normal", fontSize: 10, color: "gray", lineHeight: '10px' }}>{x.Count}</span></td>
                        </tr>)}
                </table>
            </div>

            <div>
                <VecIcon name="Settings" width={20} title={ctx.localized("Settings")} onClick={async() => {
                    if (cd.ReportFieldID) {
                        app.openRecord(defaultRecordData('ReportField', cd.ReportFieldID));
                    }
                    else await openFieldSetting(cp.RecordType!, cd.Name!, app);
                    props.refresher();
                    setShowFilter(false)
                }} />
                <VecIcon name="Reset" width={20} title={ctx.localized("Reset")} onClick={() => {
                    const rf = new MiniReportField();
                    rf.FieldName = cd.SelectName;
                    props.stateSetter(rf);
                    setState(rf);
                    setShowFilter(false);
                    props.refresher();
                }} />
                <VecIcon name="EditMode" width={20} title={ctx.localized("EditMode")} onClick={() => {
                    props.editModeToggler()
                    props.refresher();
                    setShowFilter(false)
                }} />
            </div>
        </div>}
    </th>
}
ColumnHeader.displayName = "ColumnHeader";