//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useRef, useEffect, useContext } from "react";
import { Icon, SmartButton, VecIcon } from "./misc";
import { ImportSetting, MachshevetClient, MiniImportColumn } from "./Declarations";
import { AppContext, defaultGridProps, defaultMiniReportField, defaultRecordData, MainContext, numberColor } from "./globals";
import { ReportsTabs } from "./report";
let clmID: number | undefined = 0;

export const ImportFile: FC<{ recordType: string }> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext)
    const [state, setState] = useState<ImportSetting>();
    const [columns, setColumns] = useState<MiniImportColumn[]>();
    const [maxRows, setMaxRows] = useState<number>();
    const [SkipRows, setSkipRows] = useState<number>();
    const [importID, setImportID] = useState<number>();

    const fileInput = useRef<HTMLInputElement>(null);

    function getRecType() { return props.recordType }

    async function getSettings() {
        const st = await MachshevetClient.EntitySetting(getRecType());
        setState(prev => ({ ...prev!, Settings: st! }));
    }

    useEffect(() => {
        getSettings();
        app.setPageTitle(ctx.localized(props.recordType) + ' [' + ctx.localized("Import") + ']');
    }, [])

    async function handleSubmit() {
        const inpfile = fileInput;
        var curfil = inpfile.current;
        var fils = curfil!.files;
        var fil = fils![0];
        const impid = await MachshevetClient.Import(getRecType(), fil, columns!, maxRows, SkipRows)
        setImportID(impid);
    }

    const inpstyle: React.CSSProperties = { borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: "var(--secondary)" }//border - color: var(--secondary); padding: 2px; width: 100 %;
    var gp = defaultGridProps()
    var fld = defaultMiniReportField("ImportID")
    fld.MinFilter = (importID || 0).toString()
    fld.MaxFilter = (importID || 0 + 1).toString()
    gp.Fields.push(fld)

    async function prepareFile() {
        var fils = fileInput.current?.files
        if (fils) {
            const imps = await MachshevetClient.ImportColumnNames(getRecType(), fils[0])
            setState(imps)
            setColumns(imps.ImportColumns)
        }
    }

    function setCol(column: MiniImportColumn, changedProp: 'FieldName' | 'IsKey' | 'Row', changedval: any) {
        if (changedProp === "IsKey") {
            column[changedProp] = changedval || false;
        } else {
            column[changedProp] = changedval;
        }
        setState(prev => ({ ...prev!, FileColumns: columns?.map(x => x.Name! == column.Name ? column : x) }));
    }
    var rd = defaultRecordData("Import", importID)

    var fillngth = fileInput.current?.files?.length
    var dsbld = fillngth === undefined || fillngth === 0
    //var enbld = !inProgress && fillngth !== undefined && fillngth > 0

    async function save() {
        const newcols = await MachshevetClient.SaveImportColumns(getRecType(), columns!)
        refreshCols(newcols)
        return newcols
    }


    function refreshCols(newcols: MiniImportColumn[]) {
        var newstt = columns
        newstt = newstt?.map(x => {
            var newcol = newcols.find(y => y.Name === x.Name)
            if (newcol) { newcol.Samples = x.Samples }
            return newcol || x
        })
        setColumns(newstt)
    }

    return <div style={{ display: "flex", flexDirection: "column", overflowY: "auto" }}>
        <div>
            <input id={"TestFileUpload"} type="file" accept=".csv,.xlsx,.xls,.dbf,.zip" ref={fileInput} onChange={prepareFile} />
            <SmartButton onClick={prepareFile}>{ctx.localized("PrepareFile")}</SmartButton>
            <SmartButton onClick={save}>{ctx.localized("Save")}</SmartButton>
            <VecIcon name="Settings" width={20} title={ctx.localized("Settings")} onClick={async () => {
                const id = await MachshevetClient.GetEntitySettingID(getRecType());
                var rd = defaultRecordData('EntitySetting', id)
                app.openRecord(rd);
            }} />

            <span> {ctx.localized("MaxRows")}</span>
            <input type="number" value={maxRows} onChange={e => setMaxRows(e.currentTarget.valueAsNumber)} style={inpstyle} />
            <span> {ctx.localized("SkipRows")}</span>
            <input type="number" value={SkipRows} onChange={e => setSkipRows(e.currentTarget.valueAsNumber)} style={inpstyle} />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 4, overflowY: "auto" }}>

            {columns && columns.map(column => {
                const inuse = column.FieldName || column.SubFieldName
                var clr = inuse ? numberColor(ctx.data.PrimaryColor) : "lightgray"
                var brdstyl: React.CSSProperties = { borderWidth: 2, borderStyle: "solid", borderColor: clr, borderRadius: 7, padding: 3 }
                return <div style={brdstyl} key={column.Name}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div>
                            <span style={{ fontWeight: "bold", color: clr }}> {column.Name} </span>
                            <input type="checkbox" checked={column.IsKey} onChange={e => setCol(column, 'IsKey', e.currentTarget.checked)} />
                            <span> {ctx.localized('IsKey')} </span>
                            <input type="number" style={{ ...inpstyle, width: 40 }} value={column.Row} onChange={e => setCol(column, 'Row', e.currentTarget.valueAsNumber)} />

                        </div>
                        <div  >
                            <VecIcon name='settings' width={16} onClick={async () => {
                                if (!column.ID && column.FieldName) {
                                    const newcols = await save();
                                    clmID = newcols.filter(c => c.FieldName === column.FieldName)[0].ID;
                                    // await new Promise(f => setTimeout(f, 2000));
                                }
                                const rd = defaultRecordData("ImportColumn", column.ID || clmID);
                                app.openRecord(rd, async () => {
                                    const newcols = await MachshevetClient.GetImportColumns(getRecType())
                                    refreshCols(newcols)
                                }, { Name: column.Name, RecordType: getRecType() });
                            }} />
                            <Icon name="Delete" onClick={async () => {
                                var cnf = window.confirm(ctx.localized("AreYouSureYouWantToDeleteTheseSettings") + "?")
                                if (cnf) {
                                    await MachshevetClient.Global.DeleteRecord('ImportColumn', column.ID)
                                    prepareFile()
                                }
                            }} />
                        </div>

                    </div>
                    <div >
                        {column.SubRecordType ? <span style={{ fontSize: 12, fontWeight: "bold" }}>{column.LocalSubRecordType}</span> : <select value={column.FieldName || ""} onChange={e => setCol(column, 'FieldName', e.currentTarget.value)}>
                            <option />
                            {state && state.EntityColumns.options()}
                        </select>}
                    </div>
                    <div>
                        {column.Samples?.map(sample => <div style={{ fontSize: 10, color: 'gray' }}>{sample}</div>)}
                    </div>
                </div>
            }
            )}
        </div>
        <div style={{ display: "flex", justifyContent: "center", padding: 8 }}>
            <SmartButton testName="Upload" onClick={handleSubmit} disabled={dsbld}>{ctx.localized("Upload")}</SmartButton>
        </div>
        <ReportsTabs record={rd} key={importID} />
    </div>
}