//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { CSSProperties, FC, useContext, useState } from "react";
import { AppContext, defaultRecordData, GetDayName, GetMonthName, getWeekNumber, isSameDay, MainContext, monthFirstDay, numberColor, range, rightCut, weekFirstDay } from "./globals";
import { NavLink } from "./misc";
import { TableBodyProps } from "./tableBody";

export const Calendar: FC<TableBodyProps> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext);
    const StartTime = 8;
    const EndTime = 19;
    var fd = props.gridProps?.CalendarFromDate || new Date()//cus json return it as a string, we should make something smarter
    var gp = props.gridProps
    var records = props.records;

    function setProps(newDate?: Date, weekView?: boolean) {
        var newstt = props.gridProps!
        if (newDate) newstt.CalendarFromDate = newDate
        if (weekView !== undefined) newstt.CalendarWeekView = weekView
        props.onPropsChanged!(newstt)
    }

    const previous = () => {
        if (gp.CalendarWeekView)
            setProps(new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() - 7));
        else
            setProps(new Date(fd.getFullYear(), fd.getMonth() - 1, fd.getDate()));
    }

    const next = () => {
        if (gp.CalendarWeekView)
            setProps(new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() + 7));
        else {
            var dt = new Date(fd.getFullYear(), fd.getMonth() + 1, fd.getDate());
            setProps(dt)
        }
    }
    var weekview = gp.CalendarWeekView

    return <div id="divcal1" style={{ width: "100%", overflowY: "auto" }}>
        <div className="calendar-navigation">
            <div className="calendar-navigation-box">
                <svg viewBox="0 0 20 20" onClick={previous} style={{ margin: '20px', cursor: 'pointer' }}>
                    <path d="M6.2 1L4.8 2.5l7.4 7.5-7.4 7.5L6.2 19l9-9z" />
                </svg>
                <div className="calendar-pickers-box">
                    <select value={weekview ? getWeekNumber(fd) : fd.getMonth()} onChange={weekview ? e => setProps(new Date(fd.getFullYear(), 1, +e.currentTarget.value * 7)) : e => setProps(new Date(fd.getFullYear(), +e.currentTarget.value, 1))}>
                        {weekview ?
                            range(0, 52).map(week => {
                                const sunday = new Date(fd.getFullYear(), 0, week * 7);
                                const saturday = new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 6);
                                return <option value={week} key={week}>
                                    {week + 1} | {sunday.getDate()}/{sunday.getMonth() + 1}-{saturday.getDate()}/{saturday.getMonth() + 1}
                                </option>
                            }) :
                            //range(0, 11).map(index => <option value={index} key={index}>{index + 1} | {GetMonthName(ctx, index)}</option>)
                            range(0, 11).map(x => {
                                var txt = (x + 1) + '|' + GetMonthName(ctx, x)
                                return <option key={x} value={x}>{txt}</option>
                            })
                        }
                    </select>
                    <select
                        style={{ width: 'initial' }}
                        onChange={e => setProps(new Date(+e.currentTarget.value, fd.getMonth(), fd.getDate()))}
                        value={fd.getFullYear()}
                    >
                        {range(fd.getFullYear() - 10, fd.getFullYear() + 10)
                            .map(index => {
                                return <option value={index} key={index}>
                                    {index}
                                </option>
                            })}
                    </select>
                </div>
                <svg viewBox="0 0 20 20" onClick={next} style={{ margin: '20px', cursor: 'pointer' }}>
                    <path d="M4.8 10l9 9 1.4-1.5L7.8 10l7.4-7.5L13.8 1z" />
                </svg>
            </div>

            <button
                //onClick={() => setDate(gs?.CalendarWeekView ? beginingOfWeek(new Date()) : new Date())}
                onClick={() => setProps(gp?.CalendarWeekView ? weekFirstDay(new Date()) : new Date())}
                className='calendar-button'>
                {ctx.localized("Today")}
            </button>

            <div className="calendar-toggle" onClick={() => {
                var wv = !props.gridProps!.CalendarWeekView
                var newdt = wv ? weekFirstDay(new Date()) : monthFirstDay(new Date())
                setProps(newdt, wv)
            }}>
                <div>{ctx.localized("Weekly")}</div>
                <div>{ctx.localized("Monthly")}</div>
            </div>
        </div>
        <table style={{ width: "100%", height: "100%" }}>
            {weekview ?
                <>
                    <thead>
                        <tr>
                            <th className="calendarCel " style={{ width: '2em' }}></th>
                            {range(0, 6).map(index => {
                                const day = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() + index);
                                return <th className={`calendarCel ${isSameDay(day, new Date()) ? 'today' : ''}`} key={index}>
                                    <div className="calendar-weekly-header">
                                        <div>{day.toDateString()}</div>
                                    </div>
                                </th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {range(StartTime, EndTime).map(hour => {
                                const hourRecs = records!.filter(x => {
                                    var fd = x.Fields.filter(y => y.Name === "FromDate")[0].Value
                                    //const hr = new Date(x.FromDate).getHours();
                                    const hr = new Date(fd).getHours();
                                    return hr >= hour && hr < (hour + 1)
                                })
                                if (hour === StartTime) {
                                    hourRecs.push(...records!.filter(rec => {
                                        var fd = rec.Fields.filter(y => y.Name === "FromDate")[0].Value
                                        return (new Date(fd).getHours() < hour)
                                    }))// record befor start time go to begining
                                }
                                if (hour === EndTime) {
                                    hourRecs.push(...records!.filter(rec => {
                                        var fd = rec.Fields.filter(y => y.Name === "FromDate")[0].Value
                                        return (new Date(fd).getHours() > hour)
                                    }))// record befor start time go to begining

                                }

                                return (<tr style={{ verticalAlign: "top" }} key={hour}>
                                    <td className='calendarCel' style={{ textAlign: 'center', fontWeight: 700 }} >{hour}</td>
                                    {range(0, 6).map(i => {
                                        const day = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() + i);
                                        const recs = hourRecs?.filter(x => {
                                            var fd = x.Fields.filter(y => y.Name === "FromDate")[0].Value
                                            return isSameDay(new Date(fd), day)
                                        })
                                        recs?.sortBy(x => x.Fields.filter(y => y.Name === "FromDate")[0].Value)
                                        return (
                                            <td className={`calendarCel ${isSameDay(day, new Date()) ? 'today' : ''}`} key={i}>
                                                {recs.map(Hrec => {
                                                    return (
                                                        <div className="calendarLnk" key={Hrec.RecordID}>
                                                            <NavLink controller={props.listType} recordID={Hrec.RecordID}>
                                                                {Hrec.Fields.map(y => y.DisplayString).join("/")}
                                                            </NavLink>
                                                        </div>)
                                                })}
                                            </td>
                                        )
                                    })
                                    }
                                </tr>)
                            })}
                        </>
                    </tbody>
                </>
                :
                <>
                    <thead>
                        <tr>
                            {range(0, 6).map(index => <th className="calendar-header">{GetDayName(ctx, index)}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {records && range(0, 5).map(week => {
                            return <tr key={week}>
                                {range(0, 6).map(i => {
                                    const firstDayOfMonth = new Date(fd.getFullYear(), fd.getMonth(), 1);
                                    const day = new Date(fd.getFullYear(), fd.getMonth(), 1 - firstDayOfMonth.getDay() + week * 7 + i);
                                    var recs = records?.filter(x => {
                                        var fd = x.Fields.filter(y => y.Name === "FromDate")[0].Value
                                        const recday = new Date(fd)
                                        const isd = isSameDay(recday, day)
                                        return isd
                                    });
                                    recs = recs?.sortBy(x => x.Fields.filter(y => y.Name === "FromDate")[0].Value)
                                    if (day.getMonth() === fd.getMonth()) {
                                        return <td className={`calendarCel ${isSameDay(day, new Date()) ? 'today' : ''}`} onDoubleClick={() => {
                                            var rd = defaultRecordData(props.listType!)
                                            app.openRecord(rd, undefined, { FromDate: rightCut(day.toISOString(), "Z") })
                                        }}>
                                            <span className="calendarSp">{day.getDate()}</span>
                                            {recs && recs.map(x => {
                                                var fd = x.Fields.filter(y => y.Name === "FromDate")[0].Value
                                                return <div className="calendarLnk" onClick={() => {
                                                    //var stt = viewTable;
                                                    //var cr: CommandRecord = { RecordType: x.MetaEntityName, IDs: [x.MetaRecordID] };
                                                    //stt.GridState.Selection = [cr];
                                                    //setViewTable(stt)
                                                }}>
                                                    <div key={x.RecordID}>
                                                        <div style={{ fontWeight: "bold" }}>{new Date(fd).toLocaleTimeString()}</div>
                                                        <NavLink controller={x.RecordType} recordID={x.RecordID}>{x.Fields.map(y => y.DisplayString).filter(x => x !== undefined && x !== null).join(" / ")}</NavLink>
                                                    </div>
                                                </div>
                                            }
                                            )}
                                        </td>
                                    } else return <td />
                                })}
                            </tr>
                        })}
                    </tbody>
                </>
            }
        </table>
    </div>

}

export const MiniCal: FC<{ value?: Date, onSelected: (date: Date) => void }> = props => {
    const ctx = useContext(MainContext)
    const [value, setValue] = useState(props.value)//selected date
    //const [state, setState] = useState(props.value instanceof Date && props.value || new Date())//navigation date
    const [state, setState] = useState(props.value || new Date())//navigation date

    function setSelected(date: Date) {
        setState(date)
        setValue(date)
        props.onSelected(date)
    }
    function setSelectedNum(date: number) {
        var dt = new Date(date)
        setSelected(dt)
    }
    function setStateNum(date: number) {
        var dt = new Date(date)
        setState(dt)
    }

    var valdatetime = value && new Date(value.getFullYear(), value.getMonth(), value.getDate()).getTime();
    var stlhead: React.CSSProperties = { backgroundColor: numberColor(ctx.data.PrimaryColor), color: "white" }
    var yer = state.getFullYear()
    if (yer < 2020) {

        var a = 11
        var b = a + 1
    }
    //var yrs: number[]
    //try {

    var yrs = range(yer - 10, yer + 10);
    //} catch (e) {
    //    var abc=e
    //}
    //const btnStl: CSSProperties = { border: 0, backgroundColor: "white", cursor: "pointer" }
    return <div style={{ borderColor: numberColor(ctx.data.PrimaryColor), borderStyle: "solid", textAlign: 'center' }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <button tabIndex={-1} onClick={() => setStateNum(state.setFullYear(yer - 1))} >{ctx.data.IsRtl ? "<<" : ">>"}</button>
            <button tabIndex={-1} onClick={() => setStateNum(state.setMonth(state.getMonth() - 1))} >{ctx.data.IsRtl ? "<" : ">"}</button>
            <select tabIndex={-1} value={state.getMonth()} onChange={e => setState(new Date(yer, +e.currentTarget.value, 1))}>
                {range(0, 11).map(x => {
                    var txt = (x + 1) + ' | ' + GetMonthName(ctx, x)
                    return <option key={x} value={x}>{txt}</option>
                })
                }
            </select>
            <select tabIndex={-1} value={yer} onChange={e => setState(new Date(+e.currentTarget.value, state.getMonth(), state.getDate()))}>
                {yrs.map(x => <option value={x} key={x}>{x}</option>)}
            </select>
            <button tabIndex={-1} onClick={() => setStateNum(state.setMonth(state.getMonth() + 1))} >{ctx.data.IsRtl ? ">" : "<"}</button>
            <button tabIndex={-1} onClick={() => setStateNum(state.setFullYear(yer + 1))} >{ctx.data.IsRtl ? ">>" : "<<"}</button>
            <button tabIndex={-1} onClick={() => { setSelected(new Date()) }}> {ctx.localized("Now")} </button>
        </div>
        <div style={{ display: "flex" }}>
            <table style={{ width: "100%" }}>
                <thead>
                    <tr style={stlhead}>
                        {range(0, 6).map(x => <th key={x} style={{ padding: 2 }}>{x + 1}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {range(0, 5).map(week => {
                        return <tr key={week}>
                            {range(0, 6).map(i => {
                                const firstDayOfMonth = new Date(yer, state.getMonth(), 1);
                                var monthday = 1 - firstDayOfMonth.getDay() + week * 7 + i
                                const day = new Date(yer, state.getMonth(), monthday);
                                var stl: CSSProperties = { fontSize: 10, padding: 3, cursor: "pointer", borderRadius: "5px" }
                                if (day.getTime() === valdatetime) stl.backgroundColor = "orange"
                                if (day.getMonth() !== state.getMonth()) stl.color = "lightgray"
                                return <td style={stl} key={i} onClick={() => {
                                    var a = day
                                    setSelected(day)
                                }}>{day.getDate()}</td>
                            })}
                        </tr>
                    })}
                </tbody>
            </table>
            <div style={{ display: "flex", gap: 3 }}>
                <table>
                    <thead>
                        <tr style={stlhead}><th>{ctx.localized("Hour")}</th><th>{ctx.localized("Minute")}</th></tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ display: "flex" }}>
                                <div>
                                    {range(0, 11).map(x => {
                                        var stl: React.CSSProperties = { fontSize: 10, padding: 1 }
                                        if (x === value?.getHours()) stl.backgroundColor = "orange"
                                        return <div id={"TestHour" + x} style={stl} onClick={() => setSelectedNum(state.setHours(x))}>{x}</div>
                                    })}
                                </div>
                                <div>
                                    {range(12, 23).map(x => {
                                        var stl: React.CSSProperties = { fontSize: 10, padding: 1 }
                                        if (x === value?.getHours()) stl.backgroundColor = "orange"
                                        return <div id={"TestHour" + x}  style={stl} onClick={() => {
                                            var newval = state.setHours(x)
                                            setSelectedNum(newval)
                                        }}>{x}</div>
                                    })}
                                </div>  </td><td>
                                {range(0, 11).map(x => {
                                    var stl: React.CSSProperties = { fontSize: 10, padding: 1 }
                                    var min = x * 5
                                    if (min === value?.getMinutes()) stl.backgroundColor = "orange"
                                    return <div style={stl} onClick={() => setSelectedNum(state.setMinutes(min))}>{min}</div>
                                })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}