//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from "react";

export function queryVals(obj: any, inclueEmpties: boolean = false): string {
    let query: string | undefined;
    for (let k in obj) {
        let value = obj[k];
        if (value || inclueEmpties) {
            if (!value) value = '';
            let argument = `${encodeURIComponent(k)}=${encodeURIComponent(value)}`
            if (query) query = query + '&' + argument;
            else query = argument;
        }
    }
    return query || '';
}

export async function fetchPlus(input: RequestInfo, init?: RequestInit) {
    let rsp = await fetch(input, init);
    if (rsp.status != 200) {
        const text = await rsp.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/xml");
        const ttl = doc.getElementsByTagName('title')[0].innerHTML;
        throw new Error(ttl);
    }
    return rsp
};

export function devLog(log: string, ...optionalParams: any[]) {
    //if (getServerData().InDebug) console.log(log, optionalParams)
      console.log(log, optionalParams)//cant check debugmode in shared.ts
      //console.log(log, optionalParams)
}



export async function fetchJson<T>(input: RequestInfo, init?: RequestInit) {
    //var rsp = await fetch(input, init);
    //if (rsp.status !== 200) {
    //    const text = await rsp.text();
    //    const parser = new DOMParser();
    //    const doc = parser.parseFromString(text, "text/xml");
    //    const ttl = doc.getElementsByTagName('title')[0].innerHTML;
    //    devLog('gonna throw reg err' + ttl, input)
    //    //alert(ttl)//this is wrong! but for now the error doesnt seem to be shown/bubbled up
    //    throw new Error(ttl);
    //}
    var rsp =await fetchPlus(input,init)
    var js = rsp.json();
    return js as Promise<T>;
};

export function groupBy<TItem, TKey extends keyof any>(
    xs: TItem[],
    key: (item: TItem) => TKey
): Partial<{ [P in TKey]: TItem[] | undefined }> {
    const res: Partial<{ [P in TKey]: TItem[] }> = {};
    return xs.reduce(function (rv, x) {
        const group = key(x);
        //HACK for some reason typescript doesn't detact the denullation, hence the "!"
        (rv[group] = rv[group] || new Array<TItem>())!.push(x);
        return rv;
    }, res);
}