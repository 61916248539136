//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC } from "react";
import { SvgPick, CommandButton, EditTable, CheckList } from '../newReact/misc';
import { EntityPick } from '../newReact/EntityPick';
import { TableBody } from "../newReact/tableBody";
import { NumberPick, PercentPick, ColorPick, SpanPick, UrlPick, PhonePick, FilePick, PdfPick, DatePick, BitPick, AudioPick, GenderPick, MoneyPick, MeasurePick, TextPick, ExpiryPick, HtmlPick, FormatPick, EmailPick, LongTextPick, ImagePick, PickListControl, PathPick } from "../newReact/pickers";
import { DataTypes, FieldTypes, GridProps } from "../newReact/Declarations";
import { ControlProps2, defaultGridProps,  numberColor } from "./globals";

export const Control: FC<{ field: ControlProps2, term?: string }> = props => {
    const fld = props.field
    const gp: GridProps = { ...defaultGridProps(), ParentRecordID: fld.recordID, Member: fld.SubReportID ? "" : fld.Name, ReportID: fld.SubReportID }
    fld.style = { ...props.field.style, borderRadius: 5, borderWidth: 1, borderStyle: "solid", borderColor: "var(--secondary)", padding: 2, backgroundColor: numberColor(fld.BackColor), fontSize: fld.FontSize }
    if (!fld.style?.color)
        fld.style.color = fld.ForeColor ? numberColor(fld.ForeColor) : "var(--primary)"
    if (!fld.style.fontWeight)
        fld.style.fontWeight = fld.FontWeight
    if (props.term && fld.DisplayString?.toLowerCase().includes(props.term))
        fld.BackColor = 16776960
    if (fld.serverRefresh) {
        fld.style.padding = 4
        fld.style.fontSize = 16
        fld.style.fontWeight = 700
    }
    if (fld.Multiline && fld.Editable && !fld.Picklist && !fld.FieldType)
        return <LongTextPick {...fld} />

    if (fld.SubTableName) {
        if (fld.SubTableProps) {
            if (fld.Picklist) return <CheckList {...fld} />
            return <EditTable {...fld} />
        }
        else {
            return <TableBody controller={fld.RecordType!} gridProps={gp} modelGetter={fld.modelGetter} activeTab={true} />
        }
    }
    else if (fld.FieldType === FieldTypes.Measure)
        return <MeasurePick {...fld} />
    else if (fld.IsCommand)
        return <CommandButton {...fld} />
    //return <Avatar2 {...fld} />//this meanwhile needs gridprops. but it would be nice to combine the 2 buttons
    else if (fld.SubReportID && !fld.ForeignTypeName)
        return <TableBody controller='Report' gridProps={gp} key={fld.recordID} showTools={fld.showTools} activeTab={true} />//key is bad. but meanwhile required for correct refresh
    else if (fld.DataType !== DataTypes.String && fld.Name === "File")
        return <FilePick {...fld} />
    else if ((fld.ForeignTypeName || fld.Name === "ID") && [DataTypes.String, DataTypes.Integer].includes(fld.DataType!) && ![FieldTypes.Money, FieldTypes.CardNumber, FieldTypes.CardExpiry, FieldTypes.Phone].includes(fld.FieldType!)) {
        if (fld.ShowAll) return <TableBody gridProps={gp} activeTab={true} controller={fld.mainRecordType!} showTools={false} saveState={false} handleSelection={(id, name) => {
            fld.onChange!(id)
        }} />
        return <EntityPick {...fld} />
    }
    else if (fld.FieldType && [FieldTypes.DateFormat, FieldTypes.TimeFormat].indexOf(fld.FieldType) >= 0)
        return <FormatPick  {...fld} />
    //else if (fld.Name === "AddressSearch")
    //    return <AddressPick {...fld} />
    //else if (fld.Picklist && fld.Editable && fld.DataType !== DataTypes.Boolean  && fld.FieldType !== FieldTypes.Html) {
    else if (fld.Picklist && fld.Editable && !fld.SpecialPicker) {
        if (fld.AutoComplete) {
            var listid = fld.Name + "DataList";
            return <> <input list={listid}
                onChange={e =>
                    fld.onChange!(e.currentTarget.value)
                }
                onBlur={e => fld.onBlur && fld.onBlur!(e.currentTarget.value)}
                value={fld.Value} style={{ ...fld.style, width: "100%", padding: 3, fontSize: 16 }} />
                <datalist id={listid}>{fld.Picklist.map(x => <option>{x.Value}</option>)}</datalist></>
        } else {
            return <PickListControl {...fld} />
        }
    }
    else if (fld.DataType === DataTypes.Bytes)
        if (fld.FieldType === FieldTypes.Pdf)
            return <PdfPick {...fld} />
        else if (fld.FieldType === FieldTypes.Audio)
            return <AudioPick {...fld} />
        //} else if (fld.FieldType === FieldTypes.Bitmap) {
        //    return <ImagePick {...fld} />
        else
            return <ImagePick {...fld} />
    else if (fld.FieldType === FieldTypes.Html)
        return <HtmlPick {...fld} />
    else if (fld.FieldType === FieldTypes.DrawPath)
        return <PathPick {...fld} />
    else if (fld.FieldType === FieldTypes.Color)
        return <ColorPick {...fld} />
    else if (fld.FieldType === FieldTypes.Svg)
        return <SvgPick  {...fld} />
    else if (fld.FieldType === FieldTypes.Phone)
        return <PhonePick {...fld} />
    else if (fld.FieldType === FieldTypes.Email)
        return <EmailPick {...fld} />
    else if (fld.FieldType === FieldTypes.CardExpiry)
        return <ExpiryPick {...fld} />
    else if (fld.FieldType === FieldTypes.Url)
        return <UrlPick {...fld} />
    else if (fld.FieldType === FieldTypes.Gender)
        return <GenderPick {...fld} />
    else if (fld.FieldType === FieldTypes.Money)
        return <MoneyPick {...fld} />
    else if (fld.FieldType === FieldTypes.Percent)
        return <PercentPick {...fld} />
    else if (fld.DataType === DataTypes.DateTime) {
        return   <DatePick {...fld} />  
    } else if (fld.DataType === DataTypes.Boolean) {
        return <BitPick {...fld} />
    } else if ((fld.DataType === DataTypes.TimeSpan && fld.FieldType !== FieldTypes.Clock) || fld.FieldType === FieldTypes.Span) {
        return <SpanPick {...fld} />
    } else if (fld.DataType === DataTypes.Integer) {
        return <NumberPick {...fld} />
    } else {
        if (Array.isArray(fld.Value)) {
            var ary: Array<any> = fld.Value
            fld.Value = ary.join(",")
        }
        return <TextPick {...fld} />
    }
}
