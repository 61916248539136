//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { CSSProperties, FC, useContext, useEffect, useRef, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { ChooserPopup } from "./chooseColumns";
import { ColumnChoice, CommandList, CommandMessage, GridProps, MachshevetClient, RecordData } from "./Declarations";
import { EditGrid } from "./EditGrid";
import { AppContext, AppContextType, CommandInputProps, defaultGridProps, defaultRecordData, MainContext, RecordFormProps } from "./globals";
import { ImportFile } from "./ImportFile";
import { CommandPopup, Dashboard, EditDialog, Login, MessageEditor, Popup2, useEffect2 } from "./misc";
import { ReportsTabs } from "./report";
import { SideNav } from "./sideNav";
import { TableBody } from "./tableBody";

export const AdminTemplate: FC = () => {
    const ctx = useContext(MainContext)
    const [records, setRecords] = useState<RecordFormProps[]>([]);
    const [recFlag, setRecFlag] = useState(0);
    const [tbCmdList, setTbCmdList] = useState<CommandList>();
    const [tbGS, setTbGS] = useState<GridProps>();
    const [showTable, setShowTable] = useState(false);
    const [pageTitle, setPageTitle] = useState('');
    const [showDialog, setShowDialog] = useState(false);
    const [cmdResult, setCmdResult] = useState<CommandInputProps>();
    //const [chooser, setChooser] = useState < { ColumnChoice & a: string}>();
    const [chooser, setChooser] = useState<{ choice: ColumnChoice, reloader?: () => void }>();
    const [messager, setMessager] = useState<CommandMessage>();
    const printframe = useRef<HTMLIFrameElement>(null);
    const [myRoutes, setMyRoutes] = useState<string[]>([]);

    const lastPing = useRef<number>(Date.now() - 100000);
    const actionTime = useRef<number>(0);

    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    const openRec = (record: RecordData, onSaved?: (id: number) => void, presetValues?: any) => {
        const recs = records;
        recs.push({ Record: record, onSaved, presetValues, isPopup: true });
        setRecords(recs);
        setRecFlag(records.length)
    }

    const closeRec = () => {
        var recs = records;
        var rec = recs.pop();
        setRecords(recs);
        setRecFlag(records.length)
        if (rec) return true
        return false
    }

    const openTable = (cmdList: CommandList, gp: GridProps) => {
        setTbCmdList(cmdList);
        setTbGS(gp);
        setShowTable(true)
    }

    function openPrint(html: string) {
        var pri = printframe.current!.contentWindow!;
        pri.document.open();
        pri.document.write(html);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    function openDialog(result: CommandInputProps) {
        setCmdResult(result);
        setShowDialog(true);
    }

    async function sendKeepLive() {
        await MachshevetClient.Login.KeepSessionLive();
    }

    document.addEventListener('mousemove', () => {
        actionTime.current = Date.now()
        if (Date.now() - lastPing.current > 100 * 1000) {
            lastPing.current = Date.now()
            sendKeepLive()
        }
    });

    function documentActive() {
        var intvl = 3 * 60 * 1000;
        var hdn = document.hidden
        var ret = !hdn && (Date.now() - actionTime.current) < intvl;
        return ret
    };

    const [myContext] = useState<AppContextType>({
        openRecord: openRec,
        closeRecord: closeRec,
        openTable: openTable,
        closeTable: () => setShowTable(false),
        openDialog: openDialog,
        setPageTitle: setPageTitle,
        docActive: documentActive,
        openChooser: (c, rld) => setChooser({ choice: c, reloader: rld }),
        openMessage: setMessager,
        printHtml: openPrint
    });

    async function fetchData() {
        if (!myRoutes.length) {
            const js = await MachshevetClient.Global.GetNavData();//really we should only fetch list of entitytypes. but we cannot put the timer in myapp, since it makes the whole page rerender again and again
            setMyRoutes(js!.AllLists);
        }
    }

    async function filltrans() {
        const uit = await MachshevetClient.Global.UITexts(ctx.data.Language!);
        ctx.translations = uit;
    }

    function localtext(str: string) {
        if (!ctx.data.UseLocalText) return str
        var dctn = ctx.translations
        if (!dctn) {
            //filltrans()
            return str
        }
        const ret = dctn[str];
        if (!dctn.hasOwnProperty(str)) { //this finds keys with empty values as well, so we don';'t try to send again to the server
            MachshevetClient.Global.Localize(str)
        }
        return ret || str
    };

    ctx.localized = localtext;

    useEffect2(async () => {
        await fetchData();
        await filltrans()
    }, [ctx.data.UserID]);

    const mainstl: CSSProperties = { display: 'flex', height: "100%", width: "100%", direction: ctx.data.IsRtl ? "rtl" : "ltr" }
    if (ctx.data.IsRemote) {
        mainstl.borderColor = "red"
        mainstl.borderWidth = 5
        mainstl.borderStyle = "solid"
    }

    return <AppContext.Provider value={myContext}>
        <div style={mainstl}>
            <SideNav />
            <div id='div7' style={{ overflowX: 'hidden', flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                {showDialog && cmdResult && <CommandPopup {...cmdResult} onClose={() => setShowDialog(false)} />}
                {records.map(rec => <EditDialog onClose={closeRec} onSaved={rec.onSaved} record={rec.Record} key={rec.Record!.RecordType} presetValues={rec.presetValues} />)}
                {chooser && <ChooserPopup columnChooser={chooser.choice} onCancel={() => setChooser(undefined)} onChanged={chooser.reloader} />}
                {showTable &&
                    <Popup2 title={ctx.localized(tbCmdList?.RecordType!)} onClose={() => setShowTable(false)} footer={<div />}>
                        <TableBody controller={tbCmdList?.RecordType!} gridProps={tbGS!} saveState={false} activeTab={true} />
                    </Popup2>}
                {messager && <MessageEditor {...messager} onClose={() => setMessager(undefined)} />}
                <iframe ref={printframe} style={{ width: 1, height: 1, position: "absolute" }} />
                <div id='div8' style={{ backgroundColor: 'var(--primary-light)', padding: 5, borderRadius: 4, fontWeight: 'bold' }}>{pageTitle}</div>
                <Routes>
                    <Route index element={<Dashboard />} />
                    <Route path='Login' element={<Login />} />
                    <Route path="Report/:ID" element={<ReportID />} />
                    {myRoutes.map(x => {
                        var rout = x;
                        var pth = rout;
                        return <Route path={pth} key={pth} element={<TableBody key={rout} controller={rout} fullPage={true} gridProps={defaultGridProps()} activeTab={true} />} />
                    })}
                    {myRoutes.map(x => {
                        var rout = x;
                        return <Route path={rout + '/Edit'} key={rout} >
                            <Route index key={rout} element={<EditRecord />} />
                            <Route path=":ID" key={rout} element={<EditRecord />} />
                        </Route>
                    })}
                    {myRoutes.map(x => {
                        var rout = x;
                        return <Route path={rout + '/Import'} key={rout} element={<ImportFile key={rout} recordType={rout} />} />
                    })}
                    {myRoutes.map(x => {
                        var rout = x;
                        return <Route path={rout + '/Insert'} key={rout} element={<EditGrid recordType={rout} />} />
                    })}
                </Routes>
            </div>
        </div>
    </AppContext.Provider>
}

const ReportID: FC = () => {
    const params = useParams();
    var rid = params.ID!
    var gp = defaultGridProps()
    var rpid = parseInt(rid);
    gp.ReportID = rpid
    MachshevetClient.Global.MarkReportSeen(rpid)
    return <TableBody key={'Report' + rid.toString()} controller='Report' gridProps={gp} fullPage={true} activeTab={true} />
}

const EditRecord: FC = () => {
    var params = useParams();
    var url = params['*']
    var routs = url!.split("/")
    var rout = routs[0];
    var rid = parseInt(params.ID!);
    var rd = defaultRecordData(rout, rid)
    return <ReportsTabs key={rout + rid.toString()} record={rd} fullPage={true} />
}