//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useEffect, FC, useContext } from 'react';
import { GlobalSearch } from './GlobalSearch';
import { Attention, MyTab, MyTabs, NavLink, Popup2, useInterval, VecIcon } from './misc';
import { Options } from './Options';
import { MachshevetClient } from './Declarations';
import { AppContext, getActionUrl, MainContext, numberColor, reloadPage } from './globals';

export const SideNav: FC = () => {
    var app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [showAlerts, setShowAlerts] = useState(true);
    const [visible, setVisible] = useState(true);
    const [isOptions, setIsOptions] = useState(false);
    const [featureRequest, setFeatureRequest] = useState(false);
    //const [flag, setFlag] = useState(true);
    let alrts = app.data?.Alerts?.filter(report => report.Count > 0).sortBy(x => x.Position);
    let isFirstLoad = true;

    const fetchData = async () => {
        var actv = app.docActive();
        if (isFirstLoad || actv) {
            isFirstLoad = false;
            const js = await MachshevetClient.Global.GetNavData();
            app.data = js
            //setData(js);
        }
    }

    useInterval(() => { fetchData(); }, 5000);
    useEffect(() => { fetchData(); }, [ctx.data.UserID]);
    //useEffect(() => {
    //    setFlag(!flag)
    //}, [ctx.data]);

    if (app.data) {
        app.data.Printables.forEach(x => {
            const byteArray = new Uint8Array(atob(x).split('').map(char => char.charCodeAt(0)));
            let file = new Blob([byteArray], { type: 'application/pdf' });
            var fileURL = URL.createObjectURL(file);
            window.open(fileURL);
        })
    }

    return <>
        {visible ?
            <>
                <aside style={{ color: 'white', display: 'flex', padding: 10, flexDirection: 'column', backgroundColor: "var(--primary)" }}>
                    <div style={{ position: 'absolute', bottom: 0, left: ctx.data.IsRtl ? 0 : "inherit", right: ctx.data.IsRtl ? "inherit" : 0, marginLeft: 10, marginBottom: 10, maxWidth: '15%', display: 'flex', flexDirection: 'column', maxHeight: '40%', zIndex: 1 }}>
                        {(app.data?.LiveCalls.length || app.data?.NewMessages.length) && <div><VecIcon name="Collapse" width={14} color={numberColor(ctx.data.PrimaryColor)} onClick={() => {
                            setShowAlerts(!showAlerts)
                        }} /></div>}
                        {showAlerts && <>
                            <div style={{ gap: 10, display: 'flex', flexDirection: 'column' }}> {
                                app.data?.LiveCalls.map(x => <Attention recordType="Call" recordID={x.ID} personID={x.PersonID} personName={x.PersonName} title={x.FromPhoneString + ' ' + x.ScreenName} titleLtr={true} />)
                            }</div>
                            <div style={{ gap: 10, display: 'flex', flexDirection: 'column' }}> {
                                app.data?.NewMessages.map(x => <Attention recordType="Message" recordID={x.ID} personID={x.SenderID} personName={x.SenderName} title={x.Subject} />)
                            }</div>
                        </>
                        }

                    </div>

                    <div style={{ display: 'flex', flexDirection: 'row-reverse' }}><VecIcon name='Cancel' onClick={() => setVisible(false)} /></div>
                    {app.data?.Domains?.length ?
                        <div className='choose-domain' style={{ backgroundColor: 'inherit' }}>
                            <label>{ctx.localized('Domain')}</label>
                            <select defaultValue={ctx.data.SelectedDomainID} style={{ backgroundColor: 'inherit' }} onChange={(t) => {
                                let did = t.target.selectedOptions.item(0)?.value;
                                MachshevetClient.Global.SetDomainID(did ? +did : undefined)
                            }}><option value='' style={{ backgroundColor: 'inherit' }}>{ctx.localized('All')}</option>
                                {app.data.Domains.map(x => <option key={x.Key} value={x.Key} style={{ backgroundColor: 'inherit' }} >{x.Value}</option>)}
                            </select>
                        </div> : undefined}
                    {app.data && <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <NavLink> <img src={getActionUrl('Global', 'Logo')} style={{ maxWidth: 100 }} /></NavLink>
                        <span style={{ opacity: app.data ? 1 : 0, fontWeight: 'bold' }}>{app.data ? ctx.data.DomainName : undefined}</span>
                    </div>}
                    <div className='account-block' style={{ fontWeight: 'bold' }}>
                        {ctx.data.UserID ?
                            <div style={{ display: 'flex', justifyContent: 'space-between', padding: 5 }}>
                                <img src={getActionUrl('Person', 'GetDoc', ctx.data.UserID, 'Column=Image')} style={{ maxWidth: 60 }} />
                                <NavLink controller='Person' recordID={ctx.data.UserID}>{ctx.data.UserName}</NavLink>
                                <VecIcon name='Logout' width={20} onClick={async () => {
                                    await MachshevetClient.Login.LogOut()
                                    reloadPage();
                                }} />
                            </div> : <div className='item-header'>
                                <VecIcon name='person' />
                                <NavLink controller='Login' >{ctx.localized('Login')}</NavLink>
                            </div>
                        }
                    </div>

                    {ctx.data.UserID && <>
                        {app.data?.SystemAlert.Key ?
                            <span style={{ backgroundColor: 'pink', color: 'red' }}>{app.data.SystemAlert.Key + ': ' + app.data.SystemAlert.Value}</span>
                            : undefined}
                        <div style={{ padding: '4px 10px' }}>
                            <GlobalSearch />
                        </div>
                        <div className='group-header' style={{ padding: 4 }}>
                            <span style={{ fontWeight: 'bold' }}>{ctx.localized('Add').toUpperCase()}</span>
                        </div>
                    </>}
                    <div style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }}>
                        {app.data?.QuickAdds.map((x, i) => <NavLink key={x.Key} title={x.Value} controller={x.Key} action='Edit' style={{ padding: 3 }} > <VecIcon name={x.Key} width={26} /></NavLink>)}
                    </div>
                    <div className='group-header'>
                        <span style={{ fontWeight: 'bold' }}>{ctx.localized('Alerts').toUpperCase()}</span>
                    </div>
                    {alrts?.map(item =>
                        <NavLink key={item.Name} reportID={item.ID} >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <VecIcon name={item.RecordType!} width={22} />
                                <span className='item-title' style={{ fontWeight: 'bold' }}>{item.LocalName}</span>
                                <span style={{ backgroundColor: 'red', padding: 5, borderRadius: 4, fontSize:10 }}>{item.Count}</span>
                            </div>
                        </NavLink>)}
                    <MyTabs>
                        <MyTab titleText={ctx.localized('Lists')} key='1' style={{ backgroundColor: 'transparent' }}>
                            {app.data?.Lists && app.data.Lists.map(x => <SideLink controller={x.RecordType} title={x.DisplayName!} key={x.RecordType} />)}
                        </MyTab>
                        <MyTab titleText={ctx.localized('History')} key='2' style={{ backgroundColor: 'transparent' }} >
                            {app.data?.Recents && app.data?.Recents.map(x => <SideLink controller={x.RecordType} title={x.DisplayName!} recordID={x.RecordID} key={x.RecordType + '.' + x.RecordID} />)}
                        </MyTab>
                        <MyTab titleText={ctx.localized('Reports')} key='3' style={{ backgroundColor: 'transparent' }}>
                            {app.data?.Populars.map(x => <SideLink reportID={x.ID} title={x.LocalName!} icon={x.RecordType} key={x.ID} />)}
                        </MyTab>
                    </MyTabs>

                    <div className='group-header' >
                        <span>{ctx.localized('Tools').toUpperCase()}</span>
                    </div>
                    {app.data?.Tools.map(x => <div key={x}><a href={getActionUrl('Global', x)} >{ctx.localized(x)} </a></div>)}

                    {ctx.data.UserID ? <>
                        <div className='indicators-block'>
                            {ctx.data.IsSandbox && <VecIcon name='Sandbox' width={24} title={ctx.localized('Sandbox')} />}
                            <VecIcon name='FeatureRequest' onClick={() => setFeatureRequest(true)} width={24} title={ctx.localized('FeatureRequest')} />
                            {ctx.data.IsSysAdmin && <VecIcon name='Options' onClick={() => setIsOptions(true)} width={24} title={ctx.localized('Options')} />}
                            <VecIcon name='ClearStorage' title={ctx.localized('ClearStorage')} width={24} onClick={() => {
                                localStorage.clear();
                                sessionStorage.clear();
                                MachshevetClient.Global.ClearBrowser();
                                window.location.reload();
                            }} />
                        </div>
                        <div>{app.data?.Progresses?.map(x => {
                            var prcnt = x.Progress * 100
                            return <NavLink key={x.ID} controller="Execution" recordID={x.ID}>  <div style={{ backgroundColor: 'white', borderColor: 'white', borderWidth: 2, borderStyle: 'solid', borderRadius: 5 }} title={x.LocalCommand}>
                                <div style={{ width: prcnt + '%', backgroundColor: 'var(--primary)', borderRadius: 5 }}>{Math.round(prcnt) + '%'}</div>
                            </div></NavLink>
                        })}</div>

                        {featureRequest && <FeatureRequest onCancel={() => setFeatureRequest(false)} />}
                    </> : <div />}
                    <NavLink controller="Portal" >{ctx.localized('Portal')}</NavLink>
                    <div className='app-name'>{app.data?.AppName}</div>
                    {app.data && <a href='https://Machshevet.com' className='copyright-block'>
                        <img src={getActionUrl('Global', 'Content/Logo.svg')} style={{ width: 25 }} />
                        <span>Machshevet.com</span>
                    </a>}
                </aside>
                {isOptions && <Options onCancel={() => setIsOptions(false)} />}
            </>
            : <div style={{ width: 'auto', backgroundColor: 'var(--primary)', color: 'white' }}>
                <VecIcon name='Add' onClick={() => setVisible(true)} />
            </div>}
    </>
}

export const SideLink: FC<{ reportID?: number, controller?: string, recordID?: number, title: string, icon?: string }> = props => {
    return <NavLink {...props}>
        <div className='item-header' style={{ padding: 3, borderBottom: "solid .1px rgb(0 0 0 / 20%)", gap: 3, display: 'flex' }}>
            <VecIcon name={props.icon || props.controller!} width={22} />
            <span style={{ fontWeight: 'bold' }} id={"TestLink" + props.controller}>{props.title}</span>
        </div>
    </NavLink>
}

export const FeatureRequest: FC<{ onCancel: () => void }> = props => {
    const app = useContext(AppContext)
    const ctx = useContext(MainContext)
    const handleSubmit = async () => {
        try {
            const resp = await fetch('http://machshevet.com/home/featureRequest', {
                method: 'POST',
                body: JSON.stringify({
                    Details: text,
                    RequestorName: ctx.data.UserName
                }),
                mode: 'no-cors'
            });
            if (!resp.ok) throw Error();
            // message.success(ctx.localized('We received your request and we will process it as soon as possible. Thanks!'), 3000);
        } catch (e) { setError(true); }
    }
    const [error, setError] = useState(false);
    const [text, setText] = useState('');
    return <Popup2 {...props} onClose={props.onCancel} footer={<div />}>
        {error ?
            <div color='error'>
                {ctx.localized('Sending failed')}:(<br />
                {ctx.localized('please send us an email instead')}:<br />
                <a href='mailto:yisroel@machshevet.com'>yisroel@machshevet.com</a>
            </div> :

            <input autoFocus value={text} onChange={e => setText(e.currentTarget.value)} />
        }
    </Popup2>
}

