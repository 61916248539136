//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext } from 'react';
import { FC, useState, useEffect, useRef } from 'react';
import { MachshevetClient, SearchResultGroup } from './Declarations';
import { MainContext, redirect } from './globals';
import { NavLink, Preview, VecIcon } from './misc';

export const GlobalSearch: FC = () => {
    const ctx = useContext(MainContext)!;
    const [results, setResults] = useState<SearchResultGroup[]>()
    const [selected, setSelected] = useState<[number, number]>();
    const [focused, setFocused] = useState(false);
    const [showResults, setShowResults] = useState(false);
    const [term, setTerm] = useState<string>();
    const [previewKey, setPreviewKey] = useState<string>();
    const abortController = useRef(new AbortController());

    async function globalKeyPressed(event: KeyboardEvent) {// } | React.KeyboardEvent<HTMLInputElement>) {
        //const key = 'code' in event ? event.code : event.key;
        const key = event.code
        if (event.ctrlKey && key == "KeyQ") {
            setFocused(pre => !pre)

            const perm = await navigator.permissions.query({ name: 'clipboard-read' as PermissionName })
            if (perm.state !== 'denied') {
                const clp = await navigator.clipboard.readText();
                setTerm(clp)
            }

        } else if (key === "Esc" || key === "Escape") {
            setShowResults(false);
            setFocused(false);
            setResults([]);
            setSelected([0, 0]);
        }
    };
    const handleInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setTerm(event.currentTarget.value)
    };

    useEffect(() => {
        document.addEventListener('keypress', globalKeyPressed);
        return () => { document.removeEventListener('keypress', globalKeyPressed); }
    }, []);
    async function getdata() {
        abortController.current.abort();
        abortController.current = new AbortController();
        const data = await MachshevetClient.Global.GlobalSearch(term!, abortController.current.signal);
        setResults(data)
    }
    useEffect(() => {
        if (term) {
            setShowResults(true);
            if (term.length >= 2) {
                getdata()
            }
        } else {
            setShowResults(false);
            setResults([]);
        }
    }, [term]);

    function inputKeyDown(event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) {
        const key = event.key;
        if (results && key === 'ArrowDown') {
            if (!selected) setSelected([0, 0]);
            else {
                //if its the last one in group:
                if (results[selected[0]].List.length - 1 === selected[1]) {
                    //if its the last group:
                    if (results.length - 1 === selected[0])
                        setSelected([0, 0]);
                    else
                        setSelected([selected[0] + 1, 0]);
                }
                else setSelected([selected[0], selected[1] + 1]);
            }
        } else if (results && key === 'ArrowUp') {
            if (!selected) setSelected([results.length - 1, results[results.length - 1].List.length - 1]);
            else {
                //if its the first in this group:
                if (selected[1] === 0) {
                    //if its the first group:
                    if (selected[0] === 0)
                        setSelected([results.length - 1, results[results.length - 1].List.length - 1]);
                    else
                        setSelected([selected[0] - 1, results[selected[0] - 1].List.length - 1]);
                }
                else setSelected([selected[0], selected[1] - 1]);
            }
        }
        else if (results && selected && key === "Enter") {
            var res = results[selected[0]].List[selected[1]];
            redirect(res.RecordType == 'Report' ? res.RecordID : undefined, res.RecordType, res.RecordID);
            setShowResults(false);
            setFocused(false);
            setSelected([0, 0]);
        }
        else if (key === "Esc" || key === "Escape") {
            setShowResults(false);
            setResults([]);
            setFocused(false);
            setSelected([0, 0]);
        }
    };

    return <div style={{ ...(focused ? { zIndex: 10, color: 'black', position: "fixed", top: "10vh", left: "10vh" } : {}) }}>
        <input id="TestGlobalSearch" type="search" onChange={handleInput} onKeyDown={inputKeyDown} onFocus={async e => e.target.select()} value={term} onClick={e => e.stopPropagation()}
            ref={input => focused && input && input.focus()}
            placeholder={ctx.localized('GlobalSearch') + "..."}
            style={focused ? { fontSize: 28, backgroundColor: 'white', boxShadow: '0 0 30px var(--secondary)', } : { display: 'block', backgroundColor: 'var(--secondary)', color: 'var(--primary)', width: "100%" }}
        />
        {showResults && <>
            {results ?
                <div style={{
                    backgroundColor: 'white', width: "100%", border: 'solid 1px var(--secondary)', color: 'black', maxHeight: "60vh", borderRadius: 'var(--border-radius)', overflow: 'auto', ...(focused ? { zIndex: 10, position: 'absolute', } : {})
                }}>
                    {results && results.map((x, groupIndex) => <div key={x.Name}>
                        <div style={{
                            padding: 5,
                            borderBottom: 'solid 1px var(--primary)',
                            borderTop: 'solid 1px var(--primary)',
                            backgroundColor: 'var(--secondary-light)',
                            display: 'flex', alignItems: 'center'
                        }}> <div style={{ color: 'var(--primary)', fontWeight: 'bold', flexGrow: 1 }}>{x.LocalName}</div><div style={{ fontSize: 10, direction: 'ltr' }}>{x.List.length} / {x.Total}</div></div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            {x.List.map((x, resultIndex) => {
                                const ky = x.RecordType + '.' + x.RecordID
                                const _selected = selected && selected[0] === groupIndex && selected[1] === resultIndex
                                return <div key={x.RecordID}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <NavLink key={x.RecordID}
                                            style={{ display: "block", backgroundColor: _selected ? 'var(--secondary)' : "white" }}
                                            controller={x.RecordType}
                                            reportID={x.RecordType == 'Report' ? x.RecordID : undefined}
                                            recordID={x.RecordID}
                                            onClick={() => {
                                                setFocused(false);
                                                setResults([]);
                                                setShowResults(false);
                                            }}>{x.Name}</NavLink><VecIcon name="Preview" width={16} onClick={() => {
                                                if (previewKey === ky) {
                                                    setPreviewKey(undefined)
                                                } else setPreviewKey(ky)
                                            }} />
                                    </div>
                                    {previewKey === ky && <Preview recordType={x.RecordType!} id={x.RecordID!} miniMode={true} />}
                                </div>
                            })}
                        </div>
                    </div>)}
                </div> :
                undefined}
        </>}
    </div>
}
GlobalSearch.displayName = "GlobalSearch"