//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext, useState } from 'react';
import { Popup2, SmartButton, VecIcon } from './misc';
import { ColumnChoice, ColumnData, MachshevetClient, SettingGroup } from "./Declarations"
import { MainContext } from './globals';

export const ColumnChooser: FC<{ RecordType?: string, Group: SettingGroup, SettingKey?: string, ReportID?: number, Width?: number, OnChanged?: () => void }> = props => {
    const [visible, setVisible] = useState(false)
    const [state, setState] = useState<ColumnChoice>({ RecordType: props.RecordType, Group: props.Group, ReportID: props.ReportID, Columns: [], ForAll: false, ReportMode: false });
    async function fillData(forAll: boolean) {
        var cols = await MachshevetClient.Global.GetColumnData(props.Group, props.SettingKey, props.RecordType, forAll, props.ReportID)
        setState(prev => ({ ...prev!, Columns: cols }))
    }
    return <>
        <VecIcon name='ChooseColumns' width={props.Width}
            onClick={async () => {
                await fillData(true);
                setVisible(true)
            }} />
        {visible && state && <ChooserPopup columnChooser={state} onCancel={() => setVisible(false)} onChanged={props.OnChanged} />}
    </>
}

export const ChooserPopup: FC<{ columnChooser: ColumnChoice, onCancel: () => void, onChanged?: () => void }> = props => {
    //const app = useContext(AppContext)!;
    const ctx = useContext(MainContext)
    const [search, setSearch] = useState<string>();
    const [state, setState] = useState(props.columnChooser);
    const [dragOver, setDragOver] = useState<string | null>(null);
    const [dragging, setDragging] = useState<string | null>(null);

    var srch = search?.toLowerCase()

    const searchPredicate = (column: ColumnData) => !srch || column.LocalName?.toLowerCase().includes(srch) || column.Name?.toLowerCase().includes(srch);

    const availableColumns = state.Columns.filter(x => x.SelectedPosition < 0 && searchPredicate(x)).sortBy(x => x.LocalName);
    //const selectedColumns = state.Columns.filter(x => x.SelectedPosition > -1 && searchPredicate(x)).sortBy(x => x.SelectedPosition);
    const selectedColumns = state.Columns.filter(x => x.SelectedPosition > -1).sortBy(x => x.SelectedPosition);

    //const save = async (e: any) => {
    //}

    async function fillData(forAll: boolean) {
        var cols = await MachshevetClient.Global.GetColumnData(props.columnChooser.Group, props.columnChooser.Key, props.columnChooser.RecordType, forAll, props.columnChooser.ReportID)
        setState(prev => ({ ...prev, Columns: cols }))
    }

    return <Popup2 {...props} onClose={props.onCancel} title={ctx.localized("ChooseColumns")} footer={
        <div>
            <SmartButton testName="Okay" onClick={async () => {
                await MachshevetClient.Global.SetColumns(state);
                props.onChanged && props.onChanged();
                props.onCancel()
            }}>{ctx.localized('Okay')}</SmartButton>
            {!state.ReportMode && <label>
                <input type="checkbox" checked={state.ForAll} onChange={e => {
                    var forall = e.target.checked;
                    setState(prev => ({ ...prev, ForAll: forall }))
                    fillData(forall);
                }} />
                <span>{ctx.localized('ForAllUsers')}</span>
            </label>}
        </div>
    }>

        <div style={{ display: 'flex', flexDirection: 'column', height: "100%", overflowY: 'auto' }}>
            <input type='search' id="TestColumnSearch" value={search || ''} autoFocus onChange={e => setSearch(e.currentTarget.value)} placeholder={ctx.localized('SearchColumns') + '...'} />
            <div style={{ display: 'flex', height: "100%", overflowY: 'auto' }}>
                <div style={{   display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: "bold" }}>{ctx.localized('AvailableColumns')}</span>
                    <dl style={{ overflowY: 'auto', height: "100%" }} >
                        {availableColumns.map(column => {
                            return <dt key={column.Name}
                                style={{ transition: 'all .4s ease', display: 'flex', alignItems: 'center', padding: '.2em', borderRadius: '10px', backgroundColor: column.IsNew ? 'fuchsia' : '' }}
                                onDoubleClick={() => {
                                    var newcols = state.Columns.map(x => x.Name === column.Name ? { ...column, SelectedPosition: selectedColumns.length } : x)
                                    setState(prev => ({ ...prev, Columns: newcols }))
                                }}
                            >
                                <VecIcon name={column.Icon!} width={20} />
                                <span id={"Test" + column.Name}>{column.LocalName}</span>
                            </dt>
                        })}
                    </dl>
                </div>
                <div style={{   display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontWeight: "bold" }}>{ctx.localized('SelectedColumns')}</span>
                    <dl style={{ overflowY: 'auto', height: "100%" }} >
                        {selectedColumns.filter(x => searchPredicate(x)).map(x => {
                            return <dt
                                style={{ transition: 'all .4s ease', display: 'flex', alignItems: 'center', padding: '.2em', justifyContent: 'space-between', borderRadius: '10px', backgroundColor: x.IsNew ? 'fuchsia' : '' }}
                                className='choose-columns-item'
                                key={x.Name}
                                draggable
                                onDragStart={() => {
                                    setDragging(x.Name!)
                                }}
                                onDragEnter={() => {
                                    setDragOver(x.Name!)
                                }}
                                onDragOver={e => {
                                    e.preventDefault()
                                }}
                                onDrop={e => {
                                    var aa = 11;
                                    if (dragging) {
                                        const landingPosition = x.SelectedPosition;
                                        var newcols = state.Columns?.map(x => x.Name === dragging ? { ...x, SelectedPosition: landingPosition } : x.SelectedPosition < landingPosition ? x : { ...x, SelectedPosition: x.SelectedPosition + 1 })
                                        setState(prev => ({ ...prev, Columns: newcols }))
                                        setDragOver(null);
                                    }
                                }}
                                onDoubleClick={() => {
                                    var newcols = state.Columns?.map(y => y.Name === x.Name ? { ...x, SelectedPosition: -1 } : y)
                                    setState(prev => ({ ...prev, Columns: newcols }))
                                }


                                }  >
                                <div>
                                    <VecIcon name={x.Icon!} width={18} />
                                    <span>{x.SelectedPosition.toString() + ' ' + x.LocalName}</span>
                                </div>
                                {x.IsFilter && <VecIcon name='Filter' width={16} color='red' />}
                            </dt>
                        })}
                    </dl>
                </div>
            </div>
        </div>
    </Popup2>
}