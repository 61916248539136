//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useContext, FC, useState, useEffect } from "react";
import { RecordForm } from './RecordAdapter';
import { MyTab, MyTabs, useInterval } from './misc';
import { TableBody } from './tableBody';
import { MachshevetClient, RecordData } from './Declarations';
import { AppContext, defaultGridProps, defaultReport, MainContext, numberColor, redirect } from './globals';

export const ReportsTabs: FC<{ record: RecordData, fullPage?: boolean; }> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext);
    const [state, setState] = useState(props.record);
    const [activeTab, setActiveTab] = useState(0);
    const [message, setMessage] = useState<string>();
    useEffect(() => {
        if (props.fullPage && state.RecordName) app.setPageTitle(state.RecordName);
        if (!state || !state.Fields.length) loadRecord();
    }, []);

    useEffect(() => {
        if (props.fullPage && state.RecordName) app.setPageTitle(state.RecordName);
    }, [state.RecordName]);

    useInterval(() => {
        if (!ctx.data.UserID) {
            redirect();
            return;
        }
    }, 20000);

    const loadRecord = async () => {
        const rec = await MachshevetClient.PageRecord(state.RecordType!, state.RecordID);
        setState(rec)
    }

    var dummy = defaultReport();
    dummy.ID = -1;
    dummy.Name = ctx.localized('General');
    dummy.LocalName = ctx.localized('General');
    var reps = [dummy];
    if (state.Reports) reps = reps.concat(state.Reports);

    function getGridProps(reportID: number, parentRecordID?: number) {
        var gp = defaultGridProps();
        gp.ReportID = reportID;
        gp.ParentRecordID = parentRecordID;
        return gp;
    }

    return <div style={{ padding: 5, overflowY: 'auto', height: "100%", display: 'flex', flexDirection: 'column' }} id="div10">
        {message && <div style={{ backgroundColor: "palegreen", borderRadius: 5, borderColor: "lime", borderStyle: "solid", textAlign: "center", margin: 5, padding:3 }}>{message}</div>}
        <MyTabs selectedTabChanged={setActiveTab}>
            {reps.map((x, i) => <MyTab key={x.ID} style={{ backgroundColor: numberColor(x.BackColor) }} reportID={x.ID}
                titleElement={<div style={{ display: 'flex', justifyContent: 'space-between' }}><span style={{ fontWeight: 'bold' }}>{x.LocalName!}</span><span>{x.TempCount === undefined ? null : x.TempCount}</span></div>}
                onClick={async () => {
                    if (x.ID > 0) await MachshevetClient.Global.MarkReportSeen(x.ID);
                }} >
                {x.ID === -1 ?
                    <RecordForm Record={state} onSaved={(id, oldid) => {
                        //if (oldid) message.success(ctx.localized('Saved'));
                        if (oldid) {
                            setMessage(ctx.localized('Saved'));
                            setTimeout(() => setMessage(undefined) ,5000)
                        }
                        else redirect(undefined, state.RecordType, id);
                    }} />
                    :
                    <TableBody controller='Report' gridProps={getGridProps(x.ID, state.RecordID)} onResultsFetched={e => {
                        const newstt = state
                        const rep = newstt.Reports.find(y => y.ID === x.ID)!
                        if (rep.TempCount !== e) {
                            rep.TempCount = e
                            //setState(newstt)
                            setState(prev => ({ ...prev, Reports: prev.Reports.map(y => y.ID === x.ID ? { ...y, TempCount: e } : y) }))
                        }
                    }} activeTab={i === activeTab} />
                }
            </MyTab>)
            }
        </MyTabs>
    </div>
}