//***Copyright Notice***
//____________________________________________________
//Copyright © 2023 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useEffect, useContext } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Control } from './Control';
import { ControlProps, MachshevetClient } from './Declarations';
import { groupBy } from './shared';
import { MainContext } from './globals';
import { Popup2, SmartButton } from './misc';

export const Options: FC<{ onCancel: () => void }> = props => {
    const ctx = useContext(MainContext)!;
    const [searchTerm, setSearchTerm] = useState<string>();
    const [state, setState] = useState<ControlProps[]>();
    useEffect(() => {
        getData()
        //const fetchOptions = async () => {
        // };
        //if (props.visible) fetchOptions();
        // }, [props.visible]);
    });


    async function getData() {
        const options = await MachshevetClient.Global.OptionList();
        setState(options);
    }

    const groups = state === undefined ? {} :
        groupBy<ControlProps, string>(
            (searchTerm ?
                [...state]
                    .filter(x => x.Name!.toLowerCase().includes(searchTerm) || x.DisplayName!.includes(searchTerm) || x.Group?.includes(searchTerm)) :
                [...state]),
            x => x.Group!
        )

    const [[selectedGroupName, selectedOption], setSelected] = useState<[string | undefined, string | undefined]>([undefined, undefined]);

    const [changes, setChanges] = useState<Record<string, any>>({});
    //const handleSave = async (e: any) => {
    //    await MachshevetClient.Global.SetOptions(changes)
    //    props.onCancel()
    //}

    const opts = (groups[selectedGroupName!] || []).sortBy(x => x.DisplayName);

    return <Popup2 {...props} onClose={props.onCancel} title={ctx.localized('Options')} footer={[
        <SmartButton onClick={async () => {
            await MachshevetClient.Global.SetOptions(changes)
            props.onCancel()

        }} disabled={Object.keys(changes).length < 1}  >{ctx.localized('Save')} </SmartButton>,
    ]}
    >
        <div style={{ display: 'flex' }}>
            <aside style={{ backgroundColor: 'var(--primary-light)' }}>
                <input type='search' value={searchTerm} onChange={e => setSearchTerm(e.currentTarget.value)} autoFocus />
                {Object.keys(groups).sortBy(x => x).map(group => ([
                    <div key={group}
                        style={{ transition: 'all 0.4s ease-out', padding: '0.2em 0.7em', cursor: 'pointer', fontWeight: 'lighter', fontSize: 'x-large', backgroundColor: group === selectedGroupName ? 'var(--primary)' : 'initial' }}
                        onClick={() => setSelected([group, undefined])}>
                        {group}
                    </div>,
                    searchTerm ?
                        (groups[group] || []).sortBy(x => x.DisplayName).map((item, i) =>
                            <div key={group + i}
                                style={{ transition: 'all 0.4s ease-out', padding: '0.2em 0.7em', cursor: 'pointer', backgroundColor: item.Name === selectedOption ? 'var(--primary)' : 'initial' }}
                                onClick={() => setSelected([group, item.Name])}
                            >{item.DisplayName}</div>) :
                        undefined
                ]))}
            </aside>
            <main>
                {selectedGroupName ?
                    <table style={{ margin: '4em 2em' }}>
                        <tbody>
                            {
                                opts.map(item => {
                                    const changed = changes[item.Name!];
                                    return <tr key={item.Name}
                                        style={{ animation: item.Name === selectedOption ? 'emphasize 1s ease-out' : undefined }}>
                                        {!item.IsCommand ?
                                            <>
                                                <th style={{ color: 'var(--primary-light)' }}>{item.DisplayName}:</th>
                                                <td>
                                                    <Control field={{ ...item, LetTime: true, Editable: true, Value: changed !== undefined ? changed : item.Value, onChange: async value => setChanges({ ...changes, [item.Name!]: value }) }} />
                                                </td>
                                            </> :
                                            <td colSpan={2}><button onClick={() => MachshevetClient.Global.DoCommand(item.Name!)}>{item.DisplayName}</button></td>}
                                    </tr>
                                })
                            }
                        </tbody>
                    </table> : undefined}
            </main>
        </div>
    </Popup2>;
}
Options.displayName = 'Options';